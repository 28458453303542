import { useEffect } from 'react';
import { useModificationGuard } from '@/context/ModificationGuardProvider';

/**
 * Hook to indicate that modifications should be handled locally (e.g., in a drawer)
 * rather than showing the global modification bar
 */
export function useLocalModificationBar() {
  const { setIsLocalBar: setIsLocalGuard } = useModificationGuard();

  useEffect(() => {
    // Indicate that modifications should be handled locally
    setIsLocalGuard(true);

    // Reset to global handling when component unmounts
    return () => {
      setIsLocalGuard(false);
    };
  }, [setIsLocalGuard]);
} 