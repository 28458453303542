import { DebugView } from "@/components/DebugView";
import { useModified } from "@/context/ModifiedProvider";
import { useOrganizationNamesQuery } from "@/graphql/hooks/organizations-hook";
import {
  IGQLUserProfileQuery,
  useUpdateUserProfileMutation,
  useUpdateUserProfileForOrganizationMutation,
  useUpdateUserProfileForCourseMutation,
  useUserProfileQuery,
} from "@/graphql/hooks/users-hook";
import { useLocalChange } from "@/hooks/use-local-change";
import { generateDebug } from "@/utils";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Paper, Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProfileInfo } from "./components/ProfileInfo";
import { RoleEditor } from "./components/RoleEditor";
import { Roles, RolesByRoleId } from "./types";
import { sortRoles, toByRole, toRoles } from "./utils";
import { roleDiff } from "./utils/roleDiff";
import { stripUnnecessaryFields } from "./utils/roleStrip";
// Dummy data
type User = IGQLUserProfileQuery["jrnyUserProfile"]["user"];

const debug = generateDebug("UserDetailView");
export function UserDetailView() {
  const navigate = useNavigate();
  const { organizationId, courseId, userId } = useParams();
  const modified = useModified();

  const handleClose = () => {
    navigate("..");
  };
  debug({ userId });
  const [{ data: profileData, fetching: profileFetching }] =
    useUserProfileQuery({ variables: { userId: userId! } });

  // Use the appropriate mutation hook based on context
  const [{ fetching: updateFetching }, updateProfileGlobal] =
    useUpdateUserProfileMutation();
  const [{ fetching: updateOrgFetching }, updateProfileForOrganization] =
    useUpdateUserProfileForOrganizationMutation();
  const [{ fetching: updateCourseFetching }, updateProfileForCourse] =
    useUpdateUserProfileForCourseMutation();

  // Create a single update function that calls the appropriate mutation
  const updateProfile = (variables: any) => {
    if (organizationId && !courseId) {
      return updateProfileForOrganization({
        ...variables,
        organizationId,
      });
    } else if (courseId) {
      return updateProfileForCourse({
        ...variables,
        courseId,
      });
    } else {
      return updateProfileGlobal(variables);
    }
  };

  const sortedProfile = useMemo(() => {
    if (!profileData?.jrnyUserProfile) return undefined;
    return {
      user: profileData.jrnyUserProfile.user,
      roles: stripUnnecessaryFields(
        sortRoles(
          profileData.jrnyUserProfile.roles.filter((r) => {
            if (r.scope === "global" && (organizationId || courseId)) {
              return false;
            }
            if (
              (r.scope === "organization" || r.scope === "course") &&
              organizationId &&
              r.organizationId != organizationId
            ) {
              return false;
            }
            if (r.scope === "course" && courseId && r.courseId != courseId) {
              return false;
            }
            return true;
          }) as Roles
        )
      ),
      status: profileData.jrnyUserProfile.status,
    };
  }, [profileData?.jrnyUserProfile]);
  debug({ sortedProfile });
  const [localProfile, setLocalProfile] = useLocalChange<
    { user: User; roles: Roles; status: string },
    { user: User; roles: RolesByRoleId; status: string }
  >(
    sortedProfile,
    // save the local profile to the server
    async (org, local) => {
      const { email, ...user } = local.user;
      return updateProfile({
        input: {
          user: user,
          roles: roleDiff(toRoles(local.roles), org.roles),
        },
      });
    },
    // org to local
    (org) => ({
      user: org.user,
      roles: toByRole(org.roles),
      status: org.status,
    }),
    // local to org
    (local) => ({
      user: local.user,
      roles: toRoles(local.roles),
      status: local.status,
    })
  );

  if (!localProfile) {
    return null; // Or loading state
  }

  return (
    <Paper sx={{ height: "100%", width: "100%" }}>
      <Box p={3} display="flex" flexDirection="column" gap={3}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5">User profile</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <ProfileInfo
            userId={localProfile.user.id}
            firstName={localProfile.user.firstName}
            lastName={localProfile.user.lastName}
            email={localProfile.user.email}
            status={localProfile.status}
            setLocalProfile={setLocalProfile}
          />
        </Box>

        <RoleEditor
          organizationId={organizationId}
          courseId={courseId}
          localRoles={localProfile.roles}
          setLocalRoles={(updater) =>
            setLocalProfile((draft) => {
              if (draft) {
                updater(draft.roles);
              }
            })
          }
        />
      </Box>
      {/* <DebugView>
        {JSON.stringify(
          { modified, localProfile, sortedProfile, profileData },
          null,
          2
        )}
      </DebugView> */}
    </Paper>
  );
}
