export enum CaslVerb {
  Manage = 'manage',
  Create = 'create',
  Clone = 'clone',
  View = 'view',
  Update = 'update',
  Remove = 'remove',
  UploadFiles = 'upload-files',
  ViewParticipations = 'view-participations',
  CreateParticipations = 'create-participations',
  CreateTeams = 'create-teams',
  SaveAttendance = 'save-attendance',
  ViewAttendance = 'view-attendance',
  ViewNotifications = 'view-notifications',
  UpdateNotifications = 'update-notifications',
  RemoveParticipations = 'remove-participations',
  Assess = 'assess',
  SubmitAnswer = 'submit-answer',
  SendEmailToParticipants = 'send-email-to-participants',
  ReceiveEvents = 'receive-events',
  UnlockLesson = 'unlock-lesson',
  LockLesson = 'lock-lesson',
  CreatePerk = 'create-perk',
  ClonePerk = 'clone-perk',
  ViewPerk = 'view-perk',
  UpdatePerk = 'update-perk',
  RemovePerk = 'remove-perk',
  RefundPerkPurchase = 'refund-perk-purchase',
  ViewAdminUsers = 'view-admin-users',
  UpdateAdminUsers = 'update-admin-users',
  LoginWebadmin = 'login-webadmin',
  LoginWebapp = 'login-webapp',
  CreateOrganization = 'create-organization',
  PurchasePerk = 'purchase-perk',
  AssignRoles = 'assign-roles',
  AssignRole = 'assign-role',
  ViewAdminUserProfile = 'view-admin-user-profile',
  ViewFiles = 'view-files',
  CreateFiles = 'create-files',
  ViewRoles = 'view-roles',
}
