import React, { createContext, useContext, useState } from 'react';

interface ModificationGuardContextType {
  isLocalBar: boolean;
  setIsLocalBar: (value: boolean) => void;
}

const ModificationGuardContext = createContext<ModificationGuardContextType | null>(null);
/**
 * Keeps track of if the modification bar should be local or not
 */
export function ModificationGuardProvider({ children }: { children: React.ReactNode }) {
  const [isLocalBar, setIsLocalBar] = useState(false);

  return (
    <ModificationGuardContext.Provider value={{ isLocalBar, setIsLocalBar }}>
      {children}
    </ModificationGuardContext.Provider>
  );
}

export function useModificationGuard() {
  const context = useContext(ModificationGuardContext);
  if (!context) {
    throw new Error('useModificationGuard must be used within a ModificationGuardProvider');
  }
  return context;
} 