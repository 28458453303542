import * as Types from '../../graphql-types';

import { gql } from 'urql';
import { CurrentUserFieldsFragmentDoc } from './fragment-CurrentUser-hook';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IGQLJrnyLoginMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyLoginInput;
}>;


export type IGQLJrnyLoginMutation = { __typename: 'Mutation', jrnyLogin: { __typename: 'JrnyLoginResult', permissions: Array<string>, organizationIds: Array<string>, abilities: any, user: { __typename: 'JrnyUser', firstName: string, lastName: string, id: string, email: string }, tokenResponse: { __typename: 'TokenResponse', token: string, tokenValidTo: number, refreshToken: string, refreshTokenValidTo: number } } };

export type IGQLJrnyReloginMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type IGQLJrnyReloginMutation = { __typename: 'Mutation', jrnyRelogin: { __typename: 'JrnyLoginResult', permissions: Array<string>, organizationIds: Array<string>, abilities: any, user: { __typename: 'JrnyUser', firstName: string, lastName: string, id: string, email: string }, tokenResponse: { __typename: 'TokenResponse', token: string, tokenValidTo: number, refreshToken: string, refreshTokenValidTo: number } } };

export type IGQLTestOffLimitQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IGQLTestOffLimitQuery = { __typename: 'Query', testPermission: string };


export const JrnyLoginDocument = gql`
    mutation jrnyLogin($input: JrnyLoginInput!) {
  jrnyLogin(input: $input) {
    ...CurrentUserFields
  }
}
    ${CurrentUserFieldsFragmentDoc}`;

export function useJrnyLoginMutation() {
  return Urql.useMutation<IGQLJrnyLoginMutation, IGQLJrnyLoginMutationVariables>(JrnyLoginDocument);
};
export const JrnyReloginDocument = gql`
    mutation jrnyRelogin {
  jrnyRelogin {
    ...CurrentUserFields
  }
}
    ${CurrentUserFieldsFragmentDoc}`;

export function useJrnyReloginMutation() {
  return Urql.useMutation<IGQLJrnyReloginMutation, IGQLJrnyReloginMutationVariables>(JrnyReloginDocument);
};
export const TestOffLimitDocument = gql`
    query testOffLimit {
  testPermission
}
    `;

export function useTestOffLimitQuery(options?: Omit<Urql.UseQueryArgs<IGQLTestOffLimitQueryVariables>, 'query'>) {
  return Urql.useQuery<IGQLTestOffLimitQuery, IGQLTestOffLimitQueryVariables>({ query: TestOffLimitDocument, ...options });
};