import { AbilityContext, useCurrentUser } from "@/context/CurrentUserProvider";
import { TopNavigation } from "@/top-navigation";
import { useAbility } from "@casl/react";
import { Navigate, redirect, useLocation } from "react-router-dom";
import { generateDebug } from "@/utils";
import { Box, Typography, Stack, Alert, Button } from "@mui/material";
import { IconError } from "@/icons";
import { useState, useEffect } from "react";

const debug = generateDebug("RequireAuth");

/**AbilityContext,
 * Needs to be logged in, if not, redirects to sign-in
 * @param props
 * @constructor
 */
function RequireAuth({ children }: { children: JSX.Element }) {
  const { currentUser, signOut } = useCurrentUser();
  const ability = useAbility(AbilityContext);
  let location = useLocation();
  const [duration, setDuration] = useState(-1);
  const [allowed, setAllowed] = useState(false);
  debug("currentUser", currentUser);
  debug("ability", ability);
  useEffect(() => {
    if (!allowed ) {
      const timer = setInterval(() => {
        setDuration((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [allowed]);
  useEffect(() => {
    if (duration === 0) {
      window.location.href = import.meta.env.VITE_WEBAPP_URL;
    }
  }, [allowed, duration]);
  useEffect(() => {
    if (ability) {
      if (!ability.can("login-webadmin")) {
        setAllowed(false);
        if (ability.can("login-webapp")) {
          setDuration(5);
        }
      } else {
        setAllowed(true);
      }
    }
  }, [ability]);
  if (!currentUser || !ability) {
    return (
      <Navigate to={TopNavigation.SignIn} state={{ from: location }} replace />
    );
  }

  if (!allowed) {
    return (
      <Box>
        <Stack
          spacing={2}
          sx={{
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Alert icon={<IconError fontSize="inherit" />} severity="error">
            You don't have access to the backend
          </Alert>
          {duration > 0 && (
            <Button
              variant="outlined"
              onClick={() => {
                window.location.href = import.meta.env.VITE_WEBAPP_URL + '/logout';
              }}
            >
              Go to webapp (redirecting in {duration} seconds)
            </Button>
          )}
          <Button
            variant="outlined"
            onClick={signOut}
          >
            Log out
          </Button>
        </Stack>
      </Box>
    );
    // }
  }
  return children;
}

export default RequireAuth;
