import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemText,
  SpeedDial,
  SpeedDialAction,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import * as React from "react";
import { useMemo } from "react";
import AddCourseUserDialog from "./components/AddCourseUserDialog";
import { useCurrentOrganization } from "@/context/OrganizationProvider";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAddCourseRolesToUsersMutation,
  useAddCourseRoleToUserMutation,
  useParticipationsQuery,
  useRemoveCourseRoleFromUserMutation,
  useSendWelcomeEmailMutation,
} from "@graphql/participations-hook";
import { Participation } from "@/pages/course-users/types";
import Box from "@mui/material/Box";
import { IconAdd, IconDelete, IconImportCSV, IconTeacher } from "@/icons";
import UploadCourseUserCsv from "@/pages/course-users/components/UploadCourseUserCsv";
import { sortBy } from "lodash";
import RemoveCourseUserDialog from "@/pages/course-users/components/RemoveCourseUserDialog";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import { actions } from "@/pages/prompt/types";
import IconStudent from "@mui/icons-material/School";
import SelectUsersToInviteDialog from "./components/SelectUsersToInviteDialog";
import { DebugView } from "@/components/DebugView";
import { RoleChip } from "@/pages/user/components/RoleChip";

interface CourseUsersViewProps {}

export default function CourseUsersView(props: CourseUsersViewProps) {
  const fileInput = React.useRef<HTMLInputElement>(null);

  const { currentOrganization } = useCurrentOrganization();
  const { courseId } = useParams();
  const [toRemove, setToRemove] = React.useState<[Participation, string]>();
  const [showInvitationDialog, setShowInvitationDialog] = React.useState(false);
  const [toAddRole, setToAddRole] = React.useState<"student" | "instructor">();
  const navigate = useNavigate();
  const [showCsvInstructions, setShowCsvInstructions] = React.useState(false);

  const [, addCourseUser] = useAddCourseRoleToUserMutation();
  const [, removeCourseUser] = useRemoveCourseRoleFromUserMutation();
  const [, addMultipleCourseUser] = useAddCourseRolesToUsersMutation();
  const [, sendWelcomeEmails] = useSendWelcomeEmailMutation();

  const [participations] = useParticipationsQuery({
    variables: { courseId: courseId || "" },
    pause: !currentOrganization,
  });
  const students = useMemo(
    () =>
      sortBy(
        (participations.data?.jrnyParticipationsForCourse || []).filter((u) =>
          u.roleIds.includes("student")
        ),
        ["name", "email"]
      ),
    [participations.data]
  );
  const teachers = useMemo(
    () =>
      sortBy(
        (participations.data?.jrnyParticipationsForCourse || []).filter((u) =>
          u.roleIds.includes("instructor")
        ),
        ["name", "email"]
      ),
    [participations.data]
  );

  const combined = useMemo(
    () => participations.data?.jrnyParticipationsForCourse || [],
    [participations.data]
  );
  return (
    (courseId && currentOrganization && (
      <Container>
        <Box sx={{ position: "relative", mb: 8 }}>
          <Card>
            <CardHeader
              title="Users"
              subheader={`Instructor count ${teachers.length}, student count: ${students.length}`}
              action={
                combined.length > 0 ? (
                  <Button
                    onClick={() => setShowInvitationDialog(true)}
                    sx={{ m: 2 }}
                    variant="contained"
                  >
                    Send course welcome email
                  </Button>
                ) : null
              }
            />
            <CardContent>
              <List>
                {combined.map((participation, ix) => (
                  <ListItem
                    secondaryAction={
                      <Stack direction="row" spacing={1}>
                        {participation.roleIds.map((r) => (
                          <RoleChip
                            key={r}
                            roleId={r}
                            onDelete={() => setToRemove([participation, r])}
                          />
                        ))}
                      </Stack>
                    }
                    divider={ix < combined.length - 1}
                    key={participation.userId}
                  >
                    <ListItemText
                      primary={participation.user.email}
                      secondary={participation.user.name}
                    />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Box>
        <SelectUsersToInviteDialog
          courseId={courseId}
          participations={
            participations.data?.jrnyParticipationsForCourse || []
          }
          open={showInvitationDialog}
          onClose={() => setShowInvitationDialog(false)}
          sendEmails={sendWelcomeEmails}
        />
        <AddCourseUserDialog
          courseId={courseId}
          role={toAddRole}
          onClose={() => setToAddRole(undefined)}
          addUser={addCourseUser}
        />
        <UploadCourseUserCsv
          ref={fileInput}
          courseId={courseId}
          addUsers={addMultipleCourseUser}
        />
        <RemoveCourseUserDialog
          onClose={() => setToRemove(undefined)}
          participation={toRemove?.[0]}
          role={toRemove?.[1]}
          removeUser={removeCourseUser}
        />
        <SpeedDial
          ariaLabel="Create"
          sx={{ position: "fixed", bottom: 16, right: 16 }}
          icon={<SpeedDialIcon />}
        >
          <SpeedDialAction
            onClick={() => setToAddRole("instructor")}
            icon={<IconTeacher />}
            tooltipTitle={"Add teacher"}
          />
          <SpeedDialAction
            onClick={() => setToAddRole("student")}
            icon={<IconStudent />}
            tooltipTitle={"Add student"}
          />
          <SpeedDialAction
            onClick={() => setShowCsvInstructions(true)}
            icon={<IconImportCSV />}
            tooltipTitle={"Upload CSV"}
          />
        </SpeedDial>

        <Dialog
          open={showCsvInstructions}
          onClose={() => setShowCsvInstructions(false)}
          sx={{ "& .MuiDialog-paper": { maxWidth: "90%" } }}
        >
          <DialogTitle>CSV File Format Instructions</DialogTitle>
          <DialogContent>
            <p>The CSV file should contain the following columns:</p>
            <ul>
              <li>
                <strong>Email</strong> - User's email address
              </li>
              <li>
                <strong>Roles</strong> - Combination of "student","instructor" or "instructor-team", comma separated
              </li>
              <li>
                <strong>First Name</strong> (optional) - User's first name
              </li>
              <li>
                <strong>Last Name</strong> (optional) - User's last name
              </li>
              <li>
                <strong>Message</strong> (optional) - User's last name
              </li>
            </ul>
            <p>
              If you want to add a user to multiple roles, you can separate
              roles with a comma, <strong>but make sure to enclose the roles in double
              quotes</strong>.
            </p>
            <p>Example:</p>
            <pre style={{ fontSize: ".7em" }}>
              
              student@example.com, student, John, Doe{"\n"}
              teacher@example.com, "instructor, instructor-team", Jane, Smith, "Welcome to course!"{"\n"}
              student2@example.com, student, Jane, Smith
            </pre>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowCsvInstructions(false)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setShowCsvInstructions(false);
                fileInput.current?.click();
              }}
            >
              Proceed
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    )) ||
    null
  );
}
