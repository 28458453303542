import { Unpacked } from "@/types";
import { generateDebug } from '@/utils';
import { useCurrentUser } from './CurrentUserProvider';
import { IGQLOrganizationsQuery, useOrganizationsQuery } from '@graphql/organizations-hook';
import * as React from 'react';
import { ReactNode } from 'react';

export type Organization = Unpacked<IGQLOrganizationsQuery['jrnyOrganizations']>
const debug = generateDebug('OrganizationsProvider');
debug('Running')

interface OrganizationsContextValue {
  organizations: Organization[];
  fetching: boolean;
  error?: string;
}

export const OrganizationsContext = React.createContext<OrganizationsContextValue>({
  organizations: [],
  fetching: false,
});

interface OrganizationsProviderProps {
  children: ReactNode;
}

export function OrganizationsProvider(props: OrganizationsProviderProps) {
  const { currentUser } = useCurrentUser();
  const [organizations] = useOrganizationsQuery({
    pause: !currentUser,
  });

  const value = React.useMemo(() => ({
    fetching: organizations.fetching,
    error: organizations.error?.message,
    organizations: organizations.data?.jrnyOrganizations || [],
  }), [organizations]);

  return (<OrganizationsContext.Provider value={value} {...props} />);
}

export const useOrganizations = () => {
  const context = React.useContext(OrganizationsContext);
  if (context === undefined) {
    throw new Error('useOrganizations must be used within an OrganizationsProvider');
  }
  return context;
} 
