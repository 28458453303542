import { IGQLRefundPerkPurchaseMutationVariables } from "@/graphql/hooks/perks-hook";
import { generateDebug } from '@/utils';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { FORM_ERROR } from 'final-form';
import * as React from 'react';
import { Purchase } from "../types";

const debug = generateDebug('RefundPerkPurchaseDialog');

  interface RefundPerkPurchaseDialogProps {
  refundPurchase: (variables: IGQLRefundPerkPurchaseMutationVariables) => Promise<any>;
  purchase?: Purchase
  perkId: string
  onClose: () => void
}

export default function RefundPerkPurchaseDialog(props: RefundPerkPurchaseDialogProps) {
  const [submitting, setSubmitting] = React.useState(false);


  const handleClose = () => {
    props.onClose();
  };
  const doSubmit = async () => {
    setSubmitting(true);
    const result = await props.refundPurchase({
      input: {
        perkId: props.perkId,
        id: props.purchase!.id,
      }
    });
    debug('Submit result', result);
    setSubmitting(false);
    if (result.error) {
      return {[FORM_ERROR]: result.error.graphQLErrors[0].message};
    }
    props.onClose();
  };

  return (
    <div>
      <Dialog open={!!props.purchase} onClose={submitting ? () => {
      } : handleClose}>
        <DialogTitle>Refund purchase?</DialogTitle>
        <DialogActions>
          <Button disabled={submitting} onClick={handleClose}>Cancel</Button>
          <Button disabled={submitting} onClick={() => doSubmit()}
                  variant={'contained'}
                  color={'error'}>Refund</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
