import {
  IconCheckpoint,
  IconCourse,
  IconFolder,
  IconHome,
  IconOrganization,
  IconPerk,
  IconSettings,
  IconUser,
} from "@/icons";
import { useSiteNavigation } from "./site-navigation";
import { getAppLogo } from "@/utils";
import Drawer, { DrawerProps } from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { CheckpointBullet } from "./components/CheckpointBullet";
import { SideNavSection } from "./components/SideNavSection";
import { journeyColors } from "./jouney-theme";
import { NavigationStructure } from "./navigation-types";
import Divider from "@mui/material/Divider";

const item = {
  py: "2px",
  px: 1,
  // color: 'rgba(255, 255, 255, 0.7)',
  "&:hover, &:focus": {
    // bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
  py: 1.5,
  px: 3,
};

export default function Navigator(props: DrawerProps) {
  const { sx, ...other } = props;
  const categories = useSiteNavigation();
  return (
    <Drawer
      variant="permanent"
      {...other}
      sx={{
        "& .MuiDrawer-paper": {
          backgroundColor: journeyColors.primary[0],
          p: ".75rem",
        },
      }}
    >
      <List disablePadding sx={{ m: 1 }}>
        <ListItem sx={{ pl: ".25rem" }}>{getAppLogo()}</ListItem>
        {categories.map((item, ix) => {
          return item.type === "section" ? (
            <SideNavSection key={`${ix}-${item.name}`} item={item} />
          ) : item.type === "separator" ? (
            <Divider key={`${ix}`} sx={{mb:2}} />
          ) : null;
        })}
      </List>
    </Drawer>
  );
}
