import {PromptInputCardProps} from '@/pages/prompt/components/types';
import {TextField} from '@mui/material';
import Grid from '@mui/material/Grid2';

import {produce} from 'immer';
import * as React from 'react';
import {UploadPromptInputConfig, UploadPromptInputSubmission} from '@journey-lti-tool/common'
export function UploadInputCard({
                                  promptInput,
                                  onChange,
                                }: PromptInputCardProps<UploadPromptInputConfig, UploadPromptInputSubmission>) {
  return (
    (<Grid container spacing={1}>
      <Grid
        size={{
          sm: 12
        }}><TextField
        label='Name/Title'
        fullWidth
        size='small'
        value={promptInput.config.name}
        onChange={(evt) => onChange(produce(promptInput, draft => {
          draft.config.name =  evt.target.value
        }))}
      /></Grid>
      {/* <Grid
        size={{
          sm: 2
        }}>
        <TextField
          fullWidth
          size='small'
          type={'number'}
          label='Weight'
          value={promptInput.weight}
          onChange={(evt) => onChange(produce(promptInput, draft => {
            draft.weight = Math.max(0, Number.parseFloat(evt.target.value))
          }))}/>
      </Grid> */}
    </Grid>)
  );

}
