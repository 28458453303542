import * as Types from '../../graphql-types';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IGQLReorderAllLessonsMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type IGQLReorderAllLessonsMutation = { __typename: 'Mutation', jrnyReorderAllLessons: Array<{ __typename: 'ReorderResult', courseName: string, checkpointName: string, lessonCount: number, courseId: string, checkpointId: string, previousMinOrder: number, previousMaxOrder: number, previousUniqueOrders: number }> };

export type IGQLReorderInvalidOrderLessonsMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type IGQLReorderInvalidOrderLessonsMutation = { __typename: 'Mutation', jrnyReorderInvalidOrderLessons: Array<{ __typename: 'ReorderResult', courseName: string, checkpointName: string, lessonCount: number, courseId: string, checkpointId: string, previousMinOrder: number, previousMaxOrder: number, previousUniqueOrders: number }> };

export type IGQLListCheckpointsWithInvalidOrderQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IGQLListCheckpointsWithInvalidOrderQuery = { __typename: 'Query', jrnyListCheckpointsWithInvalidOrder: Array<{ __typename: 'ReorderResult', courseName: string, checkpointName: string, lessonCount: number, courseId: string, checkpointId: string, previousMinOrder: number, previousMaxOrder: number, previousUniqueOrders: number }> };


export const ReorderAllLessonsDocument = gql`
    mutation reorderAllLessons {
  jrnyReorderAllLessons {
    courseName
    checkpointName
    lessonCount
    courseId
    checkpointId
    previousMinOrder
    previousMaxOrder
    previousUniqueOrders
  }
}
    `;

export function useReorderAllLessonsMutation() {
  return Urql.useMutation<IGQLReorderAllLessonsMutation, IGQLReorderAllLessonsMutationVariables>(ReorderAllLessonsDocument);
};
export const ReorderInvalidOrderLessonsDocument = gql`
    mutation reorderInvalidOrderLessons {
  jrnyReorderInvalidOrderLessons {
    courseName
    checkpointName
    lessonCount
    courseId
    checkpointId
    previousMinOrder
    previousMaxOrder
    previousUniqueOrders
  }
}
    `;

export function useReorderInvalidOrderLessonsMutation() {
  return Urql.useMutation<IGQLReorderInvalidOrderLessonsMutation, IGQLReorderInvalidOrderLessonsMutationVariables>(ReorderInvalidOrderLessonsDocument);
};
export const ListCheckpointsWithInvalidOrderDocument = gql`
    query listCheckpointsWithInvalidOrder {
  jrnyListCheckpointsWithInvalidOrder {
    courseName
    checkpointName
    lessonCount
    courseId
    checkpointId
    previousMinOrder
    previousMaxOrder
    previousUniqueOrders
  }
}
    `;

export function useListCheckpointsWithInvalidOrderQuery(options?: Omit<Urql.UseQueryArgs<IGQLListCheckpointsWithInvalidOrderQueryVariables>, 'query'>) {
  return Urql.useQuery<IGQLListCheckpointsWithInvalidOrderQuery, IGQLListCheckpointsWithInvalidOrderQueryVariables>({ query: ListCheckpointsWithInvalidOrderDocument, ...options });
};