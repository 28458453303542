import {generateDebug} from '@/utils';
import * as React from 'react';
import {ReactNode} from 'react';

const debug = generateDebug('ModifiedProvider');
debug('Running')

interface Handlers {
  save?: () => void
  saveCaption?: string
  reset?: () => void
  resetCaption?: string
}
interface ModifiedContextValue {
  isModified: boolean;
  setModified: (val: boolean) => void;
  setHandlers: (handlers: Handlers) => void
  handlers: Handlers
}

export const ModifiedContext = React.createContext<ModifiedContextValue>({
  isModified: false,
  setModified: () => {
  },
  setHandlers: () => {
  },
  handlers: {}
});

interface ModifiedProviderProps {
  children: ReactNode;
}

export default function ModifiedProvider(props: ModifiedProviderProps) {
  const [isModified, setModified] = React.useState<boolean>(false);
  const [handlers, setHandlers] = React.useState<Handlers>({});
  const value = React.useMemo(() => {
    debug('updating', isModified)
      return {
        isModified,
        setModified,
        setHandlers,
        handlers,
      }
    }, [handlers, isModified]
  );
  return (<ModifiedContext.Provider value={value} {...props} />);
}

export function useModified() {
  const context = React.useContext(ModifiedContext);
  if (context === undefined) {
    throw new Error('useModified must be used within a ModifiedProvider');
  }
  return context;
}
