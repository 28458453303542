import {
  AbilityContext,
  CurrentUserContext,
  ShiroContext,
} from "@/context/CurrentUserProvider";
import { OrganizationContext } from "@/context/OrganizationProvider";
import {
  IconCheckpoint,
  IconCourse,
  IconFolder,
  IconHome,
  IconLesson,
  IconOccasion,
  IconOrganization,
  IconPerks,
  IconSettings,
  IconUser,
  IconMaintenance,
} from "@/icons";
import { JrnyPermissions } from "@/JrnyPermissions";
import * as React from "react";
import { CourseContext } from "../../context/CourseProvider";
import { generateDebug } from "../../utils";
import { CheckpointBullet } from "./components/CheckpointBullet";
import {
  NavigationItem,
  NavigationSection,
  NavigationStructure,
} from "./navigation-types";
import { useOrganizations } from "@/context/OrganizationsProvider";
import { CaslVerb } from "@/CaslVerb";
import { subject } from "@casl/ability";
const debug = generateDebug("useSiteNavigation");

type MenuShape = {
  name: string;
  children: { name: string; icon: any; to: string }[];
};
export const useSiteNavigation = () => {
  const { currentUser } = React.useContext(CurrentUserContext);
  const { organization } = React.useContext(OrganizationContext);
  const { organizations } = useOrganizations();
  const { currentCourse: course } = React.useContext(CourseContext);
  debug("course", course);
  debug("organization", organization);
  debug("currentUser", currentUser);
  const shiro = React.useContext(ShiroContext);

  const ability = React.useContext(AbilityContext);
  return React.useMemo(() => {
    const result: NavigationStructure = [];
    const globalSection: NavigationSection = {
      type: "section",
      name: "",
      bgColor: "transparent",
      children: [
        {
          type: "link",
          id: "home",
          name: "Home",
          icon: <IconHome color="primary" />,
          path: "/",
        },
      ],
    };
    const adminSection: NavigationSection = {
      type: "section",
      name: "",
      bgColor: "white",
      children: [],
    };
    if (ability?.can(CaslVerb.ViewAdminUsers)) {
      adminSection.children.push({
        type: "link",
        id: "admins",
        name: "Admins",
        icon: <IconUser />,
        path: "/admin/admins",
      });
    }
    // if (shiro?.check(JrnyPermissions.JRNY_ORGANIZATION_LIST)) {
    //   adminSection.children.push({
    //     type: "link",
    //     id: "organizations",
    //     name: "Organizations",
    //     icon: <IconOrganization color="primary" />,
    //     path: "/admin/organizations",
    //   });
    // }
    const organizationSection: NavigationSection = {
      type: "section",
      name: "Organization",
      children: [
        {
          type: "select",
          id: "organization",
          icon: <IconOrganization color="primary" />,
          selected: organization
            ? {
                id: organization.id,
                name: organization.name,
                path: `/organization/${organization.id}`,
              }
            : {
                id: "-1",
                name: "-- SELECT ORGANIZATION --",
                path: "",
              },
          options:
            organizations
              ?.sort((a, b) => a.name.localeCompare(b.name))
              .map((o) => ({
                id: o.id,
                name: o.name,
                path: `/organization/${o.id}`,
              })) || [],
        },
        ...((organization
          ? [
              {
                type: "link",
                id: `${organization.id}-files`,
                name: "Files",
                icon: <IconFolder color="primary" />,
                path: `/organization/${organization.id}/files`,
              } as const,
              ability?.can(CaslVerb.UpdateAdminUsers, {
                organizationId: organization.id,
              })
                ? {
                    type: "link",
                    id: `${organization.id}-users`,
                    name: "Organization Admins",
                    icon: <IconUser color="primary" />,
                    path: `/organization/${organization.id}/admins`,
                  }
                : undefined,
              ability?.can(CaslVerb.UpdateAdminUsers, {
                organizationId: organization.id,
              })
                ? {
                    type: "link",
                    id: `${organization.id}-courses`,
                    name: "Courses",
                    icon: <IconCourse color="primary" />,
                    path: `/organization/${organization.id}/courses`,
                  }
                : undefined,
            ]
          : []
        ).filter(Boolean) as NavigationItem[]),
      ],
    };

    if (shiro?.check(JrnyPermissions.JRNY_SETTINGS_VIEW)) {
      adminSection.children.push({
        type: "link",
        id: "settings",
        name: "Settings",
        icon: <IconSettings color="primary" />,
        path: "/admin/settings",
      });
    }
    if (shiro?.check(JrnyPermissions.JRNY_MAINTENANCE_VIEW)) {
      adminSection.children.push({
        type: "link",
        id: "maintenance",
        name: "Maintenance",
        icon: <IconMaintenance color="primary" />,
        path: "/admin/maintenance",
      });
    }
    const courseSection: NavigationSection | undefined =
      organization && course
        ? {
            type: "section",
            name: "Course",
            children: [
              {
                type: "select",
                id: `course`,
                icon: <IconCourse color="primary" />,
                selected: {
                  id: course.id,
                  name: course.name,
                  path: `/organization/${organization.id}/course/${course.id}/`,
                },
                options: organization.courses.map((c) => ({
                  id: c.id,
                  name: c.name,
                  path: `/organization/${organization.id}/course/${c.id}/`,
                })),
              },
              ability?.can(CaslVerb.Update, course)
                ? {
                    type: "link",
                    id: `${course.id}-settings`,
                    name: "Settings",
                    icon: <IconSettings color="primary" />,
                    path: `/organization/${organization.id}/course/${course.id}/settings`,
                  }
                : undefined,
              {
                type: "link",
                id: `${organization.id}-perks`,
                name: "Perks",
                icon: <IconPerks color="primary" />,
                path: `/organization/${organization.id}/course/${course.id}/perks`,
              } as const,
              ability?.can(CaslVerb.UpdateAdminUsers, course)
                ? {
                    type: "link",
                    id: `${course.id}-users`,
                    name: "Course Admins",
                    icon: <IconUser color="primary" />,
                    path: `/organization/${organization.id}/course/${course.id}/admins`,
                  }
                : undefined,
              {
                type: "link",
                id: `${course.id}-roster`,
                name: "Roster",
                icon: <IconUser color="primary" />,
                path: `/organization/${organization.id}/course/${course.id}/users`,
              },
              ability?.can(CaslVerb.Update, course)
                ? {
                    type: "link",
                    id: `${course.id}-occasions`,
                    name: "Occasions",
                    icon: <IconOccasion color="primary" />,

                    path: `/organization/${organization.id}/course/${course.id}/occasions`,
                  }
                : undefined,
              {
                type: "link-parent",
                id: `${course.id}-checkpoints`,
                name: "Checkpoints",
                icon: <IconCheckpoint color="primary" />,
                children: course.checkpoints.map((cp) => ({
                  type: "link",
                  id: `${cp.id}`,
                  name: cp.name,
                  icon: <CheckpointBullet>{cp.order + 1}</CheckpointBullet>,
                  path: `/organization/${organization.id}/course/${course.id}/checkpoint/${cp.id}`,
                  children: cp.lessons.map(
                    (l) =>
                      ({
                        type: "link",
                        id: `${l.id}`,
                        name: `${cp.order + 1}.${l.order + 1} ${l.name}`,
                        path: `/organization/${organization.id}/course/${course.id}/checkpoint/${cp.id}/${l.id}`,
                      }) as const
                  ),
                })),
              },
            ].filter(Boolean) as NavigationItem[],
          }
        : undefined;
    result.push(globalSection);
    result.push({ type: "separator" });
    if (adminSection.children?.length) {
      result.push(adminSection);
    }
    if (organizationSection.children?.length) {
      result.push(organizationSection);
    }
    if (courseSection) {
      result.push(courseSection);
    }
    return result;
  }, [organizations, organization, shiro, ability, course]);
};
