import { List } from "@mui/material";


import { Box, Typography } from "@mui/material";
import { NavigationSection } from "../navigation-types";
import SideNavLink from "./SideNavLink";
import styles from './SideNavSection.module.css';
export const SideNavSection = ({item}: {item: NavigationSection}) => {
    const { name, children, bgColor } = item;
    return (
      <Box component="li" sx={{ py: 1, px: 0 }} className={styles.root}>
        {name && (
          <Typography className={styles.title}>          
            {name}
          </Typography>
        )}
        <Box sx={{bgcolor: bgColor || "white"}} className={styles.holder}>
          <List disablePadding>
            {children.map((child, ix) => <SideNavLink key={child.id} item={child} indentationLevel={0}/>)}
          </List>
        </Box>
      </Box>
    );
  };