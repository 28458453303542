import {
  Box,
  Collapse,
  IconButton,
  MenuItem,
  Menu,
  Typography,
  Button,
  Tooltip,
} from "@mui/material";

import { NavLink, useMatch, useNavigate } from "react-router-dom";

import { NavigationItem, NavigationSelect } from "../navigation-types";
import { ExpandMore } from "@mui/icons-material";
import { ExpandLess } from "@mui/icons-material";
import { ReactNode, useMemo, useState, useEffect } from "react";
import styles from "./SideNavLink.module.css";

function getTooltipTitle(item: NavigationItem): string {
  switch (item.type) {
    case "select":
      return item.selected.name;
    case "link":
    case "link-parent":
      return item.name;
  }
}

const getItemTitle = (item: NavigationItem) => {
  if (item.type === "select") {
    return item.selected.name;
  }
  return item.name;
};

export type SideNavLinkProps = {
  item: NavigationItem;
  indentationLevel: number;
};
export type SideNavSelectProps = {
  item: NavigationSelect;
  indentationLevel: number;
};
type NavLinkOrLabelProps = {
  item: NavigationItem;
  indentationLevel: number;
  children: (props: { isActive: boolean }) => ReactNode;
  onClick: () => void;
};
const NavLinkOrLabel = ({
  item,
  children,
  indentationLevel,
  onClick,
}: NavLinkOrLabelProps) => {
  const content = (props: { isActive: boolean }) => (
    <Box
      onClick={onClick}
      style={{
        height: height(indentationLevel),
        cursor: item.type === "link-parent" ? "pointer" : undefined,
      }}
      className={
        props.isActive ? [styles.link, styles.linkActive].join(" ") : styles.link
      }
    >
      {children(props)}
    </Box>
  );

  const wrappedContent = indentationLevel > 0 ? (
    <Tooltip title={getTooltipTitle(item)} enterDelay={500} placement="right-start">
      {content({ isActive: false })}
    </Tooltip>
  ) : (
    content({ isActive: false })
  );

  if (item.type === "link") {
    return (
      <NavLink
        to={item.path}
        className={({ isActive }) =>
          isActive ? [styles.link, styles.linkActive].join(" ") : styles.link
        }
      >
        {({ isActive }) =>
          indentationLevel > 0 ? (
            <Tooltip title={getTooltipTitle(item)} enterDelay={500} placement="right-start">
              {content({ isActive })}
            </Tooltip>
          ) : (
            content({ isActive })
          )
        }
      </NavLink>
    );
  } else if (item.type === "link-parent") {
    return wrappedContent;
  }
  return null;
};
const fontSize = (indentationLevel: number) => `${16 - indentationLevel * 2}px`;
const paddingLeft = (indentationLevel: number) => `${indentationLevel * 1}rem`;
const height = (indentationLevel: number) =>
  `${2.75 - indentationLevel * 0.3}rem`;

function SideNavSelect(props: SideNavSelectProps) {
  const { item, indentationLevel } = props;
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const match = useMatch({ path: item.selected.path, end: true });
  console.log(match);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget.parentElement);
  };
  const handleClose = (evt: React.MouseEvent<HTMLLIElement>) => {
    evt.stopPropagation();
    evt.preventDefault();
    setAnchorEl(null);
  };
  return (
    <Box
      className={
        match ? [styles.linkActive, styles.link].join(" ") : styles.link
      }
    
      onClick={() => navigate(item.selected.path)}
    >
      {item.icon && <Box className={styles.icon}>{item.icon}</Box>}
      <Typography
        component="span"
        className={styles.label}
        sx={{ fontSize: fontSize(indentationLevel) }}
      >
        {item.selected.name}
      </Typography>
      {item.options && (
        <IconButton
          size="small"
          className={styles.iconButton}
          onClick={handleClick}
          color="primary"
        >
          <ExpandMore fontSize="small" />
        </IconButton>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ sx: { width: "212px" } }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {item.options.map((option) => (
          <MenuItem
            key={option.id}
            onClick={(evt) => {
              handleClose(evt);
              navigate(option.path);
            }}
            selected={option.id == item.selected.id}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}

const isPathActive = (path: string): boolean => {
  return !!useMatch({ path, end: false });
};

export default function SideNavLink(props: SideNavLinkProps) {
  const { item, indentationLevel } = props;
  const [open, setOpen] = useState(false);
  
  // Move path matching logic into the component
  const currentMatch = useMatch({ path: "*", end: false });
  const currentPath = currentMatch?.pathname || "";
  
  // Check if current item or any children match the current path
  const isActive = item.type === "link" && currentPath.startsWith(item.path);
  const hasActive = item.type === "link-parent" && item.children?.some(child => 
    child.type === "link" && currentPath.startsWith(child.path)
  );
  
  useEffect(() => {
    if (isActive || hasActive) {
      setOpen(true);
    }
  }, [isActive, hasActive, currentPath]);

  const component = useMemo(() => {
    switch (item.type) {
      case "link":
      case "link-parent":
        return (
          <NavLinkOrLabel
            item={item}
            indentationLevel={indentationLevel}
            onClick={() => setOpen((prev) => !prev)}
          >
            {({ isActive }) => (
              <>
                <Box
                  sx={{ width: `${paddingLeft(indentationLevel)}` }}
                  className={styles.spacer}
                />
                {item.icon && <Box className={styles.icon}>{item.icon}</Box>}
                <Typography
                  component="span"
                  className={styles.label}
                  fontSize={fontSize(indentationLevel)}
                >
                  {item.name}
                </Typography>
                {item.children && (
                  <IconButton
                    size="small"
                    className={styles.iconButton}
                    onClick={(evt) => {
                      setOpen((prev) => !prev);
                      evt.preventDefault();
                      evt.stopPropagation();
                    }}
                    color="primary"
                  >
                    {open ? (
                      <ExpandLess fontSize="small" />
                    ) : (
                      <ExpandMore fontSize="small" />
                    )}
                  </IconButton>
                )}
              </>
            )}
          </NavLinkOrLabel>
        );
      case "select":
        const selectContent = (
          <SideNavSelect item={item} indentationLevel={indentationLevel} />
        );
        return indentationLevel > 1 ? (
          <Tooltip title={item.selected.name} placement="right">
            {selectContent}
          </Tooltip>
        ) : selectContent;
      default:
        return null;
    }
  }, [item, open, indentationLevel, currentPath]);
  
  return (
    <>
      <Box component="li" className={styles.root}>
        {component}
      </Box>
      {"children" in item && item.children && (
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          component="ul"
          className={[styles.children, styles.root].join(" ")}
        >
          {item.children.map((child) => (
            <SideNavLink
              key={child.id}
              item={child}
              indentationLevel={indentationLevel + 1}
            />
          ))}
        </Collapse>
      )}
    </>
  );
}
