import { useModificationGuard } from '@/context/ModificationGuardProvider';
import { useModified } from '@/context/ModifiedProvider';
import { IconCancel, IconSave } from "@/icons";
import { generateDebug } from '@/utils';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { ReactNode } from 'react';

const debug = generateDebug('GlobalModificationBar');

interface GlobalModificationBarProps {
  children: ReactNode;
}

export default function GlobalModificationBar(props: GlobalModificationBarProps) {
  const modifiedContextValue = useModified();
  const { isLocalBar } = useModificationGuard();
  // Don't show bottom navigation if modifications are handled locally
  const showBottomNav = modifiedContextValue.isModified && !isLocalBar;
  debug({isLocalBar, showBottomNav})

  return (
    <>
      {props.children}
      {showBottomNav && (
        <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0, zIndex:1}} elevation={3}>
          <BottomNavigation
            showLabels
            value={undefined}
            onChange={(event, newValue) => {
              if (newValue === 0) {
                modifiedContextValue.handlers.reset && modifiedContextValue.handlers.reset();
              } else if (newValue === 1) {
                modifiedContextValue.handlers.save && modifiedContextValue.handlers.save();
              }
            }}
          >
            <BottomNavigationAction
              disabled={!modifiedContextValue.handlers.reset}
              label="Reset"
              icon={<IconCancel sx={{color:'#f00'}}/>}
            />
            <BottomNavigationAction
              disabled={!modifiedContextValue.handlers.save}
              label="Save"
              icon={<IconSave sx={{color:'#0f0'}}/>}
            />
          </BottomNavigation>
        </Paper>
      )}
    </>
  );
} 