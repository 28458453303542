import {
  Box,
  TextField,
  Chip,
  Typography,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
} from "@mui/material";
import { Draft } from "immer";
import { Organization } from "../types";
import { generateDebug } from "@/utils";
import { IconDelete, IconOrganization } from "@/icons";
const debug = generateDebug("CourseSelector");
interface Course {
  id: string;
  name: string;
}

type LocalCourseIdsUpdater = (courseIds: Draft<string[]>) => string[];
interface CourseSelectorProps {
  namesById: Record<string, string>;

  organizationId: string;
  localCourseIds: string[];
  availableCourses: Course[];
  updateLocalCourseIds: (updater: LocalCourseIdsUpdater) => void;
}

export function CourseSelector(props: CourseSelectorProps) {
  const {
    organizationId,
    localCourseIds,
    namesById,
    availableCourses,
    updateLocalCourseIds,
  } = props;
  return (
    <Box display="flex" flexDirection="column" gap={1} sx={{border: '1px solid var(--mui-palette-divider)', borderRadius: 1, width: '100%'}}>
<Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{pb: 0, pt:1, px: 1}}>
  <IconOrganization sx={{color: 'var(--mui-palette-primary-200)', mr: 1}} />
<Typography variant="subtitle2">{namesById[organizationId]}</Typography>
  
</Stack>
      <Select
        multiple
        size="small"
        value={localCourseIds}
        onChange={({ target: { value } }) =>
          updateLocalCourseIds((draft) => {
            debug("onChange", namesById);
            return value instanceof Array ? value : [value];
          })
        }
        input={
          <OutlinedInput  size="small" />
        }
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip
                size="small"
                key={value}
                label={namesById[value] || value}
              />
            ))}
          </Box>
        )}
      >
        {availableCourses.map((course) => (
          <MenuItem key={course.id} value={course.id}>
            {course.name}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}
