import { Typography } from '@mui/material';
import React from 'react';
import { useMatches } from 'react-router-dom';
import { useCurrentCourse } from '@/context/CourseProvider';
import { Checkpoint, CrumbProps, crumbTypographyStyle } from './types';

export const CheckpointCrumb = ({ onUpdate }: CrumbProps) => {
  const { currentCourse } = useCurrentCourse();
  const matches = useMatches();
  const checkpointMatch = matches.find(match => match.params.checkpointId);
  const checkpointId = checkpointMatch?.params.checkpointId;
  
  const checkpoint = currentCourse?.checkpoints?.find((cp: Checkpoint) => cp.id === checkpointId);
  const displayText = checkpoint ? `${checkpoint.order + 1} ${checkpoint.name}` : 'Checkpoint';
  
  React.useEffect(() => {
    onUpdate?.(checkpoint?.name || '');
  }, [checkpoint?.name, checkpoint?.order]);

  return (
    <Typography component="span" color="inherit" sx={crumbTypographyStyle}>
      {displayText}
    </Typography>
  );
}; 