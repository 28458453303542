import { Unpacked } from "@/types";
import { generateDebug } from '@/utils';
import { IGQLOrganizationQuery, useOrganizationQuery } from '@graphql/organizations-hook';
import { sortBy } from 'lodash';
import * as React from 'react';
import { ReactNode, useState } from 'react';

export type Organization = Unpacked<IGQLOrganizationQuery['jrnyOrganization']>
const debug = generateDebug('OrganizationProvider');
debug('Running')

interface OrganizationContextValue {
  organization: Organization | undefined;
  fetching: boolean;
  error?: string;
  loadOrganization: (organizationId: string|null) => void;
}

export const OrganizationContext = React.createContext<OrganizationContextValue>({
  organization: undefined,
  fetching: false,
  loadOrganization: () => {},
});

interface OrganizationProviderProps {
  children: ReactNode;
}

//Return an organization with the all the entities sorted by order
const deepSort = (organization?: Organization): Organization | undefined => {
  if (!organization) {
    return undefined
  }
  const sorted = {
    ...organization, courses: sortBy(organization.courses.map(c => {
      return {
        ...c, checkpoints: sortBy(c.checkpoints.map(cp => {
          return {
            ...cp, lessons: sortBy(cp.lessons.map(l => {
              return {...l, contentItems: sortBy(l.contentItems, 'order')}
            }), 'order')
          }
        }), ['order'])
      }
    }), ['name'])
  }
  return sorted
}

export function OrganizationProvider(props: OrganizationProviderProps) {
  const [organizationId, setOrganizationId] = useState<string|null>(null);
  debug('organizationId', organizationId)
  const [organization] = useOrganizationQuery({
    variables: {id: organizationId!},
    pause: !organizationId,
  });
  const value = React.useMemo(() => ({
    fetching: organization.fetching,
    error: organization.error?.message,
    loadOrganization: setOrganizationId,
    organization: organizationId && organization.data?.jrnyOrganization.id==organizationId ? deepSort(organization.data?.jrnyOrganization as Organization) : undefined,
  }), [organizationId, organization]);


  return (<OrganizationContext.Provider value={value} {...props} />);
}

export const useCurrentOrganization = () => {
  const {
    organization: currentOrganization,
    fetching,
    error,
    loadOrganization,
  } = React.useContext(OrganizationContext);
  return {
    currentOrganization,
    fetching,
    error,
    loadOrganization,
  }
}
