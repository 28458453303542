import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import { RoleDescriptor } from '../RoleDescriptor';
import AddIcon from "@mui/icons-material/Add";
import { CourseByIdRole, Organization, RoleDescription } from '../../types';
import { Draft } from 'immer';
import { useState } from 'react';
import { CourseSelector } from '../CourseSelector';
import { generateDebug } from '@/utils';

const debug = generateDebug('CourseRoleTab')


type LocalRoleUpdater = (
    draft: Draft<CourseByIdRole>
  ) => void;
  
interface CourseRoleTabProps {
    namesById: Record<string, string>;
    descriptions: Readonly<RoleDescription[]>;
    availableOrganizations: ReadonlyArray<Organization>;
    localRole: CourseByIdRole;
    updateLocalRole: (updater: LocalRoleUpdater) => void;
  }

export function CourseRoleTab(props: CourseRoleTabProps) {
  const { descriptions, availableOrganizations, localRole, namesById,updateLocalRole } =
    props;

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const selectedOrganizations = availableOrganizations.filter((org) =>
        !!localRole.organizations?.[org.id]
      );
    
    return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box display="flex" flexDirection="column" gap={2}>
        {descriptions.map((description, index) => (
          <RoleDescriptor key={index} {...description} />
        ))}
      </Box>
      <Box display="flex" flexDirection="column" gap={2}>
        {selectedOrganizations.map((org) => (
            <CourseSelector
                key={org.id}
                organizationId={org.id}
                namesById={namesById}
                localCourseIds={localRole.organizations[org.id] || []}
                availableCourses={org.courses}
                updateLocalCourseIds={(updater) => updateLocalRole((draft) => {
                    const result = updater(draft.organizations[org.id]);
                    debug('updateLocalCourseIds', result, draft.organizations[org.id])
                    draft.organizations[org.id] = result;
                })}
            />
        ))}
      </Box>

      <Button
        variant="outlined"
        onClick={(event) => setAnchorEl(anchorEl ? null : event.currentTarget)}
        sx={{ alignSelf: "flex-start" }}
        startIcon={<AddIcon />}
      >
        Add Organization
      </Button>
      <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
        {availableOrganizations.map((org) => (
          <MenuItem
            key={org.id}
            onClick={() => {
              updateLocalRole((draft) => {
                if (!draft.organizations[org.id]) {
                  draft.organizations[org.id] = [];
                }
              });
              handleClose();
            }}
          >
            {org.name}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
} 