import { createTheme } from "@mui/material";

type ColorScale = {
  [K in (900 | 800 | 700 | 600 | 500 | 400 | 300 | 200 | 100 | 50 | 0) &
    number]: string;
} & {
  [K in
    | "900"
    | "800"
    | "700"
    | "600"
    | "500"
    | "400"
    | "300"
    | "200"
    | "100"
    | "50"
    | "0"]: string;
};

type RoleColor = {
  surface: string;
  main: string;
};

type JourneyColors = {
  primary: ColorScale;
  secondary: ColorScale;
  error: ColorScale;
  success: ColorScale;
  warning: ColorScale;
  roles: {
    admin: RoleColor;
    'course-creator': RoleColor;
    instructor: RoleColor;
    'instructor-team': RoleColor;
    student: RoleColor;
  };
};

export const journeyColors: JourneyColors = {
  primary: {
    900: "#391443",
    800: "#4A1A57",
    700: "#602271",
    600: "#7B2C91",
    500: "#87309F",
    400: "#9F59B2",
    300: "#AF74BF",
    200: "#C8A0D3",
    100: "#DABFE1",
    50: "#F3EAF5",
    0: "#FAF7FA",
  },
  secondary: {
    900: "#0D3960",
    800: "#114B7E",
    700: "#1561A3",
    600: "#1B7CD0",
    500: "#1E88E5",
    400: "#4BA0EA",
    300: "#68AFEE",
    200: "#98C8F3",
    100: "#B9DAF7",
    50: "#E9F3FC",
    0: "#E9F3FC",
  },
  error: {
    900: "#801C1C",
    800: "#9B1B1B",
    700: "#BF151C",
    600: "#D42424",
    500: "#E72B2B",
    400: "#EB5757",
    300: "#EF7979",
    200: "#F4ABAB",
    100: "#F9CDCD",
    50: "#FCEBEB",
    0: "#FEF5F5",
  },
  success: {
    900: "#1B4B1E",
    800: "#1F611E",
    700: "#277424",
    600: "#2E872A",
    500: "#30A132",
    400: "#52B151",
    300: "#71C16F",
    200: "#A6D9A5",
    100: "#C9E9C8",
    50: "#EAF6E9",
    0: "#F5FBF5",
  },
  warning: {
    900: "#663C00",
    800: "#805000",
    700: "#994900",
    600: "#B25200",
    500: "#CC5C00",
    400: "#D97700",
    300: "#E38800",
    200: "#EDAB4A",
    100: "#F5C883",
    50: "#FCF2E5",
    0: "#FEF9F2",
  },
  roles: {
    admin: {
      surface: "#F3EAF5",
      main: "#87309F",
    },
    'course-creator': {
      surface: "#E9F3FC",
      main: "#1E88E5",
    },
    instructor: {
      surface: "#E5F9EA",
      main: "#30A132",
    },
    'instructor-team': {
      surface: "#FEF9F2",
      main: "#CC5C00",
    },
    student: {
      surface: "#E0F2F1",
      main: "#00695C",
    },
  },
} as const;

export const journeyTheme = createTheme({
  cssVariables: true,
  typography: {
    fontFamily: 'Inter',
    allVariants: {
      letterSpacing: 0.03
    },
    h1: {
      fontSize: 96,
    },
    h2: {
      fontSize: 60,
    },
    h3: {
      fontSize: 48,
    },
    h4: {
      fontSize: 36,
    },
    h5: {
      fontSize: 24,
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
    },
    subtitle1: {
      fontSize: 12,
    },
  },

  palette: {
    primary: {
      main: journeyColors.primary[500],
      ...journeyColors.primary,
    },
    secondary: {
      main: journeyColors.secondary[500],
      ...journeyColors.secondary,
    },
    error: {
      main: journeyColors.error[500],
      ...journeyColors.error,
    },
    success: {
      main: journeyColors.success[500],
      ...journeyColors.success,
    },
    warning: {
      main: journeyColors.warning[500],
      ...journeyColors.warning,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 64,
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 6,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 12,
          paddingTop: 42,
          paddingBottom: 42,
          paddingLeft: 27,
          paddingRight: 27,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          paddingLeft: 16,
          paddingRight: 16,
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
  },
});
