import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { IconDelete } from "@/icons";

interface DeleteDialogProps {
  entityType: string;
  handler?: (remove: boolean) => void;
  disabled: boolean;
  content?: string | React.ReactNode;
  title?: string;
}

export function DeleteDialog(props: DeleteDialogProps) {
  const { handler, entityType, disabled, content, title } = props;
  return handler ? (
    <Dialog
      open={!!handler}
      onClose={() => {
        handler(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{title || `Remove this ${entityType}?`}</DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{
            p: 1,
            border: "1px solid",
            borderRadius: 0,
            borderColor: "grey.200",
          }}
        >
          {content || (
            <Typography variant="body2">
              This will delete all content but <strong>NOT</strong> the images,
              media or documents used in the {entityType}
              <br />
              <br />
            </Typography>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={disabled}
          onClick={() => {
            handler(false);
          }}
          color={"info"}
          variant={"contained"}
          autoFocus
          sx={{ backgroundColor: "grey.500" }}
        >
          Cancel
        </Button>
        <Button
          disabled={disabled}
          startIcon={<IconDelete />}
          onClick={() => handler(true)}
          color={"error"}
          variant={"contained"}
        >
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
}
