import DrawerLayout from "@/components/DrawerLayout";
import {
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
} from "@mui/material";
import { DataGrid, GridColDef, GridSortModel } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { RolesCell } from "./components/RolesCell";
import { UserCell } from "./components/UserCell";
import {
  IGQLUserProfilesQuery,
  useResendInvitationForUserMutation,
  useRevokeInvitationForUserMutation,
  useUserProfilesQuery,
  useUserProfilesForOrganizationQuery,
  useUserProfilesForCourseQuery,
} from "@graphql/users-hook";
import { useState, useMemo } from "react";
import {
  IGQLInvitationStatus,
  IGQLJrnyUserProfileSortField,
  IGQLSortDirection,
} from "@/graphql-types";
import { DebugView } from "@/components/DebugView";
import { IconContextMenu, IconAdd } from "@/icons";
import { Unpacked } from "@/types";
import { StatusChip } from "./components/StatusChip";
interface UsersWithDetailViewProps {
  routeId: string;
  organizationId?: string;
  courseId?: string;
}
type ListUserProfile = Unpacked<
  IGQLUserProfilesQuery["jrnyUserProfiles"]["items"]
>;
type ContextMenuProps = {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  item: ListUserProfile | null;
  revokeInvitation: (item: ListUserProfile) => void;
  resendInvitation: (item: ListUserProfile) => void;
};
const ContextMenu = ({
  anchorEl,
  onClose,
  item,
  revokeInvitation,
  resendInvitation,
}: ContextMenuProps) => {
  const navigate = useNavigate();
  const open = Boolean(anchorEl && item);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <MenuItem
        onClick={() => {
          onClose();
          if (item) {
            navigate(item.user.id);
          }
        }}
      >
        Profile
      </MenuItem>
      { (item?.status === IGQLInvitationStatus.Pending || item?.status === IGQLInvitationStatus.Revoked) && (
          <>
            <MenuItem
              onClick={() => {
                onClose();
                if (item) {
                  resendInvitation(item);
                }
              }}
            >
              Resend Invitation
            </MenuItem>
            <MenuItem
              onClick={() => {
                onClose();
                if (item) {
                  revokeInvitation(item);
                }
              }}
            >
              Revoke Invitation
          </MenuItem>
        </>
      )}
    </Menu>
  );
};
const columnToSort = (col: string): IGQLJrnyUserProfileSortField => {
  switch (col) {
    case "createdAt":
      return IGQLJrnyUserProfileSortField.CreatedAt;
    case "status":
      return IGQLJrnyUserProfileSortField.Status;
    default:
      return IGQLJrnyUserProfileSortField.Email;
  }
};
export function UsersWithDetailView({ routeId}: UsersWithDetailViewProps) {
  const { organizationId, courseId } = useParams();
  const navigate = useNavigate();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "createdAt", sort: "desc" },
  ]);
  const [, resendInvitation] = useResendInvitationForUserMutation();
  const [, revokeInvitation] = useRevokeInvitationForUserMutation();

  // Define common query variables
  const commonQueryVariables = {
    offset: paginationModel.page * paginationModel.pageSize,
    limit: paginationModel.pageSize,
    sort: sortModel[0]
      ? {
          field: columnToSort(sortModel[0].field),
          direction:
            sortModel[0].sort === "asc"
              ? IGQLSortDirection.Asc
              : IGQLSortDirection.Desc,
        }
      : undefined,
  };

  // Query for organization-specific users
  const [organizationResult] = useUserProfilesForOrganizationQuery({
    variables: {
      ...commonQueryVariables,
      organizationId: organizationId!,
    },
    requestPolicy: "cache-and-network",
    pause: !organizationId || !!courseId, // Pause if no organizationId or if courseId is present
  });

  // Query for course-specific users
  const [courseResult] = useUserProfilesForCourseQuery({
    variables: {
      ...commonQueryVariables,
      courseId: courseId!,
    },
    requestPolicy: "cache-and-network",
    pause: !courseId, // Pause if no courseId
  });

  // Query for all users (no organization or course filter)
  const [globalResult] = useUserProfilesQuery({
    variables: commonQueryVariables,
    requestPolicy: "cache-and-network",
    pause: !!organizationId || !!courseId, // Pause if organizationId or courseId is present
  });

  // Determine which result to use
  const result = courseId ? courseResult : organizationId ? organizationResult : globalResult;

  const { fetching: loading } = result;
  const [items, total] = useMemo(() => {
    if (courseId && courseResult.data) {
      return [courseResult.data?.jrnyUserProfilesForCourse.items, courseResult.data?.jrnyUserProfilesForCourse.total];
    } else if (organizationId && organizationResult.data) {
      return [organizationResult.data?.jrnyUserProfilesForOrganization.items, organizationResult.data?.jrnyUserProfilesForOrganization.total];
    } else if (globalResult.data) {
      return [globalResult.data?.jrnyUserProfiles.items, globalResult.data?.jrnyUserProfiles.total];
    }
    return [[], 0];
  }, [courseResult, organizationResult, globalResult]);
  const columns: GridColDef[] = [
    {
      field: "user",
      headerName: "User",
      flex: 1,
      maxWidth: 350,
      renderCell: (params) => (
        <UserCell
          name={`${params.row.user.firstName} ${params.row.user.lastName}`.trim()}
          email={params.row.user.email}
        />
      ),
    },
    {
      field: "roleSummary",
      headerName: "Roles",
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        // <DebugView>{JSON.stringify(params.row.roleSummary)}</DebugView>
        <RolesCell roleSummary={params.row.roleSummary} />
      ),
    },
    {
      field: "createdAt",
      headerName: "Added",
      width: 120,
      valueFormatter: (params) => new Date(params.value).toLocaleDateString(),
    },
    {
      field: "status",
      headerName: "Status",
      width: 110,
      renderCell: (params) => <StatusChip  status={params.row.status} />,
    },
    {
      field: "actions",
      headerName: "",
      width: 32,
      renderCell: (params) => (
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            setAnchorEl(event.currentTarget);
            setItem(params.row);
          }}
        >
          <IconContextMenu />
        </IconButton>
      ),
    },
  ];

  const handleRowClick = (params: any) => {
    navigate(params.row.user.id);
  };

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [item, setItem] = useState<ListUserProfile | null>(null);

  return (
    <DrawerLayout routeId={routeId}>
      <Container>
        <Paper sx={{ width: "100%", flexDirection: "column" }}>
          <Stack direction="row" spacing={2} alignItems="center" mb={2} justifyContent="flex-end">

          <Button
            variant="contained"
            color="secondary"
            size="small"
            sx={{
            }}
            onClick={() => navigate("create")}
            startIcon={<IconAdd />}
          >
            Add new user
          </Button>
          </Stack>
          <Stack>
          <DataGrid
            rows={items}
            rowCount={total}
            columns={columns}
            onRowClick={handleRowClick}
            getRowId={(row) => row.user.id}
            paginationMode="server"
            sortingMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            loading={loading}
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            hideFooterSelectedRowCount
            rowHeight={64}
          />
          </Stack>
        </Paper>
        {/* <DebugView>
          {JSON.stringify(data?.jrnyUserProfiles.items, null, 2)}
        </DebugView> */}
      </Container>
      <ContextMenu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        item={item}
        revokeInvitation={(item) => {
          revokeInvitation({ userId: item.user.id });
        }}
        resendInvitation={(item) => {
          resendInvitation({ userId: item.user.id });
        }}
      />
    </DrawerLayout>
  );
}
