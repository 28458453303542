import React from "react";
import { Chip, ChipProps, Typography, Box } from "@mui/material";
import { journeyColors } from "../../main/jouney-theme";

// Define the available role types based on the journey theme
type RoleType = keyof typeof journeyColors.roles;

// Map from role IDs to display names
const ROLE_NAME_MAP: Record<RoleType, string> = {
  "admin": "Admin",
  "course-creator": "Course Creator",
  "instructor": "Instructor",
  "instructor-team": "Instructor Team",
  "student": "Student",
};

export interface RoleChipProps extends Omit<ChipProps, 'label'> {
  roleId: string;
  count?: number;
  label?: string;
}

export const RoleChip = React.forwardRef<HTMLDivElement, RoleChipProps>(
  ({ roleId, count, label, sx, icon, ...chipProps }, ref) => {
    // Try to match the role ID to our theme role types
    const roleName = label || ROLE_NAME_MAP[roleId as RoleType] || 'Unknown';
    
    // Get role colors, defaulting to student if not found
    let roleColors = journeyColors.roles.student;
    if (ROLE_NAME_MAP[roleId as RoleType]) {
      roleColors = journeyColors.roles[roleId as keyof typeof journeyColors.roles];
    }
    
    // Create count icon if needed
    const countIcon = count && count > 1 ? (
      <Box
        sx={{
          ml: 0.5,
          width: 18,
          height: 18,
          borderRadius: '50%',
          backgroundColor: roleColors.main,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          fontSize="0.6rem"
          color="white"
          sx={{ 
            lineHeight: 1,
            fontWeight: 500,
          }}
        >
          {count}
        </Typography>
      </Box>
    ) : icon;

    return (
      <Chip
        ref={ref}
        label={roleName}
        size="small"
        sx={{ 
          borderRadius: 4,
          height: '24px',
          backgroundColor: roleColors.surface,
          color: roleColors.main,
          '& .MuiChip-label': { 
            px: 1,
            fontSize: '0.75rem',
            paddingLeft: count && count > 1 ? 1 : 1,
          },
          ...sx
        }}
        icon={countIcon}
        {...chipProps}
      />
    );
  }
);

RoleChip.displayName = 'RoleChip'; 