import { useAssets } from "@/context/AssetsProvider";
import { useCurrentUser } from "@/context/CurrentUserProvider";
import { useCurrentOrganization } from "@/context/OrganizationProvider";
import { stringAvatar } from "@/pages/user/utils/avatar-utils";
import { ExpandMore } from "@mui/icons-material";
import { IconButton, Avatar, Typography, MenuItem, Menu } from "@mui/material";
import Grid from "@mui/material/Grid2";
import * as React from "react";

export default function UserSection() {
  const { signOut, currentUser } = useCurrentUser();
  const { currentOrganization } = useCurrentOrganization();
  const { showFileChooser } = useAssets();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const doLogout = React.useCallback(() => {
    // setCurrentOrganization(null)
    signOut();
  }, []);
  const [_, name] = React.useMemo(() => {
    if (currentUser) {
      return [
        (currentUser.user?.firstName[0] || "") +
          (currentUser.user?.lastName[0] || "").toUpperCase(),
        (currentUser.user?.firstName + " " + currentUser.user?.lastName).trim() || currentUser.user.email,
      ];
    }
    return ["", ""];
  }, [currentUser]);
  return (
    <>
      <Grid color="primary.800" container alignItems="center" spacing={1} flexWrap={'nowrap'}>
        <Grid sx={{ flexShrink: 0 }}>
        <Avatar {...stringAvatar(name, undefined)}/>

        </Grid>
        <Grid>
          <Typography variant="body1" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{name}</Typography>
        </Grid>
        <Grid>
          <IconButton
            color="inherit"
          sx={{ p: 0.5 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
            <ExpandMore fontSize="small"/>
          </IconButton>
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
      >
        <MenuItem disabled dense>
          Logged in as {name}
        </MenuItem>
        {currentOrganization && (
          <MenuItem
            onClick={() => {
              showFileChooser({ type: "document", onSelect: undefined });
              handleClose();
            }}
          >
            Show files
          </MenuItem>
        )}
        <MenuItem onClick={doLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
}
