import {
  Avatar,
  Box, CardHeader,
  Chip,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper
} from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import CreatePerkDialog from './components/CreatePerkDialog';
import { useClonePerkMutation, useCreatePerkMutation, usePerksQuery } from "@graphql/perks-hook";
import { sortBy } from 'lodash'
import { ListPerk, Perk } from "@/pages/perks/types";
import { IconRefresh, IconGoTo, IconPhoto, IconDuplicate } from "@/icons";
import { useCurrentOrganization } from "@/context/OrganizationProvider";
import { useCurrentCourse } from '@/context/CourseProvider';

interface PerkListViewProps {

}

export function PerkListView(props: PerkListViewProps) {
  const { currentCourse } = useCurrentCourse();
  const navigate = useNavigate()
  const [perksQuery, refreshPerks] = usePerksQuery({
    variables: {
      courseId: currentCourse?.id || ''
    }, pause: !currentCourse
  })
  const perks = React.useMemo(() => sortBy(perksQuery.data?.jrnyPerks || [], ['title', 'created']), [perksQuery])
  const [, createPerk] = useCreatePerkMutation();
  const [, clonePerk] = useClonePerkMutation()

  return currentCourse
    ? (<Container>
      <Paper>
        <CardHeader
          title='Perks'
          action={
            <IconButton onClick={() => refreshPerks()}><IconRefresh /></IconButton>
          } />
        <List>
          {perks.map((perk: ListPerk) => (<ListItem
            onClick={() => navigate('./' + perk.id)}
            secondaryAction={(<>
              {perk.visible && <Chip sx={{ mr: 1 }} size='small' label='VISIBLE' color='primary' />}
              {perk.inStockCount>0 ? <Chip sx={{ mr: 1 }} size='small' label={`${perk.inStockCount}`} color='secondary' />:
              <Chip sx={{ mr: 1 }} size='small' label='OUT OF STOCK' color='error' />}
              <IconButton onClick={(evt) => {
                evt.stopPropagation();
                clonePerk({ input: { id: perk.id, title: perk.title + ' clone' } });
              }}
                edge='end'><IconDuplicate /></IconButton>
              <IconButton edge='end'><IconGoTo /></IconButton>
            </>)
            }
            sx={{ borderBottom: '1px solid rgba(0,0,0,.1)' }}
            key={perk.id}
          >
            <ListItemAvatar sx={{ width: 64 }}>
              <Avatar variant={'square'} style={{ width: 56, height: 56 }}>
                {perk.image
                  ? <img src={perk.image.url} style={{ width: 56, height: 56, objectFit: 'cover' }} />
                  : <IconPhoto />

                }
              </Avatar>
            </ListItemAvatar>
            <ListItemText secondary={perk.price + ' points'} primary={perk.title} />
          </ListItem>))}
        </List>
      </Paper>
      <CreatePerkDialog courseId={currentCourse.id} organizationId={currentCourse.organizationId} createPerk={createPerk} />
    </Container>)
    : null;
}
