import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Chip, Menu, MenuItem, Typography } from "@mui/material";
import { Draft } from "immer";
import { useState } from "react";
import {
  Organization,
  OrganizationByIdRole,
  RoleDescription,
} from "../../types";
import { RoleDescriptor } from "../RoleDescriptor";

type LocalRoleUpdater = (
  draft: Draft<OrganizationByIdRole>
) => void;
interface OrganizationRoleTabProps {
  namesById: Record<string, string>;
  descriptions: Readonly<RoleDescription[]>;
  availableOrganizations: ReadonlyArray<Organization>;
  localRole: OrganizationByIdRole;
  updateLocalRole: (updater: LocalRoleUpdater) => void;
}

export function OrganizationRoleTab(props: OrganizationRoleTabProps) {
  const { descriptions, availableOrganizations, localRole, updateLocalRole } =
    props;
  const selectedOrganizations = availableOrganizations.filter((org) =>
    localRole.organizationIds?.includes(org.id)
  );

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box display="flex" flexDirection="column" gap={2}>
        {descriptions.map((description, index) => (
          <RoleDescriptor key={index} {...description} />
        ))}
      </Box>
      <Box display="flex" flexDirection="row" gap={2}>
        {selectedOrganizations.map((org) => (
          <Chip key={org.id} label={org.name} onDelete={() => {
            updateLocalRole((draft) => {
              draft.organizationIds = draft.organizationIds.filter((id) => id !== org.id);
            });
          }} />
        ))}
      </Box>
      <Button
        variant="outlined"
        onClick={(event) => setAnchorEl(anchorEl ? null : event.currentTarget)}
        sx={{ alignSelf: "flex-start" }}
        startIcon={<AddIcon />}
      >
        Add Organization
      </Button>
      <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
        {availableOrganizations.map((org) => (
          <MenuItem
            key={org.id}
            onClick={() => {
              updateLocalRole((draft) => {
                if (!draft.organizationIds.includes(org.id)) {
                  draft.organizationIds.push(org.id);
                }
              });
              handleClose();
            }}
          >
            {org.name}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
