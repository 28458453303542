import {generateDebug} from '@/utils';
import {IconAdd} from '@/icons';
import {Fab, TextField} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import {IGQLCreatePerkMutationVariables} from "@graphql/perks-hook";
import {useSnackbar} from "notistack";

const debug = generateDebug('CreatePerkDialog');

interface CreatePerkDialogProps {
  organizationId: string
  courseId: string
  createPerk: (variables: IGQLCreatePerkMutationVariables) => Promise<any>;
}

export default function CreatePerkDialog(props: CreatePerkDialogProps) {
  const [open, setOpen] = React.useState(false);
  const [inProgress, setInProgress] = React.useState(false);
  const [title, setTitle] = React.useState('New Perk')
  const {enqueueSnackbar} = useSnackbar()

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async () => {
    debug('handleSubmit');
    const result = await props.createPerk({input: {courseId: props.courseId, organizationId: props.organizationId, title: title}});
    debug('Submit result', result);
    if (result.error) {
      enqueueSnackbar(result.error.message, {variant: 'error'})
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <Fab color='primary' onClick={handleClickOpen} sx={{position: 'fixed', bottom: 24, right: 24}}><IconAdd/></Fab>
      <Dialog open={open} onClose={inProgress ? () => {
      } : handleClose}>
        <DialogTitle>Create new perk</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter title
          </DialogContentText>
          <TextField autoFocus
                     disabled={inProgress}
                     label='Title'
                     margin='dense'
                     value={title} onChange={(evt) => setTitle(evt.target.value)}></TextField>
        </DialogContent>
        <DialogActions>
          <Button disabled={inProgress} onClick={handleClose}>Cancel</Button>
          <Button disabled={inProgress} onClick={handleSubmit}>Create</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
    ;
}
