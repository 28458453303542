import { useCurrentCourse } from "@/context/CourseProvider";
import {
  useCurrentOrganization
} from "@/context/OrganizationProvider";
import { generateDebug } from "@/utils";
import {
  LinearProgress,
  Stack
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";

const debug = generateDebug("OrganizationMain");

export function OrganizationMain() {
  const { fetching, error, currentOrganization, loadOrganization } = useCurrentOrganization();
  const { loadCourse } = useCurrentCourse();
  const { organizationId } = useParams();
  debug("fetching", fetching);
  debug("error", error);
  debug("currentOrganization", currentOrganization);
  useEffect(() => {
    if (organizationId) {
      if( organizationId !== currentOrganization?.id) {
        loadCourse(null);
        loadOrganization(organizationId);
      }
    }
  }, [organizationId]);
  return currentOrganization ? (
    <Outlet />
  ) : fetching ? (
    <Stack direction="row" spacing={2}>
      <Stack>
        <Typography>Loading organization</Typography>
      </Stack>
      <LinearProgress />
    </Stack>
  ) : error ? (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Stack>
        <Typography>Error loading organization</Typography>
      </Stack>
      <Typography>{error}</Typography>
    </Stack>
  ) : (
    <Typography>Organization not found</Typography>
  );
}
