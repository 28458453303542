import { Roles, RolesByRoleId, OrganizationByIdRole, CourseByIdRole } from "../types";
import { roleDefinitions } from "../roleDefinitions";

export const toByRole = (roles: Roles): RolesByRoleId => {
  const result = roles.reduce((acc, role) => {
    if (role.scope === "global") {
      role.roleIds?.forEach(roleId => {
        acc[roleId] = {
          scope: 'global'
        };
      });
    } else if (role.scope === "organization") {
      role.roleIds?.forEach(roleId => {
        if (!acc[roleId]) {
          acc[roleId] = {
            scope: 'organization',
            organizationIds: []
          };
        }
        if (role.organizationId) {
          (acc[roleId] as OrganizationByIdRole).organizationIds = [
            ...((acc[roleId] as OrganizationByIdRole).organizationIds || []),
            role.organizationId
          ];
        }
      });
    } else if (role.scope === "course") {
      role.roleIds?.forEach(roleId => {
        if (!acc[roleId]) {
          acc[roleId] = {
            scope: 'course',
            organizations: {}
          };
        }
        if (role.organizationId) {
          (acc[roleId] as CourseByIdRole).organizations = {
            ...(acc[roleId] as CourseByIdRole).organizations,
            [role.organizationId]: [
              ...((acc[roleId] as CourseByIdRole).organizations?.[role.organizationId] || []),
              role.courseId!
            ]
          };
        }
      });
    }
    return acc;
  }, {} as RolesByRoleId);

  // Sort roles according to roleDefinitions order
  const roleIds = Object.keys(roleDefinitions);
  return Object.keys(result)
    .sort((a, b) => roleIds.indexOf(a) - roleIds.indexOf(b))
    .reduce((acc, key) => {
      acc[key] = result[key];
      return acc;
    }, {} as RolesByRoleId);
};

export const sortByEntityNames = (roles: RolesByRoleId, organizationNames: Record<string, string>, courseNames: Record<string, string>): RolesByRoleId => {
  // Sort roles according to roleDefinitions order
  const roleIds = Object.keys(roleDefinitions);
  return Object.keys(roles)
    .sort((a, b) => roleIds.indexOf(a) - roleIds.indexOf(b))
    .reduce((acc, roleId) => {
      const role = roles[roleId];
      if (role.scope === 'global') {
        acc[roleId] = role;
      } else if (role.scope === 'organization' && role.organizationIds) {
        acc[roleId] = {
          ...role,
          organizationIds: role.organizationIds.sort((a, b) => 
            organizationNames[a].localeCompare(organizationNames[b])
          )
        };
      } else if (role.scope === 'course' && role.organizations) {
        acc[roleId] = {
          ...role,
          organizations: Object.keys(role.organizations)
            .sort((a, b) => organizationNames[a].localeCompare(organizationNames[b]))
            .reduce((orgAcc, orgId) => {
              orgAcc[orgId] = role.organizations![orgId].sort((a, b) => 
                courseNames[a].localeCompare(courseNames[b])
              );
              return orgAcc;
            }, {} as Record<string, string[]>)
        };
      }
      return acc;
    }, {} as RolesByRoleId);
};
