import AssignmentIcon from "@mui/icons-material/Assignment";
import BookIcon from "@mui/icons-material/Book";
import EditIcon from "@mui/icons-material/Edit";
import GroupIcon from "@mui/icons-material/Group";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PublishIcon from "@mui/icons-material/Publish";
import SecurityIcon from "@mui/icons-material/Security";
import SettingsIcon from "@mui/icons-material/Settings";
import { RoleDefinitions } from "./types";

export const roleDefinitions: RoleDefinitions = {
  admin: {
    id: 'admin',
    name: 'Admin',
    scope: 'global',
    roleDescriptions: [
      {
        icon: SecurityIcon,
        title: "Full System Access",
        description: "Complete access to all system features and settings",
      },
      {
        icon: BookIcon,
        title: "Course Management",
        description: "Create, edit, clone, and delete all courses across organizations",
      },
      {
        icon: GroupIcon,
        title: "User Management",
        description: "Create, delete, and modify all users across the platform",
      },
      {
        icon: SettingsIcon,
        title: "Organization Management",
        description: "Create, edit, and delete all organizations",
      },
      {
        icon: PublishIcon,
        title: "Perk Management",
        description: "Create, delete, and modify all perks across the platform",
      },
    ]
  },
  'course-creator': {
    id: 'course-creator',
    name: 'Course Creator',
    scope: 'organization',
    roleDescriptions: [
      {
        icon: BookIcon,
        title: "Course Management",
        description: "Create, edit, and clone courses within your organization",
      },
      {
        icon: GroupIcon,
        title: "User Management",
        description: "Create, delete, and modify users within your organization",
      },
      {
        icon: PublishIcon,
        title: "Perk Management",
        description: "Create, edit, and clone perks within your organization",
      },
    ]
  },
  'instructor-team': {
    id: 'instructor-team',
    name: 'Instructor Team',
    scope: 'course',
    roleDescriptions: [
      {
        icon: EditIcon,
        title: "Course Editing",
        description: "Edit courses you are a member of",
      },
      {
        icon: GroupIcon,
        title: "User Management",
        description: "Create new users for courses you are a member of",
      },
    ]
  },
  instructor: {
    id: 'instructor',
    name: 'Instructor',
    scope: 'course',
    roleDescriptions: [
      {
        icon: EditIcon,
        title: "Course Management",
        description: "Edit and clone courses within your organization",
      },
      {
        icon: GroupIcon,
        title: "User Management",
        description: "Create new users within your courses",
      },
      {
        icon: PublishIcon,
        title: "Perk Management",
        description: "Create perks for your courses",
      },
    ]
  },
  student: {
    id: 'student',
    name: 'Student',
    scope: 'course',
    roleDescriptions: [
      {
        icon: MenuBookIcon,
        title: "Course Access",
        description: "Access and participate in enrolled courses",
      },
      {
        icon: AssignmentIcon,
        title: "Assignment Submission",
        description: "Submit assignments and receive feedback",
      },
    ]
  }
} as const;
  