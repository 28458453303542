import { useModified } from "@/context/ModifiedProvider";
import {
  useCreateUserProfileMutation,
  useCreateUserProfileForOrganizationMutation,
  useCreateUserProfileForCourseMutation,
  useGetUserIdByEmailQuery,
  IGQLCreateUserProfileMutation,
} from "@/graphql/hooks/users-hook";
import { useLocalChange } from "@/hooks/use-local-change";
import { generateDebug } from "@/utils";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProfileInfo } from "./components/ProfileInfo";
import { RoleEditor } from "./components/RoleEditor";
import { toByRole, toRoles } from "./utils";

const debug = generateDebug("UserCreateView");

const INITIAL_PROFILE = {
  user: {
    email: "",
    firstName: "",
    lastName: "",
  },
  roles: {},
};

export function UserCreateView() {
  const navigate = useNavigate();
  const modified = useModified();
  const { organizationId, courseId } = useParams();
  const [emailInput, setEmailInput] = useState("");
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [welcomeMessage, setWelcomeMessage] = useState("");

  // Use the appropriate mutation hook based on context
  const [_, createProfileGlobal] = useCreateUserProfileMutation();
  const [__, createProfileForOrganization] = useCreateUserProfileForOrganizationMutation();
  const [___, createProfileForCourse] = useCreateUserProfileForCourseMutation();
  
  // Create a single create function that calls the appropriate mutation
  const createProfile = (variables: any) => {
    if (organizationId && !courseId) {
      return createProfileForOrganization({
        ...variables,
        organizationId,
      });
    } else if (courseId) {
      return createProfileForCourse({
        ...variables,
        courseId,
      });
    } else {
      return createProfileGlobal(variables);
    }
  };
  
  const [existingUserQuery, checkExistingUser] = useGetUserIdByEmailQuery({
    variables: { email: emailInput.toLowerCase().trim() },
    pause: true,
    requestPolicy: "network-only",
  });
  const handleClose = () => {
    navigate("..");
  };

  useEffect(() => {
    if (existingUserQuery.data?.jrnyGetUserIdByEmail) {
      navigate(`../${existingUserQuery.data.jrnyGetUserIdByEmail}`);
    } else if (!existingUserQuery.fetching && existingUserQuery.data) {
      setIsEmailSubmitted(true);
    }
  }, [existingUserQuery.data]);

  const handleEmailSubmit = () => {
    checkExistingUser({
      variables: { email: emailInput.toLowerCase().trim() },
    });
  };
  const initialProfile = useMemo(() => {
    return {
      user: { ...INITIAL_PROFILE.user, email: emailInput },
      roles: [],
    };
  }, [isEmailSubmitted, emailInput]);
  const [localProfile, setLocalProfile] = useLocalChange<
    { user: typeof INITIAL_PROFILE.user; roles: any[] },
    typeof INITIAL_PROFILE
  >(
    initialProfile,
    // save the local profile to the server
    // @ts-ignore
    async (org, local) => {
      setOpenMessageDialog(true);
      // TODO: Modify useLocalChange to allow for a callback to be called when the local profile is saved
    },
    // org to local
    (org) => ({
      user: org.user,
      roles: toByRole(org.roles),
    }),
    // local to org
    (local) => ({
      user: local.user,
      roles: toRoles(local.roles),
    })
  );

  const handleConfirmSave = async () => {
    setOpenMessageDialog(false);
    const result = await createProfile({
      input: {
        user: localProfile!.user,
        roles: toRoles(localProfile!.roles),
        message: welcomeMessage,
      },
    });
    if( !result.data || result.error) {
      console.error(result.error);
      return;
    }
    // Determine which mutation was used and access the result accordingly
    const createdProfile = organizationId && !courseId && 'jrnyCreateUserProfileForOrganization' in result.data
      ? result.data.jrnyCreateUserProfileForOrganization 
      : courseId && 'jrnyCreateUserProfileForCourse' in result.data
        ? result.data.jrnyCreateUserProfileForCourse
        : (result.data as IGQLCreateUserProfileMutation).jrnyCreateUserProfile;

    if (createdProfile?.user.id) {
      modified.setModified(false);
      setTimeout(() => {
        navigate(`../${createdProfile.user.id}`);
      }, 1);
    }
    return result;
  };

  return (
    <Paper sx={{ height: "100%" }}>
      <Box p={3} display="flex" flexDirection="column" gap={3}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5">Add new user</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Box flexGrow={1}>
            {isEmailSubmitted ? (
              <ProfileInfo
                email={emailInput}
                firstName={localProfile?.user.firstName || ""}
                lastName={localProfile?.user.lastName || ""}
                status="new"
                setLocalProfile={setLocalProfile}
              />
            ) : (
              <Box display="flex" gap={2}>
                <TextField
                  label="Email to Invite"
                  value={emailInput}
                  size="small"
                  onChange={(e) => setEmailInput(e.target.value)}
                  fullWidth
                />
                <Button
                  variant="contained"
                  onClick={handleEmailSubmit}
                  disabled={!emailInput}
                >
                  Next
                </Button>
              </Box>
            )}
          </Box>
        </Box>

        {isEmailSubmitted && localProfile && (
          <RoleEditor
            organizationId={organizationId}
            courseId={courseId}
            localRoles={localProfile.roles}
            setLocalRoles={(updater) =>
              setLocalProfile((draft) => {
                if (draft) {
                  updater(draft.roles);
                }
              })
            }
          />
        )}
      </Box>

      <Dialog
        open={openMessageDialog}
        onClose={() => setOpenMessageDialog(false)}
      >
        <DialogTitle>Welcome Message</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Message"
            fullWidth
            multiline
            sx={{ minWidth: 400 }}
            rows={4}
            value={welcomeMessage}
            onChange={(e) => setWelcomeMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenMessageDialog(false)}>Cancel</Button>
          <Button onClick={handleConfirmSave} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
