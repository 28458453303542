import { SxProps } from "@mui/material";

export interface CrumbProps {
  onContentChange?: () => void;
  onUpdate?: (content: string) => void;
  sx?: SxProps;   
}

export interface Checkpoint {
  id: string;
  name: string;
  order: number;
  lessons: Lesson[];
}

export interface Lesson {
  id: string;
  name: string;
  order: number;
  contentItems: Activity[];
}

export interface Activity {
  id: string;
  name: string;
  order: number;
  categoryOrder: number;
  __typename: 'JrnyPrompt' | 'JrnyMedia' | 'JrnyResource';
}

export type ResourcedItem = { 
  item: Activity;
  resources: Activity[];
}

export interface RouteHandle {
  crumb: () => React.ReactNode;
}

export const crumbTypographyStyle = {
  whiteSpace: 'nowrap' as const,
  overflow: 'hidden' as const,
  textOverflow: 'ellipsis' as const,
  minWidth: 0,
  color: 'inherit'
};

