import { IGQLMutationJrnyAddCourseRoleToUserArgs } from '@/graphql-types';
import { generateDebug } from '@/utils';
import { FormTextField } from "@components/form/FormTextField";
import { email } from '@components/form/validation';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FORM_ERROR } from 'final-form';
import * as React from 'react';
import { useEffect } from "react";
import { useForm } from 'react-hook-form';

const debug = generateDebug('AddCourseUserDialog');

interface AddCourseUserDialogProps {
  addUser: (variables: IGQLMutationJrnyAddCourseRoleToUserArgs) => Promise<any>;
  courseId: string
  role?: string
  onClose: () => void
}

type FormValues = { email: string, firstName: string, lastName: string, message: string }
export default function AddCourseUserDialog(props: AddCourseUserDialogProps) {
  const [open, setOpen] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const {handleSubmit, control, reset} = useForm<FormValues>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      message: '',
    }
  });

  useEffect(() => {
    if( !props.role ) {
      reset()
    }
  }, [props.role])
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    props.onClose();
  };
  const doSubmit = async (values: FormValues) => {
    debug('handleSubmit', values);
    setSubmitting(true);
    const result = await props.addUser({
      input: {
        courseId: props.courseId,
        emailCandidate: {
          email: values.email.trim(),
          firstName: values.firstName.trim() || null,
          lastName: values.lastName.trim() || null,
        },
        roleId: props.role!,
        message: values.message.trim() || null,
      }
    });
    debug('Submit result', result);
    setSubmitting(false);
    if (result.error) {
      return {[FORM_ERROR]: result.error.graphQLErrors[0].message};
    }
    props.onClose();
  };

  return (
    <div>
      <Dialog open={!!props.role} onClose={submitting ? () => {
      } : handleClose}>
        <DialogTitle>Add {props.role||'user'} to course</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Email is required. Name is optional. When the user signs up, they will be asked to set their name.
          </DialogContentText>
          <Box sx={{mt: 6}}>
            <FormTextField
              control={control}
              autoFocus
              rules={{
                required: 'Email is required',
                validate:  val => email(val as string)||true
              }}

              disabled={submitting}
              fullWidth
              label='Email'
              margin='dense'
              name='email'
            />
            <Stack direction='row' spacing={2}>
            <FormTextField
              control={control}
              disabled={submitting}
              fullWidth
              label='First name (optional)'
              margin='dense'
              name='firstName'
              helperText='Temporary until user sets their name.'
            />
            <FormTextField
              control={control}
              disabled={submitting}
              fullWidth
              label='Last name (optional)'
              margin='dense'
              name='lastName'
              helperText='Temporary until user sets their name.'
            />

            </Stack>
            <FormTextField
              control={control}
              disabled={submitting}
              fullWidth
              label='Message (optional)'
              margin='dense'
              name='message'
              multiline
              rows={4}
              helperText='If user is not registered in the system, this message will be sent to them together with the site invitation.'
            />
            
          </Box>
        </DialogContent>
        <DialogActions>
          <Button disabled={submitting} onClick={handleClose}>Cancel</Button>
          <Button disabled={submitting} onClick={handleSubmit(doSubmit)}>Add</Button>
          {/*<DevTool control={control}/>*/}
        </DialogActions>
      </Dialog>
    </div>
  );
}
