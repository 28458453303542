import * as Types from '../../graphql-types';

import { gql } from 'urql';
import { ListOrganizationFieldsFragmentDoc } from './fragment-ListOrganization-hook';
import { FileObjectFieldsFragmentDoc } from './fragment-FileObject-hook';
import { ContentItemsFragmentDoc } from './lessons-hook';
import { OrganizationUserFieldsFragmentDoc } from './fragment-OrganizationUser-hook';
import { FileFolderFieldsFragmentDoc } from './fragment-Folder-hook';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IGQLOrganizationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IGQLOrganizationsQuery = { __typename: 'Query', jrnyOrganizations: Array<{ __typename: 'JrnyOrganization', id: string, name: string, courseCount: number, logo?: { __typename: 'FileObject', id: string, url: string } | null }> };

export type IGQLOrganizationNamesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IGQLOrganizationNamesQuery = { __typename: 'Query', jrnyOrganizations: Array<{ __typename: 'JrnyOrganization', id: string, name: string, courses: Array<{ __typename: 'JrnyCourse', id: string, name: string }> }> };

export type IGQLOrganizationQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type IGQLOrganizationQuery = { __typename: 'Query', jrnyOrganization: { __typename: 'JrnyOrganization', id: string, name: string, courses: Array<{ __typename: 'JrnyCourse', id: string, organizationId: string, name: string, available: boolean, perkCount: number, studentCount: number, mapImage?: { __typename: 'FileObject', id: string, type?: string | null, name?: string | null, originalName: string, contentType: string, size: number, url: string, folderId: string, metadata?: any | null, created: any } | null, checkpoints: Array<{ __typename: 'JrnyCheckpoint', id: string, courseId: string, name: string, order: number, lessons: Array<{ __typename: 'JrnyLesson', id: string, name: string, checkpointId: string, order: number, contentItems: Array<{ __typename: 'JrnyMedia', lessonId: string, id: string, name: string, mediaType: Types.IGQLJrnyMediaType, iframe?: any | null, order: number, categoryOrder: number, category: string } | { __typename: 'JrnyPrompt', lessonId: string, id: string, name: string, order: number, categoryOrder: number, category: string, useAIAssessment: boolean } | { __typename: 'JrnyResource', lessonId: string, id: string, name: string, order: number, type: Types.IGQLJrnyResourceType, categoryOrder: number, category: string }> }> }> }> } };

export type IGQLOrganizationUsersQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type IGQLOrganizationUsersQuery = { __typename: 'Query', jrnyOrganizationUsers: Array<{ __typename: 'JrnyOrganizationUser', id: string, firstName: string, lastName: string, email: string, organizationId: string }> };

export type IGQLCreateOrganizationMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyCreateOrganizationInput;
}>;


export type IGQLCreateOrganizationMutation = { __typename: 'Mutation', jrnyCreateOrganization: { __typename: 'JrnyOrganization', id: string, name: string, courseCount: number, logo?: { __typename: 'FileObject', id: string, url: string } | null } };

export type IGQLOrganizationFilesQueryVariables = Types.Exact<{
  input: Types.IGQLJrnyOrganizationFilesInput;
}>;


export type IGQLOrganizationFilesQuery = { __typename: 'Query', jrnyOrganizationFiles: Array<{ __typename: 'FileObject', id: string, type?: string | null, name?: string | null, originalName: string, contentType: string, size: number, url: string, folderId: string, metadata?: any | null, created: any }> };

export type IGQLOrganizationFoldersQueryVariables = Types.Exact<{
  input: Types.IGQLJrnyOrganizationFoldersInput;
}>;


export type IGQLOrganizationFoldersQuery = { __typename: 'Query', jrnyOrganizationFolders: Array<{ __typename: 'FileFolder', id: string, name: string, parentId?: string | null, created: any, fileObjects: Array<{ __typename: 'FileObject', id: string, type?: string | null, name?: string | null, originalName: string, contentType: string, size: number, url: string, folderId: string, metadata?: any | null, created: any }> }> };

export type IGQLCreateFolderMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyCreateFolderInput;
}>;


export type IGQLCreateFolderMutation = { __typename: 'Mutation', jrnyCreateFolder: { __typename: 'FileFolder', id: string, name: string, parentId?: string | null, created: any, fileObjects: Array<{ __typename: 'FileObject', id: string, type?: string | null, name?: string | null, originalName: string, contentType: string, size: number, url: string, folderId: string, metadata?: any | null, created: any }> } };

export type IGQLRenameFolderMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyRenameFolderInput;
}>;


export type IGQLRenameFolderMutation = { __typename: 'Mutation', jrnyRenameFolder: { __typename: 'FileFolder', id: string, name: string } };

export type IGQLMoveFolderMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyMoveFolderInput;
}>;


export type IGQLMoveFolderMutation = { __typename: 'Mutation', jrnyMoveFolder: { __typename: 'FileFolder', id: string, parentId?: string | null } };

export type IGQLDeleteFolderMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyDeleteFolderInput;
}>;


export type IGQLDeleteFolderMutation = { __typename: 'Mutation', jrnyDeleteFolder: { __typename: 'FileFolder', id: string, parentId?: string | null } };

export type IGQLDeleteFileMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyDeleteFileInput;
}>;


export type IGQLDeleteFileMutation = { __typename: 'Mutation', jrnyDeleteFile: { __typename: 'FileObject', id: string, folderId: string } };

export type IGQLMoveFileMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyMoveFileInput;
}>;


export type IGQLMoveFileMutation = { __typename: 'Mutation', jrnyMoveFile: { __typename: 'JrnyMoveFilePayload', source: { __typename: 'FileFolder', id: string, fileObjects: Array<{ __typename: 'FileObject', id: string, folderId: string }> }, target: { __typename: 'FileFolder', id: string, fileObjects: Array<{ __typename: 'FileObject', id: string, folderId: string }> } } };


export const OrganizationsDocument = gql`
    query organizations {
  jrnyOrganizations {
    ...ListOrganizationFields
  }
}
    ${ListOrganizationFieldsFragmentDoc}`;

export function useOrganizationsQuery(options?: Omit<Urql.UseQueryArgs<IGQLOrganizationsQueryVariables>, 'query'>) {
  return Urql.useQuery<IGQLOrganizationsQuery, IGQLOrganizationsQueryVariables>({ query: OrganizationsDocument, ...options });
};
export const OrganizationNamesDocument = gql`
    query organizationNames {
  jrnyOrganizations {
    id
    name
    courses {
      id
      name
    }
  }
}
    `;

export function useOrganizationNamesQuery(options?: Omit<Urql.UseQueryArgs<IGQLOrganizationNamesQueryVariables>, 'query'>) {
  return Urql.useQuery<IGQLOrganizationNamesQuery, IGQLOrganizationNamesQueryVariables>({ query: OrganizationNamesDocument, ...options });
};
export const OrganizationDocument = gql`
    query organization($id: ID!) {
  jrnyOrganization(id: $id) {
    id
    name
    courses {
      __typename
      id
      organizationId
      name
      available
      perkCount
      mapImage {
        ...FileObjectFields
      }
      studentCount
      checkpoints {
        __typename
        id
        courseId
        name
        order
        lessons {
          __typename
          id
          name
          checkpointId
          order
          ...ContentItems
        }
      }
    }
  }
}
    ${FileObjectFieldsFragmentDoc}
${ContentItemsFragmentDoc}`;

export function useOrganizationQuery(options: Omit<Urql.UseQueryArgs<IGQLOrganizationQueryVariables>, 'query'>) {
  return Urql.useQuery<IGQLOrganizationQuery, IGQLOrganizationQueryVariables>({ query: OrganizationDocument, ...options });
};
export const OrganizationUsersDocument = gql`
    query organizationUsers($id: ID!) {
  jrnyOrganizationUsers(id: $id) {
    ...OrganizationUserFields
  }
}
    ${OrganizationUserFieldsFragmentDoc}`;

export function useOrganizationUsersQuery(options: Omit<Urql.UseQueryArgs<IGQLOrganizationUsersQueryVariables>, 'query'>) {
  return Urql.useQuery<IGQLOrganizationUsersQuery, IGQLOrganizationUsersQueryVariables>({ query: OrganizationUsersDocument, ...options });
};
export const CreateOrganizationDocument = gql`
    mutation createOrganization($input: JrnyCreateOrganizationInput!) {
  jrnyCreateOrganization(input: $input) {
    ...ListOrganizationFields
  }
}
    ${ListOrganizationFieldsFragmentDoc}`;

export function useCreateOrganizationMutation() {
  return Urql.useMutation<IGQLCreateOrganizationMutation, IGQLCreateOrganizationMutationVariables>(CreateOrganizationDocument);
};
export const OrganizationFilesDocument = gql`
    query organizationFiles($input: JrnyOrganizationFilesInput!) {
  jrnyOrganizationFiles(input: $input) {
    ...FileObjectFields
  }
}
    ${FileObjectFieldsFragmentDoc}`;

export function useOrganizationFilesQuery(options: Omit<Urql.UseQueryArgs<IGQLOrganizationFilesQueryVariables>, 'query'>) {
  return Urql.useQuery<IGQLOrganizationFilesQuery, IGQLOrganizationFilesQueryVariables>({ query: OrganizationFilesDocument, ...options });
};
export const OrganizationFoldersDocument = gql`
    query organizationFolders($input: JrnyOrganizationFoldersInput!) {
  jrnyOrganizationFolders(input: $input) {
    ...FileFolderFields
  }
}
    ${FileFolderFieldsFragmentDoc}`;

export function useOrganizationFoldersQuery(options: Omit<Urql.UseQueryArgs<IGQLOrganizationFoldersQueryVariables>, 'query'>) {
  return Urql.useQuery<IGQLOrganizationFoldersQuery, IGQLOrganizationFoldersQueryVariables>({ query: OrganizationFoldersDocument, ...options });
};
export const CreateFolderDocument = gql`
    mutation createFolder($input: JrnyCreateFolderInput!) {
  jrnyCreateFolder(input: $input) {
    ...FileFolderFields
  }
}
    ${FileFolderFieldsFragmentDoc}`;

export function useCreateFolderMutation() {
  return Urql.useMutation<IGQLCreateFolderMutation, IGQLCreateFolderMutationVariables>(CreateFolderDocument);
};
export const RenameFolderDocument = gql`
    mutation renameFolder($input: JrnyRenameFolderInput!) {
  jrnyRenameFolder(input: $input) {
    id
    name
  }
}
    `;

export function useRenameFolderMutation() {
  return Urql.useMutation<IGQLRenameFolderMutation, IGQLRenameFolderMutationVariables>(RenameFolderDocument);
};
export const MoveFolderDocument = gql`
    mutation moveFolder($input: JrnyMoveFolderInput!) {
  jrnyMoveFolder(input: $input) {
    id
    parentId
  }
}
    `;

export function useMoveFolderMutation() {
  return Urql.useMutation<IGQLMoveFolderMutation, IGQLMoveFolderMutationVariables>(MoveFolderDocument);
};
export const DeleteFolderDocument = gql`
    mutation deleteFolder($input: JrnyDeleteFolderInput!) {
  jrnyDeleteFolder(input: $input) {
    id
    parentId
  }
}
    `;

export function useDeleteFolderMutation() {
  return Urql.useMutation<IGQLDeleteFolderMutation, IGQLDeleteFolderMutationVariables>(DeleteFolderDocument);
};
export const DeleteFileDocument = gql`
    mutation deleteFile($input: JrnyDeleteFileInput!) {
  jrnyDeleteFile(input: $input) {
    id
    folderId
  }
}
    `;

export function useDeleteFileMutation() {
  return Urql.useMutation<IGQLDeleteFileMutation, IGQLDeleteFileMutationVariables>(DeleteFileDocument);
};
export const MoveFileDocument = gql`
    mutation moveFile($input: JrnyMoveFileInput!) {
  jrnyMoveFile(input: $input) {
    source {
      id
      fileObjects {
        id
        folderId
      }
    }
    target {
      id
      fileObjects {
        id
        folderId
      }
    }
  }
}
    `;

export function useMoveFileMutation() {
  return Urql.useMutation<IGQLMoveFileMutation, IGQLMoveFileMutationVariables>(MoveFileDocument);
};