import { RolesByRoleId, Roles, GlobalByIdRole, OrganizationByIdRole, CourseByIdRole } from "../types";
import { sortRoles, sortRoleIds } from "./roleSort";

export const toRoles = (rolesByRoleId?: RolesByRoleId): Roles => {
  // First collect all roles
  if (!rolesByRoleId) return [];
  const roles = Object.entries(rolesByRoleId).reduce((acc, [roleId, role]) => {
    if (role.scope === 'global') {
      // Find existing global role or create new one
      const existingGlobalRole = acc.find(r => r.scope === 'global');
      if (existingGlobalRole) {
        existingGlobalRole.roleIds = [...(existingGlobalRole.roleIds || []), roleId];
      } else {
        acc.push({
          scope: 'global',
          roleIds: [roleId]
        });
      }
    } else if (role.scope === 'organization') {
      const organizationRole = role as OrganizationByIdRole;
      organizationRole.organizationIds.forEach(organizationId => {
        acc.push({
          scope: 'organization',
          organizationId,
          roleIds: [roleId]
        });
      });
    } else if (role.scope === 'course') {
      const courseRole = role as CourseByIdRole;
      Object.entries(courseRole.organizations).forEach(([organizationId, courseIds]) => {
        courseIds.forEach(courseId => {
          acc.push({
            scope: 'course',
            organizationId,
            courseId,
            roleIds: [roleId]
          });
        });
      });
    }
    return acc;
  }, [] as Roles);

  // Then merge roles with same organization and course
  const mergedRoles = roles.reduce((acc, role) => {
    if (role.scope === 'global') {
      acc.push(role);
    } else if (role.scope === 'organization') {
      const existingRole = acc.find(r => 
        r.scope === 'organization' && 
        r.organizationId === role.organizationId
      );
      if (existingRole && role.roleIds) {
        existingRole.roleIds = [...(existingRole.roleIds || []), ...role.roleIds];
      } else {
        acc.push(role);
      }
    } else if (role.scope === 'course') {
      const existingRole = acc.find(r => 
        r.scope === 'course' && 
        r.organizationId === role.organizationId && 
        r.courseId === role.courseId
      );
      if (existingRole && role.roleIds) {
        existingRole.roleIds = [...(existingRole.roleIds || []), ...role.roleIds];
      } else {
        acc.push(role);
      }
    }
    return acc;
  }, [] as Roles);

  // Sort roleIds within each role and sort the roles array
  const rolesWithSortedIds = mergedRoles.map(role => {
    if (role.roleIds) {
      role.roleIds = sortRoleIds(role.roleIds);
    }
    return role;
  });

  return sortRoles(rolesWithSortedIds);
}; 