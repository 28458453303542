import { Typography } from '@mui/material';
import React from 'react';
import { useMatches } from 'react-router-dom';
import { useCurrentCourse } from '@/context/CourseProvider';
import { Activity, Checkpoint, Lesson, CrumbProps, ResourcedItem, crumbTypographyStyle } from './types';

export const ActivityCrumb = ({ onUpdate }: CrumbProps) => {
  const { currentCourse } = useCurrentCourse();
  const matches = useMatches();
  const match = matches[matches.length - 1];
  const activityType = match.pathname.split('/').slice(-2)[0]; // prompt, challenge, media, or resource
  const paramName = activityType==='challenge'?'promptId':activityType+'Id'
  const activityId = match.params[paramName];
  console.log('activityId', paramName, activityId, activityType, match.params)
  const checkpointMatch = matches.find(match => match.params.checkpointId);
  const lessonMatch = matches.find(match => match.params.lessonId);
  const checkpointId = checkpointMatch?.params.checkpointId;
  const lessonId = lessonMatch?.params.lessonId;
  
  const checkpoint = currentCourse?.checkpoints?.find((cp: Checkpoint) => cp.id === checkpointId);
  const lesson = checkpoint?.lessons?.find((l: Lesson) => l.id === lessonId);

  // Group resources with their previous items, just like in LessonView
  const resourcedItems = React.useMemo(() => {
    const items = lesson?.contentItems || [];
    const result: ResourcedItem[] = [];
    let lastItem: ResourcedItem | null = null;
    
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.__typename === 'JrnyResource') {
        if (lastItem) {
          lastItem.resources.push(item);
        }
      } else {
        lastItem = { item, resources: [] };
        result.push(lastItem);
      }
    }
    return result;
  }, [lesson]);

  // Find the activity and its context
  let parentIndex = -1;
  let resourceIndex = -1;
  let activity: Activity | undefined;
  if (lesson?.contentItems) {
    console.log('lesson.contentItems', activityId, lesson.contentItems)
    activity = lesson.contentItems.find(item => item.id === activityId);
    
    // If this is a resource, find its parent item and its index within the parent's resources
    if (activity?.__typename === 'JrnyResource') {
      for (let i = 0; i < resourcedItems.length; i++) {
        const resourceIndex = resourcedItems[i].resources.findIndex(r => r.id === activityId);
        if (resourceIndex !== -1) {
          parentIndex = i;
          break;
        }
      }
      if (parentIndex !== -1) {
        resourceIndex = resourcedItems[parentIndex].resources.findIndex(r => r.id === activityId);
      }
    } else {
      // For non-resources, just find the item's index in resourcedItems
      parentIndex = resourcedItems.findIndex(ri => ri.item.id === activityId);
    }
  }

  // Construct the number prefix
  let numberPrefix = '';
  if (checkpoint && lesson) {
    const basePrefix = `${checkpoint.order + 1}.${lesson.order + 1}.${parentIndex + 1}`;
    if (activity?.__typename === 'JrnyResource' && resourceIndex !== -1) {
      numberPrefix = `${basePrefix}.${resourceIndex + 1}`;
    } else {
      numberPrefix = basePrefix;
    }
  }

  const displayText = activity 
    ? `${numberPrefix} ${activity.name}`
    : `${activityType.charAt(0).toUpperCase() + activityType.slice(1)}`;

  React.useEffect(() => {
    onUpdate?.(activity?.name || '');
  }, [activity?.name, numberPrefix]);

  return (
    <Typography component="span" color="inherit" sx={crumbTypographyStyle}>
      {displayText}
    </Typography>
  );
}; 