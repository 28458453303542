import { Chip, Stack } from "@mui/material";
import * as React from "react";
import { RoleChip } from "./RoleChip";

interface RoleSummary {
  id: string;
  name: string;
  count: number;
}

interface RolesCellProps {
  roleSummary: RoleSummary[];
}

export const RolesCell = ({ roleSummary }: RolesCellProps) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [maxVisibleRoles, setMaxVisibleRoles] = React.useState(2);

  React.useEffect(() => {
    const calculateVisibleRoles = () => {
      if (!containerRef.current) return;
      
      const containerWidth = containerRef.current.clientWidth;
      const chipMargin = 4; // gap between chips
      const moreChipWidth = 70; // approximate width of "+X more" chip
      const reservedWidth = moreChipWidth + chipMargin;
      
      // Create temporary chips to measure their widths
      const div = document.createElement('div');
      div.style.visibility = 'hidden';
      div.style.position = 'absolute';
      document.body.appendChild(div);
      
      let totalWidth = 0;
      let visibleCount = 0;
      
      for (let i = 0; i < roleSummary.length; i++) {
        const role = roleSummary[i];
        // Create a temporary chip to measure
        const chip = document.createElement('div');
        chip.style.display = 'inline-block';
        chip.innerHTML = `<span>${role.count}</span>${role.name}`;
        div.appendChild(chip);
        
        const chipWidth = chip.offsetWidth + 32; // Add padding for chip styling
        
        if (totalWidth + chipWidth + (i < roleSummary.length - 1 ? reservedWidth : 0) > containerWidth) {
          break;
        }
        
        totalWidth += chipWidth + chipMargin;
        visibleCount++;
      }
      
      document.body.removeChild(div);
      setMaxVisibleRoles(Math.max(1, visibleCount));
    };

    calculateVisibleRoles();
    
    // Recalculate on window resize
    const resizeObserver = new ResizeObserver(calculateVisibleRoles);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [roleSummary]);

  const hiddenRoles = roleSummary.length > maxVisibleRoles ? roleSummary.length - maxVisibleRoles : 0;
  const visibleRoles = roleSummary.slice(0, maxVisibleRoles);

  return (
    <Stack 
      ref={containerRef}
      direction="row" 
      spacing={0.5} 
      sx={{
        flexWrap: 'nowrap',
        gap: '4px',
        overflow: 'hidden',
        width: '100%'
      }}
    >
      {visibleRoles.map((role) => (
        <RoleChip
          key={role.id}
          roleId={role.id}
          count={role.count}
        />
      ))}
      {hiddenRoles > 0 && (
        <Chip
          size="small"
          label={`+${hiddenRoles} more`}
          sx={{ 
            height: '24px',
            '& .MuiChip-label': { 
              px: 1,
              fontSize: '0.75rem'
            },
            backgroundColor: 'action.hover'
          }}
        />
      )}
    </Stack>
  );
}; 