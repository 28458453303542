import {generateDebug} from '@/utils';
import {devtoolsExchange} from '@urql/devtools';
import {Cache, cacheExchange, DataFields, ResolveInfo, Variables} from '@urql/exchange-graphcache';
import * as React from 'react';
import {Client, createClient, dedupExchange, errorExchange, fetchExchange, Provider} from 'urql';
import {authHeadersExchange} from './auth-headers-exchange';
import {isUnauthorized} from './error-helpers';
import {useCurrentUser} from "@/context/CurrentUserProvider";
import * as Sentry from "@sentry/react";
const debug = generateDebug('GqlClientProvider')

export type UpdateFn<R extends DataFields,V extends Variables> = (result: R, args: V, cache: Cache, _info: ResolveInfo) => void
export type KeyFn = (data: any) => any
export const mutationUpdates: Record<string, UpdateFn<any,any>> = {}
export const keys: Record<string, KeyFn> = {}


export const GqlClientProvider = (props: Omit<React.ProviderProps<Client>, 'value'>) => {
  const {currentUser, signOut} = useCurrentUser()
  const client = React.useMemo(() => {
    debug('Building client')

    return createClient({
      url: '/api/graphql',
      maskTypename: true,
      requestPolicy: 'cache-and-network',
      exchanges: [
        // serializeScalarsExchange,
        devtoolsExchange,
        dedupExchange,
        cacheExchange({
          keys,
          updates: {
            Mutation: mutationUpdates
          },
        }),
        errorExchange({
          onError: error => {
            // debug('onError', error)
            Sentry.captureException(error)
            const isAuthError = error.graphQLErrors.some(isUnauthorized);
            if (isAuthError) {
              debug('UNAUTHENTICATED, signing out');
              signOut();
            }
          },
        }),
        authHeadersExchange(currentUser),
        fetchExchange,
      ],
    });
  }, [currentUser])
  return client ? (<Provider value={client} {...props}/>) : null
}
GqlClientProvider.displayName = 'GqlClientProvider'
