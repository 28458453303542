import { useModified } from '@/context/ModifiedProvider';
import { generateDebug } from '@/utils';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useState } from 'react';
import { useBlocker } from 'react-router-dom';

const debug = generateDebug('NavigationPrompt');

interface NavigationPromptProps {
  message?: string; // Making message optional since we're hardcoding it for now
}

export function NavigationPrompt({ message: _ }: NavigationPromptProps) {
  const modified = useModified();
  const [showDialog, setShowDialog] = useState(false);
  const [pendingBlocker, setPendingBlocker] = useState<{ proceed: () => void; cancel: () => void } | null>(null);

  const blocker = useBlocker(({currentLocation, nextLocation}) => {
    if (modified.isModified && currentLocation.pathname !== nextLocation.pathname) {
      return true;
    }
    return false;
  });

  if (blocker.state === "blocked" && !showDialog) {
    setShowDialog(true);
    setPendingBlocker({
      proceed: () => {
        modified.setModified(false);
        blocker.proceed();
        setShowDialog(false);
      },
      cancel: () => {
        blocker.reset();
        setShowDialog(false);
      }
    });
  }

  return (
    <Dialog
      open={showDialog}
      onClose={() => pendingBlocker?.cancel()}
      aria-labelledby="navigation-dialog-title"
      aria-describedby="navigation-dialog-description"
    >
      <DialogTitle id="navigation-dialog-title">
        Unsaved Changes
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="navigation-dialog-description">
          You have unsaved changes. Are you sure you want to leave this page? Your changes will be lost.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => pendingBlocker?.cancel()} color="primary">
          Stay
        </Button>
        <Button onClick={() => pendingBlocker?.proceed()} color="primary" variant="contained">
          Leave
        </Button>
      </DialogActions>
    </Dialog>
  );
} 