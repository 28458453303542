import { Avatar, Box, Typography, Chip, Stack, Button } from "@mui/material";
import { StatusChip } from "./StatusChip";
import { stringAvatar } from "../utils/avatar-utils";
import { useResendInvitationForUserMutation } from "@/graphql/hooks/users-hook";
import { useRevokeInvitationForUserMutation } from "@/graphql/hooks/users-hook";

interface ProfileDisplayProps {
  avatar?: string;
  name: string;
  email: string;
  status: string;
  userId?: string;
}

export function ProfileDisplay({
  avatar,
  name,
  email,
  status,
  userId,
}: ProfileDisplayProps) {
  const [{ fetching: resendInvitationFetching }, resendInvitation] =
    useResendInvitationForUserMutation();
  const [{ fetching: revokeInvitationFetching }, revokeInvitation] =
    useRevokeInvitationForUserMutation();

  return (
    <Box display="flex" alignItems="center" gap={2}>
      <Stack alignItems="center" gap={1}>
        <Avatar {...stringAvatar(name || email, avatar, 56)} />
        <StatusChip status={status} />
        {(userId && (status === "PENDING" || status === "REVOKED")) && (
          <Button
            variant="contained"
            color="secondary"
            size="small"
            disabled={resendInvitationFetching || revokeInvitationFetching}
            onClick={() => {
              resendInvitation({ userId });
            }}
          >
            Resend invitation
          </Button>
        )}
        {userId && status === "PENDING" && (
          <Button
            variant="contained"
            color="error"
            size="small"
            disabled={resendInvitationFetching || revokeInvitationFetching}
            onClick={() => {
              revokeInvitation({ userId });
            }}
          >
            Revoke invitation
          </Button>
        )}
      </Stack>
      <Box>
        <Typography variant="h6" sx={{ mb: 0.5 }}>
          {name}
        </Typography>
        <Typography variant="body2" sx={{ mb: 1 }}>
          {email}
        </Typography>
      </Box>
    </Box>
  );
}
