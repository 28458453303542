import { Participation } from "@/pages/course-users/types";
import { generateDebug } from "@/utils";
import { IGQLSendWelcomeEmailMutationVariables } from "@graphql/participations-hook";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography
} from "@mui/material";
import { sortBy } from 'lodash';
import { useSnackbar } from "notistack";
import * as React from 'react';
import { useMemo, useState } from 'react';

const debug = generateDebug('SelectUsersToInviteDialog');

interface SelectUsersToInviteDialogProps {
  participations: Participation[];
  courseId: string
  open: boolean
  onClose: () => void
  sendEmails: (variables: IGQLSendWelcomeEmailMutationVariables) => Promise<any>;

}


function SelectUsersToInviteDialog(props: SelectUsersToInviteDialogProps, ref: React.Ref<any>) {
  const { participations, courseId, open, onClose, sendEmails } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [selectedIds, setSelectedIds] = useState<Record<string, boolean>>({});

  const selectedCount = useMemo(() => Object.values(selectedIds).filter(Boolean).length, [selectedIds]);
  const toggleSelection = (id: string, isSelected?: boolean) => {
    setSelectedIds(prevState => ({
      ...prevState,
      [id]: isSelected !== undefined ? isSelected : !prevState[id]
    }));
  };

  const [submitting, setSubmitting] = useState(false);

  const students = useMemo(() => sortBy(
    (participations || [])
      .filter(u => u.roleIds.includes('student')), ['name', 'email']),
    [participations])
  const teachers = useMemo(() => sortBy(
    (participations || [])
      .filter(u => u.roleIds.includes('instructor')), ['name', 'email']),
    [participations])

  React.useEffect(() => {
    setSelectedIds({});
  }, [open]);
  const handleUpload = async () => {
    setSubmitting(true);

    const input: IGQLSendWelcomeEmailMutationVariables['input'] = {
      courseId: courseId,
      userIds: Object.keys(selectedIds).filter(k => selectedIds[k])
    }

    try {
      await sendEmails({ input });
      enqueueSnackbar('Emails sent', { variant: 'success' });
      onClose();
    } catch (e) {
      enqueueSnackbar((e as Error).message, { variant: 'error' });
    } finally {
      setSubmitting(false);

    }
  }
  const renderList = (entries: Participation[], title: string) => (<div>
    <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography variant={'caption'}>{entries.length} {title}</Typography>
      <Button onClick={() => setSelectedIds(prev => ({
        ...prev,
        ...entries.reduce((acc, t) => ({ ...acc, [t.userId]: true }), {})
      }))}>Select All</Button>
    </Stack>
    <List>
      {entries.map((entry, ix) => (<ListItem
        divider={true}
        key={ix}>
        <ListItemButton role={undefined} onClick={() => toggleSelection(entry.userId)} dense>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={!!selectedIds[entry.userId]}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary={entry.user.email} secondary={entry.user.name} />
        </ListItemButton>
      </ListItem>))}
    </List></div>)
  return (<>
    <Dialog open={open}
      fullWidth={true}
      scroll={'paper'}
      onClose={submitting ? () => { } : onClose}
    >
      <DialogTitle>Invite users to course</DialogTitle>
      <DialogContent dividers={true} >
        <DialogContentText>
          {teachers.length>0 && renderList(teachers, "Teachers")}
          {students.length>0 && renderList(students, "Students")}
        </DialogContentText>

      </DialogContent>
      <DialogActions>
        <Button disabled={submitting} onClick={onClose}>Cancel</Button>
        <Button disabled={submitting || selectedCount < 1} onClick={handleUpload}>Send</Button>
      </DialogActions>
    </Dialog>
  </>)

}
export default React.forwardRef(SelectUsersToInviteDialog);
