import * as Types from '../../graphql-types';

import { gql } from 'urql';
import { CurrentUserFieldsFragmentDoc } from './fragment-CurrentUser-hook';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IGQLAcceptSiteInvitationMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyAcceptSiteInvitationInput;
}>;


export type IGQLAcceptSiteInvitationMutation = { __typename: 'Mutation', jrnyAcceptSiteInvitation: { __typename: 'JrnyLoginResult', permissions: Array<string>, organizationIds: Array<string>, abilities: any, user: { __typename: 'JrnyUser', firstName: string, lastName: string, id: string, email: string }, tokenResponse: { __typename: 'TokenResponse', token: string, tokenValidTo: number, refreshToken: string, refreshTokenValidTo: number } } };


export const AcceptSiteInvitationDocument = gql`
    mutation acceptSiteInvitation($input: JrnyAcceptSiteInvitationInput!) {
  jrnyAcceptSiteInvitation(input: $input) {
    ...CurrentUserFields
  }
}
    ${CurrentUserFieldsFragmentDoc}`;

export function useAcceptSiteInvitationMutation() {
  return Urql.useMutation<IGQLAcceptSiteInvitationMutation, IGQLAcceptSiteInvitationMutationVariables>(AcceptSiteInvitationDocument);
};