import { IGQLJrnyLoginResult } from "@/graphql-types";
import { generateDebug } from "@/utils";
import * as React from "react";
import { ReactNode } from "react";
import { AbilityBuilder, AnyAbility, createMongoAbility } from "@casl/ability";
import { unpackRules } from "@casl/ability/extra";
import { newTrie, ShiroTrie } from "shiro-trie";
import { IGQLJrnyLoginMutation } from '@/graphql/hooks/login-hook';

const debug = generateDebug("CurrentUserProvider");

const saveCurrentUser = (val: CurrentUser) => {
  debug("Saving current-user to localStorage");
  window.localStorage.setItem("currentUser", JSON.stringify(val));
};
const saved =
  JSON.parse(window.localStorage.getItem("currentUser") || "null") || null;

export type CurrentUser = IGQLJrnyLoginMutation['jrnyLogin'] & {saved?:boolean} | null

interface CurrentUserContextShape {
  currentUser: CurrentUser | null;
  currentUserId: string | null;
  setCurrentUser: (val: CurrentUser) => void;
  signOut: () => void;
}

export const AbilityContext = React.createContext<AnyAbility>(undefined!);
export const ShiroContext = React.createContext<ShiroTrie>(undefined!);
export const CurrentUserContext = React.createContext<CurrentUserContextShape>({
  currentUser: null,
  currentUserId: null,
  setCurrentUser: () => {},
  signOut: () => {},
});

interface CurrentUserProviderProps {
  children?: ReactNode;
}

export  function CurrentUserProvider(props: CurrentUserProviderProps) {
  const [currentUser, _setCurrentUser] = React.useState<CurrentUser | null>(saved?{...saved, saved:true}:null);
  const [currentUserId, _setCurrentUserId] = React.useState<string | null>(saved?.userId || null);

  const setCurrentUser = React.useCallback((user: CurrentUser) => {
    debug("setting current user", user);
    saveCurrentUser(user);
    _setCurrentUser(user);
    _setCurrentUserId(user?.user.id || null);
  }, []);
  const signOut = React.useCallback(() => setCurrentUser(null), []);
  const [value, shiro, ability] = React.useMemo(() => {
    const builder = new AbilityBuilder(createMongoAbility);
    let ability = builder.build({detectSubjectType: object => object.__typename});
    const shiro = newTrie();
    if (currentUser?.organizationIds) {
      builder.can("list", "JrnyOrganization");
    }
    if (currentUser?.permissions) {
      shiro.add(...currentUser.permissions);
    }
    if (currentUser?.abilities) {
      ability.update(unpackRules(currentUser?.abilities));
    }
    return [
      { currentUser, setCurrentUser, signOut, currentUserId },
      shiro,
      ability,
    ];
  }, [currentUser, currentUserId]);
  return (
    <CurrentUserContext.Provider value={value} {...props}>
      <AbilityContext.Provider value={ability}>
        <ShiroContext.Provider value={shiro}>
          {props.children}
        </ShiroContext.Provider>
      </AbilityContext.Provider>
    </CurrentUserContext.Provider>
  );
}

export const useCurrentUser = (): CurrentUserContextShape => {
  return React.useContext(CurrentUserContext);
};
