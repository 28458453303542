import { AssetsProvider } from "@/context/AssetsProvider";
import { CourseProvider } from "@/context/CourseProvider";
import ModifiedProvider from "@/context/ModifiedProvider";
import { OrganizationProvider } from "@/context/OrganizationProvider";
import { ProgressContext, ProgressProvider } from "@/context/ProgressProvider";
import { ProsemirrorDevToolsProvider } from "@/context/ProsemirrorDevToolsProvider";
import { generateDebug } from "@/utils";
import Link from "@components/Link";
import { LinearProgress, linearProgressClasses, styled } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import { journeyTheme } from "./jouney-theme";
import Navigator from "./Navigator";
import GlobalModificationBar from "@/components/GlobalModificationBar";
import { NavigationPrompt } from "./components/NavigationPrompt";
const debug = generateDebug("Main");

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" to="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}.
    </Typography>
  );
}

const drawerWidth = 256;

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 1,
  position: "absolute",
  zIndex: 1101,
  top: 64,
  right: 0,
  left: 0,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'white',
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: 'var(--mui-palette-primary-main)',
  },
}));
export default function Main() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isSmUp = useMediaQuery(journeyTheme.breakpoints.up("sm"));
  const contentRoot = React.useRef<HTMLElement>();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <OrganizationProvider>
      <CourseProvider>
        <ThemeProvider theme={journeyTheme}>
          <ProgressProvider>
            <Box sx={{ display: "flex", minHeight: "100vh" }}>
              <CssBaseline />
              <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
              >
                {isSmUp ? null : (
                  <Navigator
                    PaperProps={{ style: { width: drawerWidth } }}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                  />
                )}
                <Navigator
                  PaperProps={{ style: { width: drawerWidth } }}
                  sx={{ display: { sm: "block", xs: "none" } }}
                />
              </Box>
              <Box sx={{ flex: 1, display: "flex", flexDirection: "column", minWidth:  journeyTheme.breakpoints.values.sm}}>
                <AssetsProvider>
                  <Header onDrawerToggle={handleDrawerToggle}>
                    <ProgressContext.Consumer>
                      {({ progress, show }) => {
                        return (
                          <BorderLinearProgress
                            sx={{ zIndex: 1101, opacity: show ? 1 : 0 }}
                            variant={
                              progress != "unknown"
                                ? "determinate"
                                : "indeterminate"
                            }
                            value={progress != "unknown" ? progress : undefined}
                          />
                        );
                      }}
                    </ProgressContext.Consumer>
                  </Header>
                  <Box
                    ref={contentRoot}
                    component="main"
                    sx={{ flex: 1, py: 3, px: 2}}
                  >
                    <ModifiedProvider>
                      <GlobalModificationBar>
                        <NavigationPrompt/>
                          <ProsemirrorDevToolsProvider>
                            <Outlet />
                          </ProsemirrorDevToolsProvider>
                      </GlobalModificationBar>
                    </ModifiedProvider>
                  </Box>
                  <Box component="footer" sx={{ p: 2 }}>
                    {/* <Copyright/> */}
                  </Box>
                </AssetsProvider>
              </Box>
            </Box>
          </ProgressProvider>
        </ThemeProvider>
      </CourseProvider>
    </OrganizationProvider>
  );
}
