import { Typography } from '@mui/material';
import React from 'react';
import { useCurrentOrganization } from '@/context/OrganizationProvider';
import { CrumbProps, crumbTypographyStyle } from './types';

export const OrganizationCrumb = ({ onUpdate }: CrumbProps) => {
  const { currentOrganization, fetching } = useCurrentOrganization();
  const text = fetching ? 'Loading...' : (currentOrganization?.name || 'Organization');
  
  React.useEffect(() => {
    onUpdate?.(currentOrganization?.name || '');
  }, [currentOrganization?.name, fetching]);

  return (
    <Typography component="span" color="inherit" sx={crumbTypographyStyle}>
      {text}
    </Typography>
  );
}; 