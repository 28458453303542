import Typography from '@/components/Typography';
import { useLocalChange } from "@/hooks/use-local-change";
import { Paper, TextField } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Stack } from '@mui/system';
import { useSaveSettingsMutation, useSettingsQuery } from '../../graphql/hooks/settings-hook';
interface SettingsViewProps {

}

export function SettingsView() {
  const [perkQuery] = useSettingsQuery({ variables: { id: { key: 'default-prompt', domain: 'ai-assessment' } }, requestPolicy: "cache-and-network" })
  const [_, updatePerkMutation] = useSaveSettingsMutation()
  const [localSettings, setLocalSettings] = useLocalChange(perkQuery.data?.jrnySettingsById,
    (original, local) => {
      return updatePerkMutation({
        input: {
          key: local.key,
          domain: local.domain,
          value: local.value,
        }
      })
    })

  return localSettings ? (<>
    <Paper sx={{ p: 2 }}>

      <Grid container spacing={1}>
        <Grid
          size={{
            sm: 12
          }}>
          <Stack spacing={1}>
            <Typography variant='caption' sx={{ pb: 1 }}>The prompt used to shape how to assess user submissions. 
              Feel free to adjust the personality of the teacher as well as quality of the feedback. <br/><strong>NOTE: 
              <code> {'{{lessonSummary}}'}</code> needs to be there as well as instructions how to grade the individual inputs (from 0 to 10). 
              </strong></Typography>
            <TextField
              label='Prompt'
              fullWidth
              multiline
              size='small'
              value={localSettings.value.system}
              onChange={(evt) => setLocalSettings(draft => {
                draft!.value.system = evt.target.value
              })}
              slotProps={{
                inputLabel: { shrink: true }
              }}
            />
          </Stack>
        </Grid>
      </Grid>
    </Paper>
    {/* <DebugView>{JSON.stringify(localSettings, null, 2)}</DebugView> */}
  </>)
    : null;
}
