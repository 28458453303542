import {OptionsEditor} from '@/pages/prompt/components/OptionsEditor';
import {PromptInputCardProps} from '@/pages/prompt/components/types';
import {generateDebug} from '@/utils';
import {OptionPromptInputConfig, OptionPromptInputSubmission} from '@journey-lti-tool/common';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {produce} from 'immer'
import * as React from 'react';

const debug = generateDebug('OptionInputCard')

export function OptionInputCard({
                                  promptInput,
                                  onChange,
                                  onRemove
                                }: PromptInputCardProps<OptionPromptInputConfig, OptionPromptInputSubmission>) {
  debug('promptInput', promptInput)

  const onModeChange = (evt: SelectChangeEvent<'radio' | 'dropdown' | 'checkbox'>) => {
    onChange(produce(promptInput, draft => {
      draft.config.mode = evt.target.value as 'radio' | 'dropdown' | 'checkbox'
    },))
  }
  const onMultiChange = (evt: SelectChangeEvent<'_' | '1'>) => {
    onChange(produce(promptInput, draft => {
      draft.config.multi = evt.target.value=='1'
      if (evt.target.value=='1') {
        draft.config.mode = 'checkbox'
      }
    }))
  }
  const onAddOption = (ix: number) => {
    const value = produce(promptInput, draft => {
      debug(promptInput, draft)
      draft.config.options.splice(ix + 1, 0, 'New option')
      draft.config.optionWeights.splice(ix + 1, 0, 1)
    });
    debug('new Value', value)
    onChange(value)

  }
  const onRemoveOption = (ix: number) => {
    onChange(produce(promptInput, draft => {
      draft.config.options.splice(ix, 1)
      draft.config.optionWeights.splice(ix, 1,)
    }))

  }
  const onUpdateOption = (ix: number, value: string, weight: number) => {
    onChange(produce(promptInput, draft => {
      draft.config.options[ix] = value
      draft.config.optionWeights[ix] = weight
    }))

  }
  return (
    (<Grid container spacing={1}>
      <Grid
        size={{
          sm: 6
        }}>

        <FormControl fullWidth>
          <InputLabel id={"display-as-label" + promptInput.id}>Display as</InputLabel>
          <Select
            labelId={"display-as-label" + promptInput.id}
            label='Display as'
            size='small'
            value={promptInput.config.mode}
            onChange={onModeChange}>
            <MenuItem disabled={promptInput.config.multi} value='radio'>Radio Buttons</MenuItem>
            <MenuItem disabled={promptInput.config.multi} value='dropdown'>Dropdown</MenuItem>
            <MenuItem value='checkbox'>Checkboxes</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid
        size={{
          sm: 6
        }}>
        <FormControl fullWidth>
          <InputLabel id={"multi-label" + promptInput.id}>Multi/Single</InputLabel>
          <Select
            labelId={"multi-label" + promptInput.id}
            label='Multi/Single'
            size='small'
            displayEmpty
            value={promptInput.config.multi ? '1' : '_'}
            onChange={onMultiChange}>
            <MenuItem value='_'>Single option</MenuItem>
            <MenuItem value='1'>Multi option</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {/* <Grid
        size={{
          sm: 3
        }}>
        <TextField
          fullWidth
          size='small'
          type={'number'}
          label='Weight'
          value={promptInput.weight}
          onChange={(evt) => onChange(produce(promptInput, draft => {
            draft.weight = Math.max(0, Number.parseFloat(evt.target.value))
          }))}/>
      </Grid>
      <Grid
        size={{
          sm: 3
        }}>
        <TextField
          fullWidth
          size='small'
          type={'number'}
          label='Min weight'
          value={promptInput.minContribution}
          onChange={(evt) => onChange(produce(promptInput, draft => {
            draft.minContribution = Math.min(0, Number.parseFloat(evt.target.value))
          }))}/>
      </Grid> */}
      <Grid
        size={{
          sm: 12
        }}>

        <OptionsEditor values={promptInput.config.options}
                       weights={promptInput.config.optionWeights}
                       onRemove={onRemoveOption}
                       onChange={onUpdateOption}
                       onAdd={onAddOption}
        />
      </Grid>
    </Grid>)
  );
}
