import { createBrowserRouter, Outlet } from "react-router-dom";
import AcceptSiteInvitation from "@/pages/accept-invitation/AcceptSiteInvitation";
import AdminMain from "@/pages/admin/AdminMain";
import CourseDetailsView from "@/pages/course/CourseDetailsView";
import LessonDetailsView from "@/pages/lesson/LessonDetailsView";
import OrganizationListView from "@/pages/organization/OrganizationListView";
import { ResourceDetailsView } from "@/pages/resource/ResourceDetailsView";
import MediaDetailsView from "@/pages/video/MediaDetailsView";
import RequireAuth from "@auth/components/RequireAuth";
import RequireNotAuth from "@/auth/components/RequireNotAuth";
import CheckpointDetailsView from "@/pages/checkpoint/CheckpointDetailsView";
import { ForgotPassword, ResetPassword } from "@/pages/forgot-password";
import Main from "@/pages/main/Main";
import OrganizationUsersView from "@/pages/organization/OrganizationUsersView";
import PromptDetailsView from "@/pages/prompt/PromptDetailsView";
import { SignIn } from "@/pages/sign-in";
import { TopNavigation } from "@/top-navigation";
import { PerkListView } from "@/pages/perks/PerkListView";
import { PerkDetailsView } from "@/pages/perks/PerkDetailsView";
import { StructureView } from "@/pages/structure/StructureView";
import { FilesViewPage } from "@/pages/files/FilesPageView";
import CourseUsersView from "@/pages/course-users/CourseUsersView";
import CourseOccasionsView from "@/pages/course-occasions/CourseOccasionsView";
import { SettingsView } from "@/pages/settings/SettingsView";
import { OrganizationMain } from "@/pages/organization/OrganizationMain";
import { CourseMain } from "@/pages/course/CourseMain";
import CourseSettingsView from "@/pages/course-settings/CourseSettingsView";
import * as Sentry from "@sentry/react";
import CourseListView from "./pages/course/CourseListView";
import {
  StandardCrumb,
  OrganizationCrumb,
  CourseCrumb,
  CheckpointCrumb,
  LessonCrumb,
  ActivityCrumb,
} from "@/components/breadcrumbs";
import { UsersWithDetailView } from "./pages/user/UsersWithDetailView";
import { UserDetailView } from "./pages/user/UserDetailView";
import { UserCreateView } from "./pages/user/UserCreateView";
import { MaintenanceView } from "./pages/maintenance/MaintenanceView";
const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: (
      <RequireAuth>
        <Main />
      </RequireAuth>
    ),
    handle: {
      crumb: () => <StandardCrumb name="Home" />,
    },
    children: [
      { index: true, element: <OrganizationListView /> },
      {
        path: "admin",
        // handle: { crumb: () => <StandardCrumb name="Admin" /> },
        children: [
          {
            path: "maintain",
            element: <AdminMain />,
            handle: { crumb: () => <StandardCrumb name="Maintenance" /> },
          },
          {
            path: "settings",
            element: <SettingsView />,
            handle: { crumb: () => <StandardCrumb name="Settings" /> },
          },
          {
            path: "maintenance",
            element: <MaintenanceView />,
            handle: { crumb: () => <StandardCrumb name="Maintenance" /> },
          },
          {
            path: "admins",
            id: "admin-list",
            element: <UsersWithDetailView routeId="admin-list" />,
            handle: { crumb: () => <StandardCrumb name="Admins" /> },
            children: [
              {
                path: "create",
                element: <UserCreateView />,
                handle: { crumb: () => <StandardCrumb name="Create User" /> },
              },
              {
                path: ":userId",
                element: <UserDetailView />,
                // handle: { crumb: () => <StandardCrumb name="User Details" /> },
              },
            ],
          },
        ],
      },
      {
        path: "organization/:organizationId",
        handle: { crumb: () => <OrganizationCrumb /> },
        element: <OrganizationMain />,
        children: [
          { index: true, element: <CourseListView /> },
          {
            path: "courses",
            element: <StructureView />,
            handle: { crumb: () => <StandardCrumb name="Courses" /> },
          },
          {
            path: "files",
            element: <FilesViewPage />,
            handle: { crumb: () => <StandardCrumb name="Files" /> },
          },
          {
            path: "admins",
            id: "organization-admin-list",
            element: <UsersWithDetailView routeId="organization-admin-list" />,
            handle: { crumb: () => <StandardCrumb name="Admins" /> },
            children: [
              {
                path: "create",
                element: <UserCreateView />,
                handle: { crumb: () => <StandardCrumb name="Create User" /> },
              },
              {
                path: ":userId",
                element: <UserDetailView />,
                // handle: { crumb: () => <StandardCrumb name="User Details" /> },
              },
            ],
          },
          {
            path: "course/:courseId",
            element: <CourseMain />,
            handle: { crumb: () => <CourseCrumb /> },
            children: [
              { index: true, element: <CourseDetailsView /> },
              {
                path: "perks",
                element: <Outlet />,
                handle: { crumb: () => <StandardCrumb name="Perks" /> },
                children: [
                  {
                    index: true,
                    element: <PerkListView />,
                  },
                  {
                    path: ":perkId",
                    element: <PerkDetailsView />,
                    handle: { crumb: () => <StandardCrumb name="Perk Details" /> },
                  },
                    ]
              },
              {
                path: "admins",
                id: "course-admin-list",
                element: <UsersWithDetailView routeId="course-admin-list" />,
                handle: { crumb: () => <StandardCrumb name="Admins" /> },
                children: [
                  {
                    path: "create",
                    element: <UserCreateView />,
                    handle: { crumb: () => <StandardCrumb name="Create User" /> },
                  },
                  {
                    path: ":userId",
                    element: <UserDetailView />,
                    // handle: { crumb: () => <StandardCrumb name="User Details" /> },
                  },
                ],
              },
        
              {
                path: "checkpoint/:checkpointId",
                handle: { crumb: () => <CheckpointCrumb /> },
                children: [
                  { index: true, element: <CheckpointDetailsView /> },
                  {
                    path: ":lessonId",
                    handle: { crumb: () => <LessonCrumb /> },
                    children: [
                      { index: true, element: <LessonDetailsView /> },
                      {
                        path: "prompt/:promptId",
                        element: <PromptDetailsView />,
                        handle: { crumb: () => <ActivityCrumb /> },
                      },
                      {
                        path: "challenge/:promptId",
                        element: <PromptDetailsView />,
                        handle: { crumb: () => <ActivityCrumb /> },
                      },
                      {
                        path: "media/:mediaId",
                        element: <MediaDetailsView />,
                        handle: { crumb: () => <ActivityCrumb /> },
                      },
                      {
                        path: "resource/:resourceId",
                        element: <ResourceDetailsView />,
                        handle: { crumb: () => <ActivityCrumb /> },
                      },
                    ],
                  },
                ],
              },
              {
                path: "users",
                element: <CourseUsersView />,
                handle: { crumb: () => <StandardCrumb name="Users" /> },
              },
              {
                path: "occasions",
                element: <CourseOccasionsView />,
                handle: { crumb: () => <StandardCrumb name="Occasions" /> },
              },
              {
                path: "settings",
                element: <CourseSettingsView />,
                handle: { crumb: () => <StandardCrumb name="Settings" /> },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: TopNavigation.SignIn,
    element: (
      <RequireNotAuth>
        <SignIn />
      </RequireNotAuth>
    ),
  },
  {
    path: `${TopNavigation.AcceptInvitation}/:tokenId`,
    element: <AcceptSiteInvitation />,
  },
  {
    path: TopNavigation.ForgotPassword,
    element: (
      <RequireNotAuth>
        <ForgotPassword />
      </RequireNotAuth>
    ),
  },
  {
    path: `${TopNavigation.ResetPassword}/:code`,
    element: <ResetPassword />,
  },
]);

export default router;
