import { Participation } from "@/pages/course-users/types";
import { generateDebug } from '@/utils';
import {
  IGQLRemoveCourseRoleFromUserMutationVariables
} from "@graphql/participations-hook";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { FORM_ERROR } from 'final-form';
import * as React from 'react';

const debug = generateDebug('RemoveCourseUserDialog');

interface RemoveCourseUserDialogProps {
  removeUser: (variables: IGQLRemoveCourseRoleFromUserMutationVariables) => Promise<any>;
  participation?: Participation
  role?: string
  onClose: () => void
}

export default function RemoveCourseUserDialog(props: RemoveCourseUserDialogProps) {
  const [submitting, setSubmitting] = React.useState(false);


  const handleClose = () => {
    props.onClose();
  };
  const doSubmit = async () => {
    setSubmitting(true);
    const result = await props.removeUser({
      input: {
        courseId: props.participation!.courseId,
        userId: props.participation!.userId,
        roleId: props.role!
      }
    });
    debug('Submit result', result);
    setSubmitting(false);
    if (result.error) {
      return {[FORM_ERROR]: result.error.graphQLErrors[0].message};
    }
    props.onClose();
  };

  return (
    <div>
      <Dialog open={!!props.participation} onClose={submitting ? () => {
      } : handleClose}>
        <DialogTitle>Remove {props.participation?.user.name|| props.participation?.user.email} as {props.role} from course?</DialogTitle>
        <DialogActions>
          <Button disabled={submitting} onClick={handleClose}>Cancel</Button>
          <Button disabled={submitting} onClick={() => doSubmit()}
                  variant={'contained'}
                  color={'error'}>Remove</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
