import { IconChallenge, IconCheckpoint, IconCourse, IconDelete, IconDuplicate, IconLesson, IconPrompt, IconResource, IconVideo } from "@/icons";
import { actionsByTypeName } from "@/pages/lesson/LessonDetailsView";
import { ContextMenuActionTuple, ItemContextMenu } from "@/pages/structure/components/ItemContextMenu";
import {
  AnyContentItem,
  AnyItem,
  Checkpoint,
  Course,
  CreateHandler,
  DeleteHandler, DuplicateHandler,
  Lesson
} from "@/pages/structure/types";
import { typenameToCreateItemType } from "@/pages/structure/utils";
import { alpha, Chip } from "@mui/material";
import Box from "@mui/material/Box";
import { SvgIconProps } from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { TreeItem, treeItemClasses, TreeItemProps } from '@mui/x-tree-view';
import * as React from "react";
import { Draggable, DraggableStateSnapshot, Droppable, DroppableStateSnapshot } from "react-beautiful-dnd";

declare module 'react' {
  interface CSSProperties {
    '--tree-view-color'?: string;
    '--tree-view-bg-color'?: string;
  }
}

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  iconColor?: string
  labelIcon: React.ElementType<SvgIconProps>;
  labelInfo?: React.ReactNode;
  labelText: string;
  item: AnyItem,
  menuItems?: ContextMenuActionTuple[],
};

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightRegular,
    },
    // '&:hover': {
    //   backgroundColor: theme.palette.action.hover,
    // },
    'div.my-buttons': {
      opacity: 0,
      transition: 'opacity 0.2s ease-in-out',
    },
    '&:hover div.my-buttons': {
      opacity: 1,
    },
  }
  //   '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
  //     backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
  //     color: 'var(--tree-view-color)',
  //   },
  //   [`& .${treeItemClasses.label}`]: {
  //     fontWeight: 'inherit',
  //     color: 'inherit',
  //   },
  // },
  // [`& .${treeItemClasses.group}`]: {
  //   marginLeft: 0,
  //   [`& .${treeItemClasses.content}`]: {
  //     paddingLeft: theme.spacing(2),
  //   },
  // },
}));

const StyledTreeItem = React.forwardRef<HTMLLIElement, StyledTreeItemProps>((props: StyledTreeItemProps, ref) => {
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    iconColor,
    labelInfo,
    labelText,
    item,
    menuItems,
    ...other
  } = props;

  return (
    <StyledTreeItemRoot
      ref={ref}
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }} >
          <Box component={LabelIcon} sx={{ mr: 1, fill: iconColor || "currentColor" }} />
          <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
          {menuItems && <Box className={'my-buttons'} sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
            <ItemContextMenu items={menuItems} />
          </Box>}
        </Box>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      {...other}
    />
  );
});

const renderItems = (grandParent: Checkpoint, parent: Lesson, items: AnyContentItem[], disabled: boolean, createHandler: CreateHandler, deleteHandler: DeleteHandler, duplicateHandler: DuplicateHandler) => {

  const result: React.ReactNode[] = []
  let nonCountable = 0
  items.forEach((c: AnyContentItem, ix: number) => {
    let orderString = ''
    if (c.__typename === 'JrnyResource') {
      nonCountable++
    } else {
      orderString = `${grandParent.order + 1}.${parent.order + 1}.${ix + 1 - nonCountable} `
    }
    result.push(<Draggable
      isDragDisabled={disabled}
      draggableId={c.id} index={c.order}
      key={c.id}>{(providedDrag, snapshot) => {
        const key = c.__typename + ('category' in c ? c.category || '' : '');
        if (!actionsByTypeName[key]) {
          debugger
        }
        const type = typenameToCreateItemType(c.__typename, c.category as 'challenge' | 'prompt')
        const color = actionsByTypeName[key].color
        const icon = actionsByTypeName[key].iconElement
        // switch (c.__typename) {
        //   case 'JrnyPrompt':
        //     actionsByTypeName[key].icon
        //     icon = c.category === 'challenge' ? IconChallenge : IconPrompt
        //     break;
        //   case 'JrnyResource':
        //     icon = IconResource
        //     break;
        //   case 'JrnyMedia':
        //     icon = IconVideo
        //     break;
        // }
        const menuItems: ContextMenuActionTuple[] = ([
          ['Remove', () => deleteHandler(c, type), <IconDelete />],
          ['Duplicate', () => duplicateHandler(c, c.order + 1,), <IconDuplicate />],

          ['Insert Prompt', () => createHandler(parent, c.order, 'prompt'), <IconPrompt />],
          ['Insert Challenge', () => createHandler(parent, c.order, 'challenge'), <IconChallenge />],
          ['Insert Media', () => createHandler(parent, c.order, 'media'), <IconVideo />],
          ['Insert Resource', () => createHandler(parent, c.order, 'resource'), <IconResource />],
        ])
        return (
          <StyledTreeItem itemId={c.id}
            ref={providedDrag.innerRef}
            {...providedDrag.draggableProps}
            {...providedDrag.dragHandleProps}
            item={c}
            iconColor={color}
            menuItems={menuItems}
            labelText={`${orderString}${c.name}`}
            labelIcon={icon} />);
      }}
    </Draggable>);
  });
  return result;
}

const renderLessons = (parent: Checkpoint, lessons: Lesson[], disabled: boolean, createHandler: CreateHandler, deleteHandler: DeleteHandler, duplicateHandler: DuplicateHandler) => {
  return lessons.map((c: Lesson, ix) => {
    const orderString = `${parent.order + 1}.${ix + 1} `

    const menuItems: ContextMenuActionTuple[] = ([
      ['Remove', () => deleteHandler(c), <IconDelete />],
      ['Duplicate', () => duplicateHandler(c, c.order + 1,), <IconDuplicate />],
      ['Insert Lesson', () => createHandler(parent, c.order,), <IconLesson />],
      ['Add Prompt', () => createHandler(c, c.contentItems.length, 'prompt'), <IconPrompt />],
      ['Add Challenge', () => createHandler(c, c.contentItems.length, 'challenge'), <IconChallenge />],
      ['Add Media', () => createHandler(c, c.contentItems.length, 'media'), <IconVideo />],
      ['Add Resource', () => createHandler(c, c.contentItems.length, 'resource'), <IconResource />],
    ])

    return <Draggable isDragDisabled={disabled} draggableId={c.id} index={c.order}
      key={c.id}>{(providedDrag, snapshot) => (
        <Droppable
          isDropDisabled={disabled}
          key={c.id} droppableId={c.id}
          type={'lesson'}

        >{providedDrop =>
          <div ref={providedDrop.innerRef} {...providedDrop.droppableProps}>
            <StyledTreeItem
              ref={providedDrag.innerRef}
              {...providedDrag.draggableProps}
              {...providedDrag.dragHandleProps}
              item={c}
              menuItems={menuItems}
              itemId={c.id} labelText={`${orderString}${c.name}`} labelIcon={IconLesson}>
              {renderItems(parent, c, c.contentItems, disabled, createHandler, deleteHandler, duplicateHandler)}
              {providedDrop.placeholder}
            </StyledTreeItem></div>
          }
        </Droppable>)}
    </Draggable>;
  })

}
const renderCheckpoints = (parent: Course, checkpoints: Checkpoint[], disabled: boolean, createHandler: CreateHandler, deleteHandler: DeleteHandler, duplicateHandler: DuplicateHandler) => {
  return checkpoints.map((c: Checkpoint) => {
    const menuItems: ContextMenuActionTuple[] = ([
      ['Remove', () => deleteHandler(c), <IconDelete />],
      ['Duplicate', () => duplicateHandler(c, c.order + 1), <IconDuplicate />],
      ['Insert Checkpoint', () => createHandler(parent, c.order,), <IconCheckpoint />],
      ['Add Lesson', () => createHandler(c, c.lessons.length,), <IconLesson />],

    ])
    return <Draggable draggableId={c.id} index={c.order}
      key={c.id}>{(providedDrag, snapshot: DraggableStateSnapshot) => (
        <Droppable
          key={c.id} droppableId={c.id}
          type={'checkpoint'}
        >{providedDrop =>
          <div ref={providedDrop.innerRef} {...providedDrop.droppableProps}>

            <StyledTreeItem
              ref={providedDrag.innerRef}
              {...providedDrag.draggableProps}
              {...providedDrag.dragHandleProps}
              itemId={c.id}
              item={c}
              menuItems={menuItems}
              labelText={`${c.order + 1} ${c.name}`}
              iconColor={'#854c96'}
              labelIcon={IconCheckpoint}>
              {renderLessons(c, c.lessons, disabled, createHandler, deleteHandler, duplicateHandler)}
              {providedDrop.placeholder}
            </StyledTreeItem>
          </div>
          }
        </Droppable>
      )}
    </Draggable>;
  })

}
export const renderCourses = (courses: Course[], disabled: boolean, createHandler: CreateHandler, deleteHandler: DeleteHandler, duplicateHandler: DuplicateHandler) => {
  return courses.map((c: Course) => {
    const menuItems: ContextMenuActionTuple[] = ([
      ['Remove', () => deleteHandler(c), <IconDelete />],
      ['Duplicate', () => duplicateHandler(c, 0), <IconCourse />],
      ['Add Checkpoint', () => createHandler(c, c.checkpoints.length,), <IconCheckpoint />],

    ])

    return <Droppable isDropDisabled={disabled} key={c.id} droppableId={c.id}
      type={'course'}>{(providedDrop, snapshot: DroppableStateSnapshot) => (
        <StyledTreeItem ref={providedDrop.innerRef}
          {...providedDrop.droppableProps}
          itemId={c.id}
          item={c}
          menuItems={menuItems}
          labelText={c.name}
          labelInfo={<>
            {c.available && <Chip sx={{ mr: 1 }} size='small' label='AVAILABLE' color='primary' />}
          </>}
          labelIcon={IconCourse}>
          {renderCheckpoints(c, c.checkpoints, disabled, createHandler, deleteHandler, duplicateHandler)}
          {providedDrop.placeholder}
        </StyledTreeItem>
      )}
    </Droppable>;
  })

}
