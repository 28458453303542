import { Role } from "../types";
import { roleDefinitions } from "../roleDefinitions";

export const sortRoleIds = (roleIds: string[]): string[] => {
  const roleOrder = Object.keys(roleDefinitions);
  return [...roleIds].sort((a, b) => roleOrder.indexOf(a) - roleOrder.indexOf(b));
};

export const sortRoles = (roles: Role[]): Role[] => {
  const scopeOrder = ['global', 'organization', 'course'];
  
  return [...roles].sort((a, b) => {
    // First sort by scope
    const scopeDiff = scopeOrder.indexOf(a.scope) - scopeOrder.indexOf(b.scope);
    if (scopeDiff !== 0) return scopeDiff;

    // For organization and course roles, sort by organizationId
    if (a.organizationId && b.organizationId) {
      const orgDiff = a.organizationId.localeCompare(b.organizationId);
      if (orgDiff !== 0) return orgDiff;
    }

    // For course roles, sort by courseId
    if (a.scope === 'course' && b.scope === 'course' && a.courseId && b.courseId) {
      return a.courseId.localeCompare(b.courseId);
    }

    return 0;
  });
}; 