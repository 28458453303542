import { useAssets } from "@/context/AssetsProvider";
import {
  IGQLJrnyResourceType,
  IGQLJrnyUpdateResourceInput,
} from "@/graphql-types";
import { useLocalChange } from "@/hooks/use-local-change";
import { MinimalFileObject } from "@components/file-object/types";
import {
  useResourceQuery,
  useUpdateResourceMutation,
} from "@graphql/resources-hook";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField
} from "@mui/material";
import Grid from '@mui/material/Grid2';

import { FileListItem } from "@components/file-object/FileListItem";
import * as React from "react";
import { useParams } from "react-router-dom";

interface ResourceDetailsViewProps {}

export function ResourceDetailsView(props: ResourceDetailsViewProps) {
  const { resourceId } = useParams();
  const { showFileChooser } = useAssets();
  const [resource] = useResourceQuery({
    variables: { id: resourceId || "" },
    pause: !resourceId,
  });
  const [_, updateResource] = useUpdateResourceMutation();
  const [localResource, setLocalResource, resetCount] = useLocalChange(
    resource.data?.jrnyResource,
    (original, local) => {
      const input: IGQLJrnyUpdateResourceInput = {
        id: original.id,
        fileId: local.file?.id,
        url: local.url,
        name: local.name,
        category: local.category,
        type: local.type,
        description: local.description,
      };
      return updateResource({ input });
    }
  );
  const setDescription = React.useCallback(
    (description: string) =>
      setLocalResource((draft) => {
        draft!.description = description;
      }),
    [resource, resetCount]
  );
  const onSetFile = (fileObject: MinimalFileObject | null) => {
    setLocalResource((draft) => {
      if (draft) {
        if (fileObject) {
          // @ts-ignore
          draft.file = fileObject;
        } else {
          draft.file = undefined;
        }
      }
    });
  };

  return localResource ? (
    <>
      <Paper sx={{ p: 2 }}>
        <Grid container columnSpacing={1} rowSpacing={4}>
          <Grid size={10}>
            <TextField
              label="Name/Title"
              fullWidth
              size="small"
              value={localResource.name}
              onChange={(evt) =>
                setLocalResource((draft) => {
                  draft!.name = evt.target.value;
                })
              }
            />
          </Grid>
          <Grid
            size={{
              sm: 2
            }}>
            <FormControl fullWidth>
              <InputLabel id="type-label">Type</InputLabel>
              <Select
                labelId="type-label"
                label="Type"
                size="small"
                displayEmpty
                value={localResource.type}
                onChange={(evt) =>
                  setLocalResource((draft) => {
                    draft!.type = evt.target.value as IGQLJrnyResourceType;
                  })
                }
              >
                <MenuItem value={IGQLJrnyResourceType.File}>File</MenuItem>
                <MenuItem value={IGQLJrnyResourceType.Url}>URL</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {localResource.type === IGQLJrnyResourceType.File && (
            <Grid size={12}>
              <Box
                sx={{
                  p: 2,
                  m: 1,
                  textAlign: "center",
                  border: "1px solid rgba(0,0,0,.1)",
                  bgcolor: "#eee",
                }}
              >
                {localResource.file ? (
                  <FileListItem item={localResource.file} />
                ) : (
                  "No file selected"
                )}
              </Box>
              <Button
                onClick={() =>
                  showFileChooser({ type: "document", onSelect: onSetFile })
                }
              >
                Choose file
              </Button>
            </Grid>
          )}
          {localResource.type === IGQLJrnyResourceType.Url && (
            <Grid size={12}>
              <TextField
                label="URL"
                fullWidth
                size="small"
                value={localResource.url || ""}
                onChange={(evt) =>
                  setLocalResource((draft) => {
                    draft!.url = evt.target.value;
                  })
                }
              />
            </Grid>
          )}
        </Grid>
      </Paper>
      {/*<DebugView>{JSON.stringify(localResource, null, 2)}</DebugView>*/}
    </>
  ) : null;
}
