import { Box, Typography, Paper } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';

interface RoleDescriptorProps {
  icon: SvgIconComponent;
  title: string;
  description: string;
}

export function RoleDescriptor({ icon: Icon, title, description }: RoleDescriptorProps) {
  return (
    <Paper variant="outlined">
      <Box display="flex" gap={2} p={2}>
        <Box display="flex" alignItems="center">
          <Icon sx={{ fontSize: 24 }} />
        </Box>
        <Box>
          <Typography variant="subtitle1" sx={{ mb: 0.5 }}>{title}</Typography>
          <Typography variant="body2">{description}</Typography>
        </Box>
      </Box>
    </Paper>
  );
} 