import { CurrentUserProvider } from '@/context/CurrentUserProvider';
import { NetworkProvider } from '@/context/NetworkProvider';
import { GqlClientProvider } from '@/graphql-client/GqlClientProvider';
import { ModificationGuardProvider } from '@/context/ModificationGuardProvider';
import '@/graphql/register-update-hooks';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter
} from 'react-router-dom';
import App from './App';
import './index.css';
import { addTabTracking, disableEmotionWarnings } from './utils';
dayjs.extend(utc);

import type { } from '@mui/material/themeCssVarsAugmentation';
import { OrganizationsProvider } from './context/OrganizationsProvider';

// Sentry.init({
//   dsn: "https://0c3565cdac1cf8106d4c0f7b03710392@o4506260373897216.ingest.sentry.io/4506272611827712",
//   tunnel: "/api/sentry",
//   environment: import.meta.env.MODE,
//   // debug: import.meta.env.MODE == 'development',
//   release: __APP_VERSION__,
//   integrations: [
//     new Sentry.BrowserTracing({

//       routingInstrumentation: Sentry.reactRouterV6Instrumentation(
//         React.useEffect,
//         useLocation,
//         useNavigationType,
//         createRoutesFromChildren,
//         matchRoutes
//       ),
//     }),
//     new Sentry.Replay(),
//   ],
//   tracesSampleRate: 1.0,
//   replaysOnErrorSampleRate: 1.0,
//   tracePropagationTargets: ["localhost", "/api/"],
// });

window.localStorage.setItem('debug', '*, -y-ws:*, -y-idb:*, , -y-prosemirror:*');

addTabTracking();
disableEmotionWarnings();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  // <React.StrictMode>
  <>
    <CurrentUserProvider>
      <NetworkProvider>
        <GqlClientProvider>
          <ModificationGuardProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <OrganizationsProvider>
                <App />
              </OrganizationsProvider>
            </LocalizationProvider>
          </ModificationGuardProvider>
        </GqlClientProvider>
      </NetworkProvider>
    </CurrentUserProvider>
  </>
  // </React.StrictMode>,
);
