import * as Types from '../../graphql-types';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IGQLResetPasswordMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyResetPasswordInput;
}>;


export type IGQLResetPasswordMutation = { __typename: 'Mutation', jrnyResetPassword: { __typename: 'EmailLogin', email: string } };

export type IGQLRequestResetPasswordMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyResetPasswordRequestInput;
}>;


export type IGQLRequestResetPasswordMutation = { __typename: 'Mutation', jrnyRequestResetPassword: string };


export const ResetPasswordDocument = gql`
    mutation resetPassword($input: JrnyResetPasswordInput!) {
  jrnyResetPassword(input: $input) {
    email
  }
}
    `;

export function useResetPasswordMutation() {
  return Urql.useMutation<IGQLResetPasswordMutation, IGQLResetPasswordMutationVariables>(ResetPasswordDocument);
};
export const RequestResetPasswordDocument = gql`
    mutation requestResetPassword($input: JrnyResetPasswordRequestInput!) {
  jrnyRequestResetPassword(input: $input)
}
    `;

export function useRequestResetPasswordMutation() {
  return Urql.useMutation<IGQLRequestResetPasswordMutation, IGQLRequestResetPasswordMutationVariables>(RequestResetPasswordDocument);
};