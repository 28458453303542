import {
  useCourseSettingsQuery,
  useUpdateCourseSettingsMutation,
} from "@/graphql/hooks/course-settings-hook";
import { useLocalChange } from "@/hooks/use-local-change";
import { generateDebug } from "@/utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  SvgIconProps,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useEffect, useMemo } from "react";
import { ColorPickerProps } from "react-best-gradient-color-picker";
import { useParams } from "react-router-dom";
import { parseSync, stringify } from "svgson";
import { AppButton } from "./components/AppButton";
import {
  ActiveIcon,
  ClockIcon,
  DefaultDiamondIcon,
  DefaultGemIcon,
  DownloadIcon,
  FlagIcon
} from "./components/AppIcons";
import { AppView } from "./components/AppView";
import { GradientPicker } from "./components/GradientPicker";
import { CourseSettings } from "./types";
import { renderCustomSvgIcon } from "./utils";
const debug = generateDebug("CourseSettingsView");

//=============================================================================
// TYPES & INTERFACES
//=============================================================================

//=============================================================================
// CONSTANTS & DEFAULTS
//=============================================================================

const defaultPickerProps: Partial<ColorPickerProps> = {
  hideAdvancedSliders: false,
  hidePresets: true,
  hideGradientControls: true,
  hideEyeDrop: true,
  hideInputType: true,
  hideColorGuide: true,
  hidePickerSquare: true,
  hideGradientType: true,
  hideGradientAngle: true,
  hideColorTypeBtns: true,
  hideInputs: true,
};

function capitalizeIfNotEmpty(str: string) {
  if (!str) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

interface ColorPickerChangeHandler {
  (value: string): void;
}

//=============================================================================
// MAIN COMPONENT
//=============================================================================

export default function CourseSettingsView() {
  const { courseId } = useParams();
  const [settings] = useCourseSettingsQuery({
    variables: { courseId: courseId || "" },
    pause: !courseId,
  });
  const [_, updateSettings] = useUpdateCourseSettingsMutation();

  const [localSettings, setLocalSettings] = useLocalChange<CourseSettings>(
    settings.data?.jrnyCourseSettings as CourseSettings,
    (original, local) => {
      const gemSvg = local.gemSvg ? stringify(parseSync(local.gemSvg)) : "";
      const diamondSvg = local.diamondSvg
        ? stringify(parseSync(local.diamondSvg))
        : "";

      const { __typename, ...customThemeSettings } = local.customThemeSettings;
      return updateSettings({
        input: {
          id: local.id,
          theme: local.theme,
          useCustomGem: local.useCustomGem,
          gemSvg: gemSvg,
          gemName: capitalizeIfNotEmpty(local.gemName),
          gemNamePlural: capitalizeIfNotEmpty(local.gemNamePlural),
          useCustomDiamond: local.useCustomDiamond,
          diamondSvg: diamondSvg,
          diamondName: capitalizeIfNotEmpty(local.diamondName),
          diamondNamePlural: capitalizeIfNotEmpty(local.diamondNamePlural),
          customThemeSettings: customThemeSettings,
        },
      });
    }
  );
  const buttonGradientDisabled = useMemo(() => {
    return (
      localSettings?.customThemeSettings.buttonGradient?.replace(
        /rgba?\([^)]+\)/g,
        (match: string) => {
          if (match.startsWith("rgba")) {
            const parts = match.slice(5, -1).split(",");
            return `rgba(${parts[0]},${parts[1]},${parts[2]},${
              parseFloat(parts[3]) * 0.8
            })`;
          }
          return `rgba(${match.slice(4, -1)},0.8)`;
        }
      ) || ""
    );
  }, [localSettings?.customThemeSettings.buttonGradient]);
  useEffect(() => {
    debug("localSettings", localSettings);
  }, [localSettings]);
  const hasCustomTheme = useMemo(
    () => !!localSettings?.customThemeSettings.backgroundGradient1,
    [localSettings?.customThemeSettings]
  );
  useEffect(() => {
    if (
      localSettings?.theme == "custom" &&
      !hasCustomTheme &&
      (settings.data?.jrnyCourseSettings?.theme != "custom" ||
        (settings.data?.jrnyCourseSettings?.theme == "custom" &&
          !settings.data?.jrnyCourseSettings?.customThemeSettings
            .backgroundGradient1))
    ) {
      setLocalSettings((draft) => {
        draft!.customThemeSettings = {
          __typename: "JrnyCustomThemeColorsV1",
          primaryColor: "#40B777",
          secondaryColor: "#F8ACFF",
          backgroundGradient1:
            "linear-gradient(134deg, rgba(255, 243, 0, .14) 0%, rgba(0, 195, 255, 0.2) 50%, rgba(79, 0, 255, 0.34) 100%)",
          backgroundGradient2:
            "linear-gradient(202deg, rgba(40, 254, 254, 1) 0%, rgba(0, 113, 184, 1) 23%, rgba(180, 49, 171, 0.5) 60%, rgba(176, 57, 146, 1) 100%)",
          lessonGradient1:
            "linear-gradient(180deg, rgba(5, 4, 11, 0.6) 0%, rgba(66, 54, 142, 0.6) 41.67%, rgba(248, 172, 255, 0.6) 100%)",
          lessonGradient2:
            "linear-gradient(202deg, rgba(40, 254, 254, 1) 0%, rgba(0, 113, 184, 1) 23%, rgba(180, 49, 171, 0.5) 60%, rgba(176, 57, 146, 1) 100%)",
          buttonGradient:
            "linear-gradient(263.33deg, #362C78 0.57%, #328EBB 53.05%, #40B777 102.35%)",
        };
      });
    }
  }, [localSettings?.theme, settings, hasCustomTheme]);
  const [GemIcon, gemSvgError] = useMemo(() => {
    if (!localSettings?.useCustomGem || !localSettings?.gemSvg) {
      return [DefaultGemIcon, undefined];
    }
    try {
      const asJSON = parseSync(localSettings.gemSvg);
      debug("asJSON", asJSON);
      return [
        (props: SvgIconProps) => renderCustomSvgIcon(asJSON, props),
        undefined,
      ];
    } catch (e) {
      return [DefaultGemIcon, (e as Error).message];
    }
  }, [localSettings?.useCustomGem, localSettings?.gemSvg]);

  const [DiamondIcon, diamondSvgError] = useMemo(() => {
    if (!localSettings?.useCustomDiamond || !localSettings?.diamondSvg) {
      return [DefaultDiamondIcon, undefined];
    }
    try {
      const asJSON = parseSync(localSettings.diamondSvg);
      debug("asJSON", asJSON);
      return [
        (props: SvgIconProps) => renderCustomSvgIcon(asJSON, props),
        undefined,
      ];
    } catch (e) {
      return [DefaultDiamondIcon, (e as Error).message];
    }
  }, [localSettings?.useCustomDiamond, localSettings?.diamondSvg]);

  //=============================================================================
  // PREVIEW CONTENT - Used in multiple places
  //=============================================================================
  const appComponents = localSettings && hasCustomTheme && (
    <>
      <Stack direction="row" spacing={2}>
        <FlagIcon sx={{ fontSize: 24 }} />
        <DownloadIcon sx={{ fontSize: 24 }} />
        <ActiveIcon sx={{ fontSize: 24 }} />
        <ClockIcon sx={{ fontSize: 24 }} />
        <GemIcon sx={{ fontSize: 24 }} />
        <DiamondIcon sx={{ fontSize: 24 }} />
      </Stack>
      <Stack direction="row" spacing={2}>
        <AppButton
          gradient={localSettings!.customThemeSettings.buttonGradient || ""}
          gradientDisabled={buttonGradientDisabled}
        >
          Enabled Button
        </AppButton>
        <AppButton
          gradient={localSettings!.customThemeSettings.buttonGradient || ""}
          gradientDisabled={buttonGradientDisabled}
          disabled
        >
          Disabled Button
        </AppButton>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Typography color={"primary"}>Primary Color</Typography>
        <Typography color={"secondary"}>Secondary Color</Typography>
      </Stack>
    </>
  );
  const appContent = localSettings && hasCustomTheme && (
    <>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          gap: 2,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          color: localSettings!.customThemeSettings.primaryColor || "",
        }}
      >
        {appComponents}
      </Paper>
      <Box
        sx={{
          p: 2,
          display: "flex",
          gap: 2,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          color: localSettings!.customThemeSettings.primaryColor || "",
        }}
      >
        {" "}
        {appComponents}
      </Box>
    </>
  );

  //=============================================================================
  // MAIN RENDER
  //=============================================================================

  return localSettings ? (
    <>
      <Paper sx={{ p: 2 }}>
        <Grid container spacing={3}>
          {/*==============================================================================
          //  THEME SELECTION
          //============================================================================*/}
          <Grid size={12} container spacing={2}>
            <Grid size={2} sx={{ alignSelf: "flex-start", pt: 1 }}>
              <Typography>Theme</Typography>
            </Grid>
            <Grid size={10}>
              <FormControl fullWidth>
                <Select
                  size="small"
                  value={localSettings.theme}
                  onChange={(evt) =>
                    setLocalSettings((draft) => {
                      draft!.theme = evt.target.value;
                    })
                  }
                >
                  <MenuItem value="default">Default</MenuItem>
                  <MenuItem value="yellow">Yellow</MenuItem>
                  <MenuItem value="custom">Custom</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {localSettings?.theme === "custom" && hasCustomTheme && (
              <>
                {/*==============================================================================
                //  THEME COLORS SECTION
                //============================================================================*/}
                <Grid size={12}>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <Box sx={{ flex: 1 }}>
                      <Stack spacing={2}>
                        <Box
                          sx={{
                            bgcolor: "background.paper",
                            borderRadius: 1,
                            opacity: 0.7,
                            p: 2,
                          }}
                        >
                          <AppView
                            gradient1={
                              localSettings!.customThemeSettings
                                .backgroundGradient1 || ""
                            }
                            gradient2={
                              localSettings!.customThemeSettings
                                .backgroundGradient2 || ""
                            }
                            primaryColor={
                              localSettings!.customThemeSettings.primaryColor ||
                              ""
                            }
                            secondaryColor={
                              localSettings!.customThemeSettings
                                .secondaryColor || ""
                            }
                          >
                            {appContent}
                          </AppView>
                          <Typography
                            variant="caption"
                            color="text.secondary"
                            align="center"
                            sx={{ display: "block", mt: 1 }}
                          >
                            Main Background
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            bgcolor: "background.paper",
                            borderRadius: 1,
                            opacity: 0.7,
                            p: 2,
                          }}
                        >
                          <AppView
                            gradient1={
                              localSettings!.customThemeSettings
                                .lessonGradient1 || ""
                            }
                            gradient2={
                              localSettings!.customThemeSettings
                                .lessonGradient2 || ""
                            }
                            primaryColor={
                              localSettings!.customThemeSettings.primaryColor ||
                              ""
                            }
                            secondaryColor={
                              localSettings!.customThemeSettings
                                .secondaryColor || ""
                            }
                            isLesson
                          >
                            {appContent}
                          </AppView>
                          <Typography
                            variant="caption"
                            color="text.secondary"
                            align="center"
                            sx={{ display: "block", mt: 1 }}
                          >
                            Lesson Background
                          </Typography>
                        </Box>
                      </Stack>
                    </Box>

                    <Box sx={{ width: 360 }}>
                      <Stack spacing={2}>
                        <Accordion defaultExpanded>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Theme Colors</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack spacing={2}>
                              <Box sx={{ width: "50%" }}>
                                <Typography>Primary Color</Typography>
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                  sx={{ mb: 1, display: "block" }}
                                >
                                  Used for main actions and highlights
                                </Typography>
                                <Box
                                  sx={{
                                    height: 40,
                                    mb: 2,
                                    borderRadius: 1,
                                    bgcolor:
                                      localSettings!.customThemeSettings
                                        .primaryColor || "",
                                    border: "1px solid rgba(255,255,255,0.1)",
                                    boxShadow: "inset 0 0 4px rgba(0,0,0,0.1)",
                                  }}
                                />
                                <GradientPicker
                                  value={
                                    localSettings!.customThemeSettings
                                      .primaryColor || ""
                                  }
                                  onChange={(value) =>
                                    setLocalSettings((draft) => {
                                      draft!.customThemeSettings.primaryColor =
                                        value;
                                    })
                                  }
                                  label=""
                                  description=""
                                  showHexAlpha={false}
                                />
                              </Box>
                              <Box sx={{ width: "50%" }}>
                                <Typography>Secondary Color</Typography>
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                  sx={{ mb: 1, display: "block" }}
                                >
                                  Used for complementary elements
                                </Typography>
                                <Box
                                  sx={{
                                    height: 40,
                                    mb: 2,
                                    borderRadius: 1,
                                    bgcolor:
                                      localSettings!.customThemeSettings
                                        .secondaryColor || "",
                                    border: "1px solid rgba(255,255,255,0.1)",
                                    boxShadow: "inset 0 0 4px rgba(0,0,0,0.1)",
                                  }}
                                />
                                <GradientPicker
                                  value={
                                    localSettings!.customThemeSettings
                                      .secondaryColor || ""
                                  }
                                  onChange={(value) =>
                                    setLocalSettings((draft) => {
                                      draft!.customThemeSettings.secondaryColor =
                                        value;
                                    })
                                  }
                                  label=""
                                  description=""
                                  showHexAlpha={false}
                                />
                              </Box>
                            </Stack>
                          </AccordionDetails>
                        </Accordion>

                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Main Background</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack spacing={2}>
                              <GradientPicker
                                value={
                                  localSettings!.customThemeSettings
                                    .backgroundGradient1 || ""
                                }
                                onChange={(value) =>
                                  setLocalSettings((draft) => {
                                    draft!.customThemeSettings.backgroundGradient1 =
                                      value;
                                  })
                                }
                                label="Gradient 1 (Top Layer)"
                                description="Semi-transparent overlay gradient"
                              />
                              <GradientPicker
                                value={
                                  localSettings!.customThemeSettings
                                    .backgroundGradient2 || ""
                                }
                                onChange={(value) =>
                                  setLocalSettings((draft) => {
                                    draft!.customThemeSettings.backgroundGradient2 =
                                      value;
                                  })
                                }
                                label="Gradient 2 (Base Layer)"
                                description="Main color gradient"
                              />
                            </Stack>
                          </AccordionDetails>
                        </Accordion>

                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Lesson Background</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack spacing={2}>
                              <GradientPicker
                                value={
                                  localSettings!.customThemeSettings
                                    .lessonGradient1 || ""
                                }
                                onChange={(value) =>
                                  setLocalSettings((draft) => {
                                    draft!.customThemeSettings.lessonGradient1 =
                                      value;
                                  })
                                }
                                label="Gradient 1 (Top Layer)"
                                description="Vertical overlay gradient"
                              />
                              <GradientPicker
                                value={
                                  localSettings!.customThemeSettings
                                    .lessonGradient2 || ""
                                }
                                onChange={(value) =>
                                  setLocalSettings((draft) => {
                                    draft!.customThemeSettings.lessonGradient2 =
                                      value;
                                  })
                                }
                                label="Gradient 2 (Base Layer)"
                                description="Main color gradient"
                              />
                            </Stack>
                          </AccordionDetails>
                        </Accordion>

                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Button Gradient</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <GradientPicker
                              showHexAlpha={false}
                              value={
                                localSettings!.customThemeSettings
                                  .buttonGradient || ""
                              }
                              onChange={(value) =>
                                setLocalSettings((draft) => {
                                  draft!.customThemeSettings.buttonGradient =
                                    value;
                                })
                              }
                              description="The disabled state will automatically reduce the opacity of the gradient colors to 80%"
                            />
                          </AccordionDetails>
                        </Accordion>
                      </Stack>
                    </Box>
                  </Box>
                </Grid>

                <Grid size={12}>
                  <Divider sx={{ my: 1, opacity: 0.3 }} />
                </Grid>
              </>
            )}
            {/*==============================================================================
                //  CUSTOM GEM SETTINGS SECTION
                //============================================================================*/}
            <Grid size={2} sx={{ alignSelf: "flex-start", pt: 1 }}>
              <Typography>Custom Gem Settings</Typography>
            </Grid>
            <Grid size={10}>
              <FormControlLabel
                control={
                  <Switch
                    checked={localSettings.useCustomGem}
                    onChange={(evt) =>
                      setLocalSettings((draft) => {
                        draft!.useCustomGem = evt.target.checked;
                      })
                    }
                  />
                }
                label="Enable"
              />
            </Grid>

            {localSettings.useCustomGem && (
              <>
                <Grid size={2}>
                  <Typography>Gem SVG</Typography>
                </Grid>
                <Grid size={10}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    value={localSettings.gemSvg || ""}
                    onChange={(evt) =>
                      setLocalSettings((draft) => {
                        draft!.gemSvg = evt.target.value;
                      })
                    }
                    placeholder="Enter SVG markup for custom gem"
                    error={!!gemSvgError}
                    helperText={gemSvgError}
                  />
                </Grid>

                <Grid size={2}>
                  <Typography>Gem Name (Singular)</Typography>
                </Grid>
                <Grid size={10}>
                  <TextField
                    fullWidth
                    size="small"
                    value={localSettings.gemName || ""}
                    onChange={(evt) =>
                      setLocalSettings((draft) => {
                        draft!.gemName = evt.target.value;
                      })
                    }
                    placeholder="e.g., Gem"
                  />
                </Grid>

                <Grid size={2}>
                  <Typography>Gem Name (Plural)</Typography>
                </Grid>
                <Grid size={10}>
                  <TextField
                    fullWidth
                    size="small"
                    value={localSettings.gemNamePlural || ""}
                    onChange={(evt) =>
                      setLocalSettings((draft) => {
                        draft!.gemNamePlural = evt.target.value;
                      })
                    }
                    placeholder="e.g., Gems"
                  />
                </Grid>

                <Grid size={2}>
                  <Typography>Icon Preview</Typography>
                </Grid>
                <Grid size={10}>
                  <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                    <Box sx={{ textAlign: "center" }}>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Small
                      </Typography>
                      <GemIcon sx={{ fontSize: 24 }} />
                    </Box>
                    <Box sx={{ textAlign: "center" }}>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Medium
                      </Typography>
                      <GemIcon sx={{ fontSize: 36 }} />
                    </Box>
                    <Box sx={{ textAlign: "center" }}>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Large
                      </Typography>
                      <GemIcon sx={{ fontSize: 48 }} />
                    </Box>
                  </Box>
                </Grid>
              </>
            )}

            {/*==============================================================================
                //  CUSTOM DIAMOND SETTINGS SECTION
                //============================================================================*/}
            <Grid size={2} sx={{ alignSelf: "flex-start", pt: 1 }}>
              <Typography>Custom Diamond Settings</Typography>
            </Grid>
            <Grid size={10}>
              <FormControlLabel
                control={
                  <Switch
                    checked={localSettings.useCustomDiamond}
                    onChange={(evt) =>
                      setLocalSettings((draft) => {
                        draft!.useCustomDiamond = evt.target.checked;
                      })
                    }
                  />
                }
                label="Enable"
              />
            </Grid>

            {localSettings.useCustomDiamond && (
              <>
                <Grid size={2}>
                  <Typography>Diamond SVG</Typography>
                </Grid>
                <Grid size={10}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    value={localSettings.diamondSvg || ""}
                    onChange={(evt) =>
                      setLocalSettings((draft) => {
                        draft!.diamondSvg = evt.target.value;
                      })
                    }
                    placeholder="Enter SVG markup for custom diamond"
                    error={!!diamondSvgError}
                    helperText={diamondSvgError}
                  />
                </Grid>

                <Grid size={2}>
                  <Typography>Diamond Name (Singular)</Typography>
                </Grid>
                <Grid size={10}>
                  <TextField
                    fullWidth
                    size="small"
                    value={localSettings.diamondName || ""}
                    onChange={(evt) =>
                      setLocalSettings((draft) => {
                        draft!.diamondName = evt.target.value;
                      })
                    }
                    placeholder="e.g., Diamond"
                  />
                </Grid>

                <Grid size={2}>
                  <Typography>Diamond Name (Plural)</Typography>
                </Grid>
                <Grid size={10}>
                  <TextField
                    fullWidth
                    size="small"
                    value={localSettings.diamondNamePlural || ""}
                    onChange={(evt) =>
                      setLocalSettings((draft) => {
                        draft!.diamondNamePlural = evt.target.value;
                      })
                    }
                    placeholder="e.g., Diamonds"
                  />
                </Grid>

                <Grid size={2}>
                  <Typography>Icon Preview</Typography>
                </Grid>
                <Grid size={10}>
                  <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                    <Box sx={{ textAlign: "center" }}>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Small
                      </Typography>
                      <DiamondIcon sx={{ fontSize: 24 }} />
                    </Box>
                    <Box sx={{ textAlign: "center" }}>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Medium
                      </Typography>
                      <DiamondIcon sx={{ fontSize: 36 }} />
                    </Box>
                    <Box sx={{ textAlign: "center" }}>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Large
                      </Typography>
                      <DiamondIcon sx={{ fontSize: 48 }} />
                    </Box>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  ) : null;
}
