import { Avatar, Box, TextField, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { useState } from 'react';
import { ProfileDisplay } from './ProfileDisplay';
import { Draft } from 'immer';
import { stringAvatar } from '../utils/avatar-utils';
interface ProfileInfoProps {
  avatar?: string;
  firstName: string;
  lastName: string;
  email: string;
  status: string;
  setLocalProfile: (updater: (draft: Draft<any>) => void) => void;
  readOnly?: boolean;
  userId?: string;
}

export function ProfileInfo({ 
  avatar,
  firstName,
  lastName,
  email,
  status,
  setLocalProfile,
  readOnly = false,
  userId,
}: ProfileInfoProps) {
  const [isEditing, setIsEditing] = useState(status === 'new');

  if (status !== 'new' && (!isEditing || readOnly)) {
    return (
      <Box display="flex" alignItems="center" gap={1}>
        <ProfileDisplay
          avatar={avatar}
          name={`${firstName} ${lastName}`.trim()}
          email={email}
          status={status}
          userId={userId}
        />
        {!readOnly && (
          <IconButton 
            size="small" 
            onClick={() => setIsEditing(true)}
            sx={{ ml: 2 }}
          >
            <EditIcon />
          </IconButton>
        )}
      </Box>
    );
  }

  return (
    <Box display="flex" gap={2} alignItems="flex-start">
      <Avatar {...stringAvatar(`${firstName} ${lastName}`.trim()||email, avatar, 56)}  />
      <Box display="flex" flexDirection="column" gap={2} flexGrow={1}>
        <Box display="flex" gap={2}>
          <TextField
            label="First Name"
            value={firstName}
            onChange={(e) =>
              setLocalProfile((draft) => {
                draft.user.firstName = e.target.value;
              })
            }
            size="small"
            fullWidth
          />
          <TextField
            label="Last Name"
            value={lastName}
            onChange={(e) =>
              setLocalProfile((draft) => {
                draft.user.lastName = e.target.value;
              })
            }
            size="small"
            fullWidth
          />
        </Box>
        <TextField
          label="Email"
          value={email}
          disabled
          size="small"
          fullWidth
        />
      </Box>
      <IconButton 
        onClick={() => setIsEditing(false)}
        sx={{ mt: 1 }}
      >
        <SaveIcon />
      </IconButton>
    </Box>
  );
} 