import { keys, mutationUpdates } from "@/graphql-client/GqlClientProvider";
import { IGQLJrnyParticipation, IGQLJrnyUserProfile } from "@/graphql-types";
import { generateDebug } from "@/utils";
import {
  CourseDocument,
  IGQLCloneCourseMutation,
  IGQLCloneCourseMutationVariables,
  IGQLCreateCourseMutation,
  IGQLCreateCourseMutationVariables,
  IGQLDeleteCourseMutation,
  IGQLDeleteCourseMutationVariables,
  IGQLUpdateCourseMutation,
  IGQLUpdateCourseMutationVariables,
} from "@graphql/courses-hook";
import {
  IGQLCreateFolderMutation,
  IGQLCreateFolderMutationVariables,
  IGQLCreateOrganizationMutation,
  IGQLDeleteFileMutation,
  IGQLDeleteFileMutationVariables,
  IGQLDeleteFolderMutation,
  IGQLDeleteFolderMutationVariables,
} from "@graphql/organizations-hook";
import {
  IGQLAddCourseRolesToUsersMutation,
  IGQLAddCourseRolesToUsersMutationVariables,
  IGQLAddCourseRoleToUserMutation,
  IGQLAddCourseRoleToUserMutationVariables,
  IGQLRemoveCourseRoleFromUserMutation,
  IGQLRemoveCourseRoleFromUserMutationVariables
} from "@graphql/participations-hook";
import {
  IGQLConfirmUploadsSimpleMutation,
  IGQLConfirmUploadsSimpleMutationVariables,
} from "@graphql/uploads-hook";
import { gql } from "urql";
import {
  IGQLCreateCourseOccasionsMutation,
  IGQLCreateCourseOccasionsMutationVariables,
  IGQLRemoveCourseOccasionMutation,
  IGQLRemoveCourseOccasionMutationVariables,
} from "./hooks/course-occasions-hook";
import {
  IGQLClonePerkMutation,
  IGQLClonePerkMutationVariables,
  IGQLCreatePerkMutation,
  IGQLCreatePerkMutationVariables,
  IGQLRefundPerkPurchaseMutation,
  IGQLRefundPerkPurchaseMutationVariables,
} from "./hooks/perks-hook";

const debug = generateDebug("mutation-updates");

const ORGANIZATION_COURSE_COUNT_FRAGMENT = gql`
  fragment _ on JrnyOrganization {
    id
    courseCount
  }
`;

const COURSE_PERK_COUNT_FRAGMENT = gql`
  fragment _ on JrnyCourse {
    id
    perkCount
  }
`;

// Coordinates is a scalar
keys.JrnyCoordinates = (data) => null;
keys.JrnyRoleSummary = (data) => null;
keys.JrnyUserRole = (data) => null;
keys.JrnyUserProfile = (data: IGQLJrnyUserProfile) => data.user.id;
keys.JrnyParticipation = (data: IGQLJrnyParticipation) =>
  `${data.courseId}_${data.userId}`;

////////////////////////////////////////////////////////////////////////////////
// Organization Related Updates
////////////////////////////////////////////////////////////////////////////////

mutationUpdates.jrnyCreateOrganization = (
  result: IGQLCreateOrganizationMutation,
  _args,
  cache,
  _info
) => {
  const list = cache.resolve("Query", "jrnyOrganizations");
  if (Array.isArray(list)) {
    list.push(result.jrnyCreateOrganization as any);
    cache.link("Query", "jrnyOrganizations", list as any);
  }
};

////////////////////////////////////////////////////////////////////////////////
// Course Related Updates
////////////////////////////////////////////////////////////////////////////////

mutationUpdates.jrnyCreateCourse = (
  result: IGQLCreateCourseMutation,
  args: IGQLCreateCourseMutationVariables,
  cache,
  _info
) => {
  const entity = {
    __typename: "JrnyOrganization",
    id: args.input.organizationId,
  };
  const list = cache.resolve(entity, "courses");
  if (Array.isArray(list)) {
    list.push(result.jrnyCreateCourse as any);
    cache.link(entity, "courses", list as any);
  }
  const count = cache.resolve(entity, "courseCount");
  if (count !== undefined) {
    cache.writeFragment(ORGANIZATION_COURSE_COUNT_FRAGMENT, {
      id: args.input.organizationId,
      courseCount: (count as number) + 1,
    });
  }
};

mutationUpdates.jrnyCloneCourse = (
  result: IGQLCloneCourseMutation,
  args: IGQLCloneCourseMutationVariables,
  cache,
  _info
) => {
  const entity = {
    __typename: "JrnyOrganization",
    id: result.jrnyCloneCourse.organizationId,
  };
  const list = cache.resolve(entity, "courses");
  if (Array.isArray(list)) {
    list.push(result.jrnyCloneCourse as any);
    cache.link(entity, "courses", list as any);
  }

  const count = cache.resolve(entity, "courseCount");
  if (count !== undefined) {
    cache.writeFragment(ORGANIZATION_COURSE_COUNT_FRAGMENT, {
      id: result.jrnyCloneCourse.organizationId,
      courseCount: (count as number) + 1,
    });
  }
};

mutationUpdates.jrnyRemoveCourse = (
  result: IGQLDeleteCourseMutation,
  args: IGQLDeleteCourseMutationVariables,
  cache,
  _info
) => {
  const entity = {
    __typename: "JrnyOrganization",
    id: result.jrnyRemoveCourse.organizationId,
  };
  const list = cache.resolve(entity, "courses");
  if (Array.isArray(list)) {
    const id = "JrnyCourse:" + result.jrnyRemoveCourse.id;
    cache.link(
      entity,
      "courses",
      (list as string[]).filter((i) => i != id)
    );
  }

  const count = cache.resolve(entity, "courseCount");
  if (count !== undefined) {
    cache.writeFragment(ORGANIZATION_COURSE_COUNT_FRAGMENT, {
      id: result.jrnyRemoveCourse.organizationId,
      courseCount: Math.max(0, (count as number) - 1),
    });
  }
};

mutationUpdates.jrnyUpdateCourse = (
  result: IGQLUpdateCourseMutation,
  args: IGQLUpdateCourseMutationVariables,
  cache,
  _info
) => {
  debug(result.jrnyUpdateCourse);
  cache.updateQuery({ query: CourseDocument }, () => {
    return result.jrnyUpdateCourse;
  });
};

////////////////////////////////////////////////////////////////////////////////
// Folder and File Related Updates
////////////////////////////////////////////////////////////////////////////////

mutationUpdates.jrnyCreateFolder = (
  result: IGQLCreateFolderMutation,
  args: IGQLCreateFolderMutationVariables,
  cache,
  _info
) => {
  const organizationId = args.input.organizationId;
  const list = cache.resolve("Query", "jrnyOrganizationFolders", {
    input: { id: organizationId },
  });

  if (Array.isArray(list)) {
    list.push(result.jrnyCreateFolder as any);
    cache.link(
      "Query",
      "jrnyOrganizationFolders",
      { input: { id: organizationId } },
      list as any
    );
  }
};

mutationUpdates.jrnyDeleteFolder = (
  result: IGQLDeleteFolderMutation,
  args: IGQLDeleteFolderMutationVariables,
  cache,
  _info
) => {
  const organizationId = args.input.organizationId;
  const list = cache.resolve("Query", "jrnyOrganizationFolders", {
    input: { id: organizationId },
  });
  if (Array.isArray(list)) {
    const id = "FileFolder:" + result.jrnyDeleteFolder.id;
    cache.link(
      "Query",
      "jrnyOrganizationFolders",
      { input: { id: organizationId } },
      (list as string[]).filter((i) => i != id)
    );
  }
};

mutationUpdates.jrnyDeleteFile = (
  result: IGQLDeleteFileMutation,
  args: IGQLDeleteFileMutationVariables,
  cache,
  _info
) => {
  const entity = {
    __typename: "FileFolder",
    id: result.jrnyDeleteFile.folderId,
  };

  const list = cache.resolve(entity, "fileObjects");
  if (Array.isArray(list)) {
    const id = "FileObject:" + result.jrnyDeleteFile.id;
    cache.link(
      entity,
      "fileObjects",
      (list as string[]).filter((i) => i != id)
    );
  }
};

mutationUpdates.uploadsConfirmSimple = (
  result: IGQLConfirmUploadsSimpleMutation,
  args: IGQLConfirmUploadsSimpleMutationVariables,
  cache,
  _info
) => {
  debug(result.uploadsConfirmSimple);
  const entity = {
    __typename: "FileFolder",
    id: result.uploadsConfirmSimple[0].folderId,
  };

  const list = cache.resolve(entity, "fileObjects");
  if (Array.isArray(list)) {
    list.push(...(result.uploadsConfirmSimple as any));
    cache.link(entity, "fileObjects", list as any);
  }
};

////////////////////////////////////////////////////////////////////////////////
// Participation Related Updates
////////////////////////////////////////////////////////////////////////////////

mutationUpdates.jrnyAddCourseRoleToUser = (
  result: IGQLAddCourseRoleToUserMutation,
  args: IGQLAddCourseRoleToUserMutationVariables,
  cache,
  _info
) => {
  const courseId = args.input.courseId;
  debug("jrnyCreateParticipation args: %o", args);
  const list = cache.resolve("Query", "jrnyParticipationsForCourse", {
    courseId,
  });
  const id = `JrnyParticipation:${args.input.courseId}_${result.jrnyAddCourseRoleToUser.userId}`;
  if (Array.isArray(list)) {
    if (!list.includes(id as any)) {
      list.push(result.jrnyAddCourseRoleToUser as any);
      cache.link(
        "Query",
        "jrnyParticipationsForCourse",
        { courseId },
        list as any
      );
    }
  }
};

mutationUpdates.jrnyAddCourseRolesToUsers = (
  result: IGQLAddCourseRolesToUsersMutation,
  args: IGQLAddCourseRolesToUsersMutationVariables,
  cache,
  _info
) => {
  const courseId = args.input.courseId;
  const list = cache.resolve("Query", "jrnyParticipationsForCourse", {
    courseId,
  });
  if (Array.isArray(list)) {
    const participations = result.jrnyAddCourseRolesToUsers;
    for (const p of participations) {
      const id = `JrnyParticipation:${p.courseId}_${p.userId}`;
      if (!list.includes(id as any)) {
        list.push(p);
      }
    }
    cache.link(
      "Query",
      "jrnyParticipationsForCourse",
      { courseId },
      list as any
    );
  }
};

mutationUpdates.jrnyRemoveCourseRoleFromUser = (
  result: IGQLRemoveCourseRoleFromUserMutation,
  args: IGQLRemoveCourseRoleFromUserMutationVariables,
  cache,
  _info
) => {
  const courseId = args.input.courseId;
  const list = cache.resolve("Query", "jrnyParticipationsForCourse", {
    courseId,
  });
  const id = `JrnyParticipation:${result.jrnyRemoveCourseRoleFromUser.courseId}_${result.jrnyRemoveCourseRoleFromUser.userId}`;
  if (
    result.jrnyRemoveCourseRoleFromUser.roleIds.length === 0 &&
    Array.isArray(list)
  ) {
    cache.link(
      "Query",
      "jrnyParticipationsForCourse",
      { courseId },
      (list as string[]).filter((i) => i != id)
    );
  }
};

////////////////////////////////////////////////////////////////////////////////
// Perk Related Updates
////////////////////////////////////////////////////////////////////////////////

mutationUpdates.jrnyCreatePerk = (
  result: IGQLCreatePerkMutation,
  args: IGQLCreatePerkMutationVariables,
  cache,
  _info
) => {
  const list = cache.resolve("Query", "jrnyPerks", {
    courseId: args.input.courseId,
  });
  if (Array.isArray(list)) {
    list.push(result.jrnyCreatePerk as any);
    cache.link(
      "Query",
      "jrnyPerks",
      {
        courseId: args.input.courseId,
      },
      list as any
    );
  }
  const entity = {
    __typename: "JrnyCourse",
    id: args.input.courseId,
  };

  const count = cache.resolve(entity, "perkCount");
  if (count !== undefined) {
    cache.writeFragment(COURSE_PERK_COUNT_FRAGMENT, {
      id: args.input.courseId,
      perkCount: (count as number) + 1,
    });
  }
};
mutationUpdates.jrnyRefundPerkPurchase = (
  result: IGQLRefundPerkPurchaseMutation,
  args: IGQLRefundPerkPurchaseMutationVariables,
  cache,
  _info
) => {
  const entity = {
    __typename: "JrnyPerk",
    id: args.input.perkId,
  };

  const list = cache.resolve(entity, "purchases");

  if (Array.isArray(list)) {
    const newPurchases = result.jrnyRefundPerkPurchase.purchases;
    cache.link(entity, "purchases", newPurchases as any);
  }
};
mutationUpdates.jrnyClonePerk = (
  result: IGQLClonePerkMutation,
  args: IGQLClonePerkMutationVariables,
  cache,
  _info
) => {
  const courseId = result.jrnyClonePerk.courseId;
  const list = cache.resolve("Query", "jrnyPerks", { courseId });
  if (Array.isArray(list)) {
    list.push(result.jrnyClonePerk as any);
    cache.link("Query", "jrnyPerks", { courseId }, list as any);
  }

  const entity = {
    __typename: "JrnyCourse",
    id: courseId,
  };
  const count = cache.resolve(entity, "perkCount");
  if (count !== undefined) {
    cache.writeFragment(COURSE_PERK_COUNT_FRAGMENT, {
      id: courseId,
      perkCount: (count as number) + 1,
    });
  }
};

////////////////////////////////////////////////////////////////////////////////
// Course Occasion Related Updates
////////////////////////////////////////////////////////////////////////////////

mutationUpdates.jrnyCreateCourseOccasions = (
  result: IGQLCreateCourseOccasionsMutation,
  args: IGQLCreateCourseOccasionsMutationVariables,
  cache,
  _info
) => {
  const list = cache.resolve("Query", "jrnyCourseOccasionsForCourse", {
    courseId: args.input.courseId,
  });
  if (Array.isArray(list)) {
    list.push(...(result.jrnyCreateCourseOccasions as any));
    cache.link(
      "Query",
      "jrnyCourseOccasionsForCourse",
      { courseId: args.input.courseId },
      list as any
    );
  }
};

mutationUpdates.jrnyRemoveCourseOccasion = (
  result: IGQLRemoveCourseOccasionMutation,
  args: IGQLRemoveCourseOccasionMutationVariables,
  cache,
  _info
) => {
  const list = cache.resolve("Query", "jrnyCourseOccasionsForCourse", {
    courseId: args.input.courseId,
  });
  if (Array.isArray(list)) {
    const id = "JrnyCourseOccasion:" + result.jrnyRemoveCourseOccasion.id;
    cache.link(
      "Query",
      "jrnyCourseOccasionsForCourse",
      { courseId: args.input.courseId },
      (list as string[]).filter((i) => i != id)
    );
  }
};

export { };
