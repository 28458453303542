import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import styles from './OrganizationCard.module.css';
import { ChevronRight } from '@mui/icons-material';
import { IconCreate, IconImage } from '@/icons';
import { ListOrganization } from '../types';
interface OrganizationCardProps {
  organization: ListOrganization;
  isSelected?: boolean;
  onClick?: () => void;
  badge?: string;
  isNew?: boolean;
}

export const OrganizationCard: React.FC<OrganizationCardProps> = ({
  organization,
  isSelected = false,
  onClick,
  badge,
  isNew = false,
}) => {
  const {name, courseCount} = organization;
  return (
    <Card 
      className={`${styles.card} ${isSelected ? styles.selected : ''}`}
      onClick={onClick}
      elevation={0}
    >
      <CardContent className={styles.cardContent}>
        <Box className={styles.logoContainer}>
          <div className={styles.logo}>
            {isNew ? <IconCreate fontSize='large'/> : <IconImage fontSize='large'/>}
          </div>
        </Box>
        
        <Box className={styles.infoContainer}>
          <Typography variant="h6" className={styles.organizationName}>
            {name}
          </Typography>
          
          {!isNew && <Typography variant="body2" color="text.secondary" className={styles.stats}>
            {courseCount} Courses 
          </Typography>}

        </Box>

        <ChevronRight className={styles.chevron} />
      </CardContent>
    </Card>
  );
};

export default OrganizationCard; 