import { Chip } from "@mui/material";

const statusColors = {
    ACCEPTED: {
        color: "success.main",
    },
    PENDING: {
        color: "warning.main",
    },
    REVOKED: {
        color: "error.main",
    },
}
export const StatusChip = ({ status }: { status: string }) => {
  return (
    <Chip
      label={status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()}
      size="small"
      sx={{
        backgroundColor: "transparent",
        color: statusColors[status as keyof typeof statusColors]?.color,
      }}
    />
  );
};
