import * as Types from '../../graphql-types';

import { gql } from 'urql';
export type IGQLListOrganizationFieldsFragment = { __typename: 'JrnyOrganization', id: string, name: string, courseCount: number, logo?: { __typename: 'FileObject', id: string, url: string } | null };

export const ListOrganizationFieldsFragmentDoc = gql`
    fragment ListOrganizationFields on JrnyOrganization {
  id
  name
  courseCount
  logo {
    id
    url
  }
}
    `;