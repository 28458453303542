import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Menu, MenuItem, Tab, Tabs } from "@mui/material";
import { Draft } from "immer";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { CourseRoleTab } from "./role-tabs/CourseRoleTab";
import { GlobalRoleTab } from "./role-tabs/GlobalRoleTab";
import { OrganizationRoleTab } from "./role-tabs/OrganizationRoleTab";
import { roleDefinitions } from "../roleDefinitions";
import { useOrganizationNamesQuery } from "@/graphql/hooks/organizations-hook";
import {
  AvailableRoles,
  CourseByIdRole,
  GlobalByIdRole,
  OrganizationByIdRole,
  RolesByRoleId,
} from "../types";
import { IconCancel } from "@/icons";

interface RoleEditorProps {
  organizationId?: string;
  courseId?: string;
  localRoles: RolesByRoleId;
  setLocalRoles: (updater: (prev: RolesByRoleId | undefined) => void) => void;
}

const availableRoles: AvailableRoles[] = Object.keys(
  roleDefinitions
) as AvailableRoles[];

export function RoleEditor({
  localRoles,
  setLocalRoles,
  organizationId,
  courseId,
}: RoleEditorProps) {
  const [{ data: orgsData }] = useOrganizationNamesQuery();

  const [namesById, orgs] = useMemo(() => {
    if (orgsData?.jrnyOrganizations) {
      const orgs = orgsData.jrnyOrganizations.filter(org => {
        if( organizationId && org.id !== organizationId) {
          return false;
        }
        return true;
      }).map((org) => ({
        id: org.id,
        name: org.name,
        courses: org.courses.filter(course => {
          if( courseId && course.id !== courseId) {
            return false;
          }
          return true;
        }).map((course) => ({
          id: course.id,
          name: course.name,
        })),
      }));
      const namesById = orgs.reduce(
        (acc, org) => {
          acc[org.id] = org.name;
          org.courses.forEach((course) => {
            acc[course.id] = course.name;
          });
          return acc;
        },
        {} as Record<string, string>
      );
      return [namesById, orgs] as const;
    }
    return [{}, []] as const;
  }, [orgsData?.jrnyOrganizations]);
  const allowedRoles = useMemo(
    () =>
      availableRoles.filter((r) => {
        if (organizationId && roleDefinitions[r].scope === "global") {
          return false;
        }
        if (courseId && roleDefinitions[r].scope === "organization") {
          return false;
        }
        return true;
      }),
    [organizationId, courseId]
  );
  const tabs: AvailableRoles[] = Object.keys(
    localRoles || {}
  ) as AvailableRoles[];
  const [selectedTab, setSelectedTab] = useState<AvailableRoles | null>(
    tabs?.[0] || null
  );

  useEffect(() => {
    const tabs = Object.keys(localRoles || {}) as AvailableRoles[];
    if (tabs.length > 0 && (!selectedTab || !tabs.includes(selectedTab))) {
      setSelectedTab(tabs?.[0]);
    }
  }, [localRoles]);
  // Add menu
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const handleAddClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAddClose = () => {
    setAnchorEl(null);
  };

  const renderRoleTab = () => {
    if (!selectedTab) return null;
    const roles = roleDefinitions[selectedTab];
    if (!localRoles![selectedTab]) {
      return null;
    }
    switch (selectedTab) {
      case "admin":
        return (
          <GlobalRoleTab
            roles={roles.roleDescriptions}
            deleteRole={() => {
              setLocalRoles((prev) => {
                delete prev![selectedTab];
              });
            }}
          />
        );
      case "course-creator":
        return (
          <OrganizationRoleTab
            namesById={namesById}
            descriptions={roles.roleDescriptions}
            availableOrganizations={orgs}
            localRole={localRoles![selectedTab] as OrganizationByIdRole}
            updateLocalRole={(updater) =>
              setLocalRoles((prev) => {
                updater(prev![selectedTab] as Draft<OrganizationByIdRole>);
              })
            }
          />
        );
      case "instructor-team":
      case "instructor":
      case "student":
        return (
          <CourseRoleTab
            namesById={namesById}
            descriptions={roles.roleDescriptions}
            availableOrganizations={orgs}
            localRole={localRoles![selectedTab] as CourseByIdRole}
            updateLocalRole={(updater) =>
              setLocalRoles((prev) => {
                updater(prev![selectedTab] as Draft<CourseByIdRole>);
              })
            }
          />
        );
      default:
        return null;
    }
  };
  if (!localRoles) {
    return null;
  }
  return (
    <>
      {/* Role tabs section */}
      <Box display="flex" alignItems="center">
        {tabs.length > 0 && (
          <Tabs
            value={selectedTab}
            onChange={(_, newValue) => setSelectedTab(newValue)}
            variant="scrollable"
            scrollButtons="auto"
          >
            {tabs.map((role) => {
              const roleDef = roleDefinitions[role];
              return (
                <Tab
                  sx={{
                    "&.MuiTab-root": {
                      height: 32,
                    },
                  }}
                  key={role}
                  label={roleDef?.name || role}
                  value={role}
                />
              );
            })}
          </Tabs>
        )}
        <Box flexGrow={1} />
        <Button
          sx={{
            ml: tabs.length > 0 ? 0 : undefined,
            px: tabs.length > 0 ? 0 : undefined,
            py: tabs.length > 0 ? 0.5 : undefined,
            minWidth: 32,
          }}
          variant="contained"
          size="small"
          onClick={handleAddClick}
          startIcon={tabs.length == 0 ? <AddIcon /> : undefined}
        >
          {tabs.length > 0 ? <AddIcon fontSize="small" /> : "Add role"}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleAddClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {allowedRoles.map((role) => (
            <MenuItem
              key={role}
              onClick={() => {
                setLocalRoles((prev) => {
                  const roleDef = roleDefinitions[role];
                  const roleId = roleDef.id;
                  const roleScope = roleDef.scope;
                  let toAdd:
                    | GlobalByIdRole
                    | OrganizationByIdRole
                    | CourseByIdRole;
                  switch (roleScope) {
                    case "global":
                      toAdd = { scope: "global" };
                      break;
                    case "organization":
                      toAdd = { scope: "organization", organizationIds: [] };
                      break;
                    case "course":
                      toAdd = { scope: "course", organizations: {} };
                      break;
                  }
                  prev![roleId] = toAdd;
                });
                setSelectedTab(role);
                handleAddClose();
              }}
              disabled={!!localRoles?.[role as keyof typeof localRoles]}
            >
              {roleDefinitions[role].name}
            </MenuItem>
          ))}
        </Menu>
      </Box>

      {/* Role content section */}
      <Box display="flex" flexDirection="column" gap={3}>
        {renderRoleTab()}
      </Box>
    </>
  );
}
