import {Box, Menu, MenuItem, Typography} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';
import {useCurrentUser} from "@/context/CurrentUserProvider";
import {useCurrentOrganization} from "@/context/OrganizationProvider";
import {IconMenu} from "@/icons";
import {useAssets} from "@/context/AssetsProvider";
import UserSection from './components/UserSection';
import BreadCrumbs from '@/components/breadcrumbs/BreadCrumbs';

const version = __APP_VERSION__

const lightColor = 'rgba(255, 255, 255, 0.7)';

interface HeaderProps {
  onDrawerToggle: () => void;
  children?: React.ReactNode;
}

export default function Header(props: HeaderProps) {
  const {onDrawerToggle} = props;
  return (
    (<React.Fragment>
      <AppBar sx={{backgroundColor: 'white'}} position="sticky" elevation={0}>
        {props.children}
        <Toolbar>
          <Grid container spacing={1} alignItems="center" flexGrow={1} flexShrink={1} flexWrap={'nowrap'}>
            <Grid sx={{display: {sm: 'none', xs: 'block'}}}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
              >
                <IconMenu/>
              </IconButton>
            </Grid>
            <Grid flexGrow={1} flexShrink={1}  >
              <BreadCrumbs />
            </Grid>
            <Grid flexGrow={1} flexShrink={.5} ></Grid>
            <UserSection />
          </Grid>
        </Toolbar>
        <Box sx={{color: 'grey.500', position: 'absolute', p: '2px', bottom: 5, right: 0, opacity: 1, fontSize: 8}}>v{version}</Box>
      </AppBar>
      <AppBar
        component="div"
        color="primary"
        position="static"
        elevation={0}
        sx={{zIndex: 0}}
      >
      </AppBar>
    </React.Fragment>)
  );
}
