import { Avatar, Stack, Typography } from "@mui/material";
import * as React from "react";
import { stringAvatar } from "../utils/avatar-utils";

interface UserCellProps {
  name: string;
  email: string;
}

export const UserCell = ({ name, email }: UserCellProps) => (
  <Stack direction="row" spacing={2} alignItems="center">
    <Avatar {...stringAvatar(name?.trim()||email)} />
    <Stack>
      <Typography variant="body1" noWrap overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">{name}</Typography>
      <Typography variant="body2" color="text.secondary" noWrap overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">{email}</Typography>
    </Stack>
  </Stack>
); 