import * as Types from '../../graphql-types';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IGQLUserProfileFragment = { __typename: 'JrnyUserProfile', status: Types.IGQLInvitationStatus, createdAt: any, user: { __typename: 'JrnyUser', id: string, email: string, firstName: string, lastName: string } };

export type IGQLFullUserProfileFragment = { __typename: 'JrnyUserProfile', status: Types.IGQLInvitationStatus, createdAt: any, roles: Array<{ __typename: 'JrnyUserRole', scope: string, roleIds: Array<string>, organizationId?: string | null, courseId?: string | null }>, roleSummary?: Array<{ __typename: 'JrnyRoleSummary', id: string, name: string, count: number }> | null, user: { __typename: 'JrnyUser', id: string, email: string, firstName: string, lastName: string } };

export type IGQLUserProfilesQueryVariables = Types.Exact<{
  offset: Types.Scalars['Int']['input'];
  limit: Types.Scalars['Int']['input'];
  sort?: Types.InputMaybe<Types.IGQLJrnyUserProfileSort>;
}>;


export type IGQLUserProfilesQuery = { __typename: 'Query', jrnyUserProfiles: { __typename: 'JrnyUserProfilesResponse', total: number, offset: number, limit: number, items: Array<{ __typename: 'JrnyUserProfile', status: Types.IGQLInvitationStatus, createdAt: any, roleSummary?: Array<{ __typename: 'JrnyRoleSummary', id: string, name: string, count: number }> | null, user: { __typename: 'JrnyUser', id: string, email: string, firstName: string, lastName: string } }> } };

export type IGQLUserProfilesForOrganizationQueryVariables = Types.Exact<{
  offset: Types.Scalars['Int']['input'];
  limit: Types.Scalars['Int']['input'];
  sort?: Types.InputMaybe<Types.IGQLJrnyUserProfileSort>;
  organizationId: Types.Scalars['ID']['input'];
}>;


export type IGQLUserProfilesForOrganizationQuery = { __typename: 'Query', jrnyUserProfilesForOrganization: { __typename: 'JrnyUserProfilesResponse', total: number, offset: number, limit: number, items: Array<{ __typename: 'JrnyUserProfile', status: Types.IGQLInvitationStatus, createdAt: any, roleSummary?: Array<{ __typename: 'JrnyRoleSummary', id: string, name: string, count: number }> | null, user: { __typename: 'JrnyUser', id: string, email: string, firstName: string, lastName: string } }> } };

export type IGQLUserProfilesForCourseQueryVariables = Types.Exact<{
  courseId: Types.Scalars['ID']['input'];
  offset: Types.Scalars['Int']['input'];
  limit: Types.Scalars['Int']['input'];
  sort?: Types.InputMaybe<Types.IGQLJrnyUserProfileSort>;
}>;


export type IGQLUserProfilesForCourseQuery = { __typename: 'Query', jrnyUserProfilesForCourse: { __typename: 'JrnyUserProfilesResponse', total: number, offset: number, limit: number, items: Array<{ __typename: 'JrnyUserProfile', status: Types.IGQLInvitationStatus, createdAt: any, roleSummary?: Array<{ __typename: 'JrnyRoleSummary', id: string, name: string, count: number }> | null, user: { __typename: 'JrnyUser', id: string, email: string, firstName: string, lastName: string } }> } };

export type IGQLUserProfileQueryVariables = Types.Exact<{
  userId: Types.Scalars['String']['input'];
}>;


export type IGQLUserProfileQuery = { __typename: 'Query', jrnyUserProfile: { __typename: 'JrnyUserProfile', status: Types.IGQLInvitationStatus, createdAt: any, roles: Array<{ __typename: 'JrnyUserRole', scope: string, roleIds: Array<string>, organizationId?: string | null, courseId?: string | null }>, roleSummary?: Array<{ __typename: 'JrnyRoleSummary', id: string, name: string, count: number }> | null, user: { __typename: 'JrnyUser', id: string, email: string, firstName: string, lastName: string } } };

export type IGQLUpdateUserProfileMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyUpdateUserProfileInput;
}>;


export type IGQLUpdateUserProfileMutation = { __typename: 'Mutation', jrnyUpdateUserProfile: { __typename: 'JrnyUserProfile', status: Types.IGQLInvitationStatus, createdAt: any, roles: Array<{ __typename: 'JrnyUserRole', scope: string, roleIds: Array<string>, organizationId?: string | null, courseId?: string | null }>, roleSummary?: Array<{ __typename: 'JrnyRoleSummary', id: string, name: string, count: number }> | null, user: { __typename: 'JrnyUser', id: string, email: string, firstName: string, lastName: string } } };

export type IGQLUpdateUserProfileForOrganizationMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyUpdateUserProfileInput;
  organizationId: Types.Scalars['String']['input'];
}>;


export type IGQLUpdateUserProfileForOrganizationMutation = { __typename: 'Mutation', jrnyUpdateUserProfileForOrganization: { __typename: 'JrnyUserProfile', status: Types.IGQLInvitationStatus, createdAt: any, roles: Array<{ __typename: 'JrnyUserRole', scope: string, roleIds: Array<string>, organizationId?: string | null, courseId?: string | null }>, roleSummary?: Array<{ __typename: 'JrnyRoleSummary', id: string, name: string, count: number }> | null, user: { __typename: 'JrnyUser', id: string, email: string, firstName: string, lastName: string } } };

export type IGQLUpdateUserProfileForCourseMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyUpdateUserProfileInput;
  courseId: Types.Scalars['String']['input'];
}>;


export type IGQLUpdateUserProfileForCourseMutation = { __typename: 'Mutation', jrnyUpdateUserProfileForCourse: { __typename: 'JrnyUserProfile', status: Types.IGQLInvitationStatus, createdAt: any, roles: Array<{ __typename: 'JrnyUserRole', scope: string, roleIds: Array<string>, organizationId?: string | null, courseId?: string | null }>, roleSummary?: Array<{ __typename: 'JrnyRoleSummary', id: string, name: string, count: number }> | null, user: { __typename: 'JrnyUser', id: string, email: string, firstName: string, lastName: string } } };

export type IGQLCreateUserProfileMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyCreateUserProfileInput;
}>;


export type IGQLCreateUserProfileMutation = { __typename: 'Mutation', jrnyCreateUserProfile: { __typename: 'JrnyUserProfile', status: Types.IGQLInvitationStatus, createdAt: any, roles: Array<{ __typename: 'JrnyUserRole', scope: string, roleIds: Array<string>, organizationId?: string | null, courseId?: string | null }>, roleSummary?: Array<{ __typename: 'JrnyRoleSummary', id: string, name: string, count: number }> | null, user: { __typename: 'JrnyUser', id: string, email: string, firstName: string, lastName: string } } };

export type IGQLCreateUserProfileForOrganizationMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyCreateUserProfileInput;
  organizationId: Types.Scalars['String']['input'];
}>;


export type IGQLCreateUserProfileForOrganizationMutation = { __typename: 'Mutation', jrnyCreateUserProfileForOrganization: { __typename: 'JrnyUserProfile', status: Types.IGQLInvitationStatus, createdAt: any, roles: Array<{ __typename: 'JrnyUserRole', scope: string, roleIds: Array<string>, organizationId?: string | null, courseId?: string | null }>, roleSummary?: Array<{ __typename: 'JrnyRoleSummary', id: string, name: string, count: number }> | null, user: { __typename: 'JrnyUser', id: string, email: string, firstName: string, lastName: string } } };

export type IGQLCreateUserProfileForCourseMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyCreateUserProfileInput;
  courseId: Types.Scalars['String']['input'];
}>;


export type IGQLCreateUserProfileForCourseMutation = { __typename: 'Mutation', jrnyCreateUserProfileForCourse: { __typename: 'JrnyUserProfile', status: Types.IGQLInvitationStatus, createdAt: any, roles: Array<{ __typename: 'JrnyUserRole', scope: string, roleIds: Array<string>, organizationId?: string | null, courseId?: string | null }>, roleSummary?: Array<{ __typename: 'JrnyRoleSummary', id: string, name: string, count: number }> | null, user: { __typename: 'JrnyUser', id: string, email: string, firstName: string, lastName: string } } };

export type IGQLResendInvitationForUserMutationVariables = Types.Exact<{
  userId: Types.Scalars['String']['input'];
}>;


export type IGQLResendInvitationForUserMutation = { __typename: 'Mutation', jrnyResendInvitationForUser: { __typename: 'JrnyUserProfile', status: Types.IGQLInvitationStatus, createdAt: any, roles: Array<{ __typename: 'JrnyUserRole', scope: string, roleIds: Array<string>, organizationId?: string | null, courseId?: string | null }>, roleSummary?: Array<{ __typename: 'JrnyRoleSummary', id: string, name: string, count: number }> | null, user: { __typename: 'JrnyUser', id: string, email: string, firstName: string, lastName: string } } };

export type IGQLRevokeInvitationForUserMutationVariables = Types.Exact<{
  userId: Types.Scalars['String']['input'];
}>;


export type IGQLRevokeInvitationForUserMutation = { __typename: 'Mutation', jrnyRevokeInvitationForUser: { __typename: 'JrnyUserProfile', status: Types.IGQLInvitationStatus, createdAt: any, roles: Array<{ __typename: 'JrnyUserRole', scope: string, roleIds: Array<string>, organizationId?: string | null, courseId?: string | null }>, roleSummary?: Array<{ __typename: 'JrnyRoleSummary', id: string, name: string, count: number }> | null, user: { __typename: 'JrnyUser', id: string, email: string, firstName: string, lastName: string } } };

export type IGQLGetUserIdByEmailQueryVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
}>;


export type IGQLGetUserIdByEmailQuery = { __typename: 'Query', jrnyGetUserIdByEmail?: string | null };

export const UserProfileFragmentDoc = gql`
    fragment UserProfile on JrnyUserProfile {
  user {
    id
    email
    firstName
    lastName
  }
  status
  createdAt
}
    `;
export const FullUserProfileFragmentDoc = gql`
    fragment FullUserProfile on JrnyUserProfile {
  ...UserProfile
  roles {
    scope
    roleIds
    organizationId
    courseId
  }
  roleSummary {
    id
    name
    count
  }
}
    ${UserProfileFragmentDoc}`;
export const UserProfilesDocument = gql`
    query userProfiles($offset: Int!, $limit: Int!, $sort: JrnyUserProfileSort) {
  jrnyUserProfiles(offset: $offset, limit: $limit, sort: $sort) {
    items {
      ...UserProfile
      roleSummary {
        id
        name
        count
      }
    }
    total
    offset
    limit
  }
}
    ${UserProfileFragmentDoc}`;

export function useUserProfilesQuery(options: Omit<Urql.UseQueryArgs<IGQLUserProfilesQueryVariables>, 'query'>) {
  return Urql.useQuery<IGQLUserProfilesQuery, IGQLUserProfilesQueryVariables>({ query: UserProfilesDocument, ...options });
};
export const UserProfilesForOrganizationDocument = gql`
    query userProfilesForOrganization($offset: Int!, $limit: Int!, $sort: JrnyUserProfileSort, $organizationId: ID!) {
  jrnyUserProfilesForOrganization(
    offset: $offset
    limit: $limit
    sort: $sort
    organizationId: $organizationId
  ) {
    items {
      ...UserProfile
      roleSummary {
        id
        name
        count
      }
    }
    total
    offset
    limit
  }
}
    ${UserProfileFragmentDoc}`;

export function useUserProfilesForOrganizationQuery(options: Omit<Urql.UseQueryArgs<IGQLUserProfilesForOrganizationQueryVariables>, 'query'>) {
  return Urql.useQuery<IGQLUserProfilesForOrganizationQuery, IGQLUserProfilesForOrganizationQueryVariables>({ query: UserProfilesForOrganizationDocument, ...options });
};
export const UserProfilesForCourseDocument = gql`
    query userProfilesForCourse($courseId: ID!, $offset: Int!, $limit: Int!, $sort: JrnyUserProfileSort) {
  jrnyUserProfilesForCourse(
    courseId: $courseId
    offset: $offset
    limit: $limit
    sort: $sort
  ) {
    items {
      ...UserProfile
      roleSummary {
        id
        name
        count
      }
    }
    total
    offset
    limit
  }
}
    ${UserProfileFragmentDoc}`;

export function useUserProfilesForCourseQuery(options: Omit<Urql.UseQueryArgs<IGQLUserProfilesForCourseQueryVariables>, 'query'>) {
  return Urql.useQuery<IGQLUserProfilesForCourseQuery, IGQLUserProfilesForCourseQueryVariables>({ query: UserProfilesForCourseDocument, ...options });
};
export const UserProfileDocument = gql`
    query userProfile($userId: String!) {
  jrnyUserProfile(userId: $userId) {
    ...FullUserProfile
  }
}
    ${FullUserProfileFragmentDoc}`;

export function useUserProfileQuery(options: Omit<Urql.UseQueryArgs<IGQLUserProfileQueryVariables>, 'query'>) {
  return Urql.useQuery<IGQLUserProfileQuery, IGQLUserProfileQueryVariables>({ query: UserProfileDocument, ...options });
};
export const UpdateUserProfileDocument = gql`
    mutation updateUserProfile($input: JrnyUpdateUserProfileInput!) {
  jrnyUpdateUserProfile(input: $input) {
    ...FullUserProfile
  }
}
    ${FullUserProfileFragmentDoc}`;

export function useUpdateUserProfileMutation() {
  return Urql.useMutation<IGQLUpdateUserProfileMutation, IGQLUpdateUserProfileMutationVariables>(UpdateUserProfileDocument);
};
export const UpdateUserProfileForOrganizationDocument = gql`
    mutation updateUserProfileForOrganization($input: JrnyUpdateUserProfileInput!, $organizationId: String!) {
  jrnyUpdateUserProfileForOrganization(
    input: $input
    organizationId: $organizationId
  ) {
    ...FullUserProfile
  }
}
    ${FullUserProfileFragmentDoc}`;

export function useUpdateUserProfileForOrganizationMutation() {
  return Urql.useMutation<IGQLUpdateUserProfileForOrganizationMutation, IGQLUpdateUserProfileForOrganizationMutationVariables>(UpdateUserProfileForOrganizationDocument);
};
export const UpdateUserProfileForCourseDocument = gql`
    mutation updateUserProfileForCourse($input: JrnyUpdateUserProfileInput!, $courseId: String!) {
  jrnyUpdateUserProfileForCourse(input: $input, courseId: $courseId) {
    ...FullUserProfile
  }
}
    ${FullUserProfileFragmentDoc}`;

export function useUpdateUserProfileForCourseMutation() {
  return Urql.useMutation<IGQLUpdateUserProfileForCourseMutation, IGQLUpdateUserProfileForCourseMutationVariables>(UpdateUserProfileForCourseDocument);
};
export const CreateUserProfileDocument = gql`
    mutation createUserProfile($input: JrnyCreateUserProfileInput!) {
  jrnyCreateUserProfile(input: $input) {
    ...FullUserProfile
  }
}
    ${FullUserProfileFragmentDoc}`;

export function useCreateUserProfileMutation() {
  return Urql.useMutation<IGQLCreateUserProfileMutation, IGQLCreateUserProfileMutationVariables>(CreateUserProfileDocument);
};
export const CreateUserProfileForOrganizationDocument = gql`
    mutation createUserProfileForOrganization($input: JrnyCreateUserProfileInput!, $organizationId: String!) {
  jrnyCreateUserProfileForOrganization(
    input: $input
    organizationId: $organizationId
  ) {
    ...FullUserProfile
  }
}
    ${FullUserProfileFragmentDoc}`;

export function useCreateUserProfileForOrganizationMutation() {
  return Urql.useMutation<IGQLCreateUserProfileForOrganizationMutation, IGQLCreateUserProfileForOrganizationMutationVariables>(CreateUserProfileForOrganizationDocument);
};
export const CreateUserProfileForCourseDocument = gql`
    mutation createUserProfileForCourse($input: JrnyCreateUserProfileInput!, $courseId: String!) {
  jrnyCreateUserProfileForCourse(input: $input, courseId: $courseId) {
    ...FullUserProfile
  }
}
    ${FullUserProfileFragmentDoc}`;

export function useCreateUserProfileForCourseMutation() {
  return Urql.useMutation<IGQLCreateUserProfileForCourseMutation, IGQLCreateUserProfileForCourseMutationVariables>(CreateUserProfileForCourseDocument);
};
export const ResendInvitationForUserDocument = gql`
    mutation resendInvitationForUser($userId: String!) {
  jrnyResendInvitationForUser(userId: $userId) {
    ...FullUserProfile
  }
}
    ${FullUserProfileFragmentDoc}`;

export function useResendInvitationForUserMutation() {
  return Urql.useMutation<IGQLResendInvitationForUserMutation, IGQLResendInvitationForUserMutationVariables>(ResendInvitationForUserDocument);
};
export const RevokeInvitationForUserDocument = gql`
    mutation revokeInvitationForUser($userId: String!) {
  jrnyRevokeInvitationForUser(userId: $userId) {
    ...FullUserProfile
  }
}
    ${FullUserProfileFragmentDoc}`;

export function useRevokeInvitationForUserMutation() {
  return Urql.useMutation<IGQLRevokeInvitationForUserMutation, IGQLRevokeInvitationForUserMutationVariables>(RevokeInvitationForUserDocument);
};
export const GetUserIdByEmailDocument = gql`
    query getUserIdByEmail($email: String!) {
  jrnyGetUserIdByEmail(email: $email)
}
    `;

export function useGetUserIdByEmailQuery(options: Omit<Urql.UseQueryArgs<IGQLGetUserIdByEmailQueryVariables>, 'query'>) {
  return Urql.useQuery<IGQLGetUserIdByEmailQuery, IGQLGetUserIdByEmailQueryVariables>({ query: GetUserIdByEmailDocument, ...options });
};