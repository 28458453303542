import { Typography } from '@mui/material';
import React from 'react';
import { useMatches } from 'react-router-dom';
import { useCurrentCourse } from '@/context/CourseProvider';
import { Checkpoint, Lesson, CrumbProps, crumbTypographyStyle } from './types';

export const LessonCrumb = ({ onUpdate }: CrumbProps) => {
  const { currentCourse } = useCurrentCourse();
  const matches = useMatches();
  const checkpointMatch = matches.find(match => match.params.checkpointId);
  const lessonMatch = matches.find(match => match.params.lessonId);
  
  const checkpointId = checkpointMatch?.params.checkpointId;
  const lessonId = lessonMatch?.params.lessonId;
  
  const checkpoint = currentCourse?.checkpoints?.find((cp: Checkpoint) => cp.id === checkpointId);
  const lesson = checkpoint?.lessons?.find((l: Lesson) => l.id === lessonId);
  const displayText = lesson ? `${checkpoint!.order + 1}.${lesson.order + 1} ${lesson.name}` : 'Lesson';
  
  React.useEffect(() => {
    onUpdate?.(lesson?.name || '');
  }, [lesson?.name, lesson?.order, checkpoint?.order]);

  return (
    <Typography component="span" color="inherit" sx={crumbTypographyStyle}>
      {displayText}
    </Typography>
  );
}; 