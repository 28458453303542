import { DebugView } from '@/components/DebugView';
import { DescriptionEditor } from '@/components/DescriptionEditor';
import { IGQLJrnyCreatePromptInput, IGQLJrnyUpdateLessonInput, } from '@/graphql-types';
import { useLocalChange } from '@/hooks/use-local-change';
import {
  IconChallenge,
  IconDelete,
  IconEdit,
  IconGoTo,
  IconPrompt,
  IconResource,
  IconSpeedDial,
  IconVideo
} from "@/icons";
import { SimpleLesson } from '@/pages/checkpoint/types';
import { Unpacked } from '@/types';
import { generateDebug } from '@/utils';
import DraggableList from '@components/draggable/DraggableList';
import { IGQLLessonQuery, useLessonQuery, useUpdateLessonMutation } from '@graphql/lessons-hook';
import {
  Avatar, FormControl,
  IconButton, InputLabel,
  ListItem,
  ListItemAvatar,
  ListItemText, MenuItem,
  Paper, Select,
  SpeedDial,
  SpeedDialAction,
  Stack,
  SvgIconTypeMap,
  TextField
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { Draft } from "immer";
import { uuidv4 } from 'lib0/random';
import { isEqual, omit, pick } from 'lodash';
import * as React from 'react';
import { DraggableProvided, DraggableStateSnapshot, DropResult } from 'react-beautiful-dnd';
import { useNavigate, useParams } from 'react-router-dom';

const debug = generateDebug('LessonDetailsView')
type SimpleEntity = { id: string, name: string, order: number, categoryOrder: number }

type Lesson = IGQLLessonQuery['jrnyLesson']
type AnyItem = Unpacked<Lesson['contentItems']>
type UpdatedAnyItem = Partial<AnyItem> & { id: string }
type LocalLesson = Lesson &
{ useTeacherNotes: boolean } &
{ mediaCount: number, resourceCount: number, promptCount: number, challengeCount: number }

const getChangedEntities = <T extends SimpleEntity>(localEntities: AnyItem[], orgEntities: AnyItem[]): [AnyItem[], AnyItem[], UpdatedAnyItem[]] => {
  const itemsAdded = localEntities
    .filter(lcp => !orgEntities.find(cp => cp.id === lcp.id))
  const itemsDeleted = orgEntities
    .filter(cp => !localEntities.find(lcp => cp.id === lcp.id))
  const itemsModified = localEntities
    .map(lcp => {
      const org = orgEntities.find(cp => cp.id === lcp.id)
      if (!org || isEqual(lcp, org)) {
        return null
      }
      const update: UpdatedAnyItem = { ...lcp, __typename: lcp.__typename }
      debug('Update', update)
      if (org.name === lcp.name) {
        delete update.name
      }
      if (org.order === lcp.order) {
        delete update.order
      }
      if (org.categoryOrder === lcp.categoryOrder) {
        delete update.categoryOrder
      }
      return update
    })
    .filter(lcp => lcp !== null) as UpdatedAnyItem[]
  debug('Entities:', localEntities, orgEntities)
  debug('Changed entities:', itemsAdded, itemsDeleted, itemsModified)
  return [itemsAdded, itemsDeleted, itemsModified]
}
const updateOrder = (draft: Draft<LocalLesson>) => {
  let media: AnyItem[] = []
  let challenges: AnyItem[] = []
  let prompts: AnyItem[] = []
  let resources: AnyItem[] = []
  let blockResources: AnyItem[] = []
  debug('Updating order', JSON.parse(JSON.stringify(draft.contentItems)))
  draft.contentItems.forEach((p: AnyItem, i: number) => {
    p.order = i
    switch (p.__typename) {
      case 'JrnyMedia':
        p.categoryOrder = media.length
        p.categoryOrder = media.length
        media.push(p)

        break;
      case 'JrnyPrompt':
        if (p.category === 'prompt') {
          p.categoryOrder = prompts.length
          prompts.push(p)
        } else {
          p.categoryOrder = challenges.length
          challenges.push(p)
        }
        break;
      case 'JrnyResource':
        p.categoryOrder = resources.length
        resources.push(p)
        break;
    }
  })
  debug('Updated order', JSON.parse(JSON.stringify(draft.contentItems)))


}
const getModificationsToSend = (localLesson: LocalLesson, orgLesson: Lesson) => {
  const [itemsAdded, itemsDeleted, itemsModified] = getChangedEntities(localLesson.contentItems, orgLesson.contentItems)

  const input: IGQLJrnyUpdateLessonInput = {
    id: localLesson.id!,
    version: localLesson.version,
    name: localLesson.name,
    teacherNotes: localLesson.useTeacherNotes && localLesson.teacherNotes ? localLesson.teacherNotes : null,
    summary: localLesson.summary || '',
    mediaAdded: itemsAdded.filter(i => i.__typename === 'JrnyMedia')
      .map(v => pick(v, ['id', 'name', 'order', 'categoryOrder'])),
    mediaDeleted: itemsDeleted.filter(i => i.__typename === 'JrnyMedia')
      .map(v => pick(v, ['id'])),
    mediaModified: itemsModified.filter(i => i.__typename === 'JrnyMedia')
      .map(v => pick(v, ['id', 'name', 'order', 'categoryOrder'])),

    promptsAdded: itemsAdded.filter(i => i.__typename === 'JrnyPrompt')
      .map(v => pick(v, ['id', 'name', 'order', 'categoryOrder', 'category']) as IGQLJrnyCreatePromptInput),
    promptsDeleted: itemsDeleted.filter(i => i.__typename === 'JrnyPrompt')
      .map(v => pick(v, ['id'])),
    promptsModified: itemsModified.filter(i => i.__typename === 'JrnyPrompt')
      .map(v => pick(v, ['id', 'name', 'order', 'categoryOrder'])),


    resourcesAdded: itemsAdded.filter(i => i.__typename === 'JrnyResource')
      .map(v => pick(v, ['id', 'name', 'order', 'categoryOrder'])),
    resourcesDeleted: itemsDeleted.filter(i => i.__typename === 'JrnyResource')
      .map(v => pick(v, ['id'])),
    resourcesModified: itemsModified.filter(i => i.__typename === 'JrnyResource')
      .map(v => pick(v, ['id', 'name', 'order', 'categoryOrder'])),
  }
  return input
}

interface LessonDetailsViewProps {

}


type ActionType = {
  icon: any,
  iconElement: OverridableComponent<SvgIconTypeMap>,
  name: string,
  color: string
  typename: AnyItem['__typename'],
  type: string,
  baseName: string,
  category?: 'prompt' | 'challenge' | 'inline'
  counter: 'mediaCount' | 'challengeCount' | 'promptCount' | 'resourceCount'
}
const actions: ActionType[] = [
  {
    icon: <IconResource />,
    iconElement: IconResource,
    color: '#050',
    category: 'inline',
    name: 'Add resource',
    type: 'resource',
    typename: 'JrnyResource',
    baseName: 'New Resource',
    counter: 'resourceCount'
  },
  {
    icon: <IconChallenge />,
    iconElement: IconChallenge,
    name: 'Add Challenge',
    color: '#ba0',
    type: 'challenge',
    typename: 'JrnyPrompt',
    category: 'challenge',
    baseName: 'New Challenge',
    counter: 'challengeCount'
  },
  {
    icon: <IconPrompt />,
    iconElement: IconPrompt,
    name: 'Add Prompt',
    color: '#a22',
    type: 'prompt',
    typename: 'JrnyPrompt',
    category: 'prompt',
    baseName: 'New Prompt',
    counter: 'promptCount'
  },
  {
    icon: <IconVideo />,
    iconElement: IconVideo,
    color: '#000',
    name: 'Add Media',
    type: 'media',
    typename: 'JrnyMedia',
    baseName: 'New Media',
    counter: 'mediaCount'
  },
];
// TODO: Move this somewhere better?
export const actionsByTypeName: Record<string, ActionType> = actions.reduce((prev, action) => ({
  ...prev,
  [action.typename + (action.category || '')]: action
}), {})


export default function LessonDetailsView(props: LessonDetailsViewProps) {
  const { lessonId } = useParams()
  const navigate = useNavigate()

  const [lesson] = useLessonQuery({ variables: { id: lessonId || '' }, pause: !lessonId })
  const [_, updateLesson] = useUpdateLessonMutation()

  const [localLesson, setLocalLesson] = useLocalChange<Lesson, LocalLesson>(
    lesson.data?.jrnyLesson,
    (org, local) => {
      const input = getModificationsToSend(local, org)
      return updateLesson({ input })

    },
    (org) => {

      const useTeacherNotes = org.teacherNotes !== null
      const mediaCount = org.contentItems.filter(i => i.__typename === 'JrnyMedia').length
      const resourceCount = org.contentItems.filter(i => i.__typename === 'JrnyResource').length
      const promptCount = org.contentItems.filter(i => i.__typename === 'JrnyPrompt' && i.category === 'prompt').length
      const challengeCount = org.contentItems.filter(i => i.__typename === 'JrnyPrompt' && i.category === 'challenge').length
      const contentItems = org.contentItems.map(i => {
        const t = i.__typename;
        const { __typename, ...rest } = i;
        const newItem =  { ...rest, __typename: t };
        return newItem
      }) as typeof org.contentItems
      const result = { ...org, contentItems, mediaCount, resourceCount, promptCount, challengeCount, useTeacherNotes }
      debug('Local lesson', result)
      return result
    },
    local => {
      const contentItems = local.contentItems.map(i => {
        const { __typename, ...rest } = i;
        const newItem =  { ...rest};
        Object.defineProperty(newItem, '__typename', { value: __typename, writable: false, enumerable: false, configurable: false });
        return newItem
      }) 
      return ({
        ...omit(local, ['useTeacherNotes', 'mediaCount', 'resourceCount', 'promptCount', 'challengeCount']),
        contentItems: contentItems as typeof local.contentItems,
        teacherNotes: local.useTeacherNotes ? local.teacherNotes : null,
        summary: local.summary || ''
      });
    },
  )
  const setTeacherNotes = React.useCallback((description: string) =>
    setLocalLesson(draft => {
      draft!.teacherNotes = description
    }), [])

  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;
    setLocalLesson(draft => {
      if (!draft) {
        return
      }
      const moved = draft.contentItems.splice(source.index, 1)
      draft.contentItems.splice(destination.index, 0, ...moved)
      updateOrder(draft)
    })
  };
  const onDelete = (index: number) => {
    setLocalLesson(draft => {
      if (!draft) {
        return
      }
      draft.contentItems.splice(index, 1)
      updateOrder(draft)
    })
  }
  const onCreate = (actionType: ActionType) => {
    setLocalLesson(draft => {
      if (!draft) {
        return
      }
      const newEntity = {
        __typename: actionType.typename,
        name: actionType.baseName + ' ' + (++draft![actionType.counter]),
        id: uuidv4(),
        category: actionType.category,
        order: draft.contentItems.length,
        categoryOrder: draft![actionType.counter],
      } as const
      if (actionType.typename === 'JrnyResource') {
        // @ts-ignore
        newEntity.description = '<p>Write your question here...</p>'
      }
      // @ts-ignore
      draft.contentItems.push(newEntity)
      updateOrder(draft)

    })
    debug('creating ', actionType)
  }

  const onEdit = (type: ActionType, item: SimpleLesson) => {
  }
  const onNavigate = (type: ActionType, item: SimpleLesson) => navigate(`./${type.type}/${item.id}`)

  return localLesson ?
    (<div>
      <Paper>
        <Stack>
          <Grid container spacing={1} sx={{ p: 2 }}>
            <Grid
              size={{
                sm: 10
              }}>
              <TextField
                label='Name/Title'
                fullWidth
                size='small'
                value={localLesson.name}
                onChange={(evt) => setLocalLesson(draft => {
                  draft!.name = evt.target.value
                })}
              />
            </Grid>
            <Grid
              size={{
                sm: 2
              }}>
              <FormControl fullWidth>
                <InputLabel id="available-label">Use Notes</InputLabel>
                <Select
                  labelId="use-notes-label"
                  label='Use Notes'
                  size='small'
                  displayEmpty
                  value={localLesson.useTeacherNotes ? '1' : '_'}
                  onChange={(evt) => setLocalLesson(draft => {
                    draft!.useTeacherNotes = evt.target.value == '1'
                  })}>
                  <MenuItem value='_'>No</MenuItem>
                  <MenuItem value='1'>Yes</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {localLesson.useTeacherNotes && <Grid size={12}>
              <DescriptionEditor
                content={localLesson.teacherNotes || ''}
                onChange={setTeacherNotes}
                label={'Teacher Notes'} />
            </Grid>}
            <Grid
              size={{
                sm: 12
              }}>
              <TextField
                label='Summary (optional, used by AI to correct prompts and challenges)'
                fullWidth
                size='small'
                multiline
                value={localLesson.summary || ''}
                onChange={(evt) => setLocalLesson(draft => {
                  draft!.summary = evt.target.value
                })}
              />
            </Grid>

          </Grid>
          <DraggableList
            items={localLesson.contentItems}
            onDragEnd={onDragEnd}
          >{(item: AnyItem,
            index: number,
            provided: DraggableProvided,
            snapshot: DraggableStateSnapshot) => {
            // @ts-ignore
            const type = actionsByTypeName[item.__typename + (item.category || '')]
            debug('Item', (item.__typename + (item.category || '')),type, item)
            return type ? (<ListItem
              onClick={() => onNavigate(type, item)}
              secondaryAction={(<>
                <IconButton onClick={(e) => {
                  e.stopPropagation();
                  return onDelete(index);
                }} edge='end'><IconDelete /></IconButton>
                <IconButton onClick={(e) => {
                  e.stopPropagation();
                  return onNavigate(type, item);
                }} edge='end'><IconGoTo /></IconButton>
              </>)
              }
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              sx={snapshot.isDragging ? { background: 'rgb(235,235,235)' } : {}}
            >
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: type.color }}>
                  {type.icon}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={(item.order + 1) + ' ' + item.name} secondary={item.id} />
            </ListItem>
            )
              : <ListItem ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              ><ListItemText>Unknown item</ListItemText></ListItem>
          }}</DraggableList>
        </Stack>
      </Paper>
      <div style={{ height: 100 }}></div>
      <SpeedDial
        ariaLabel="Create"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        icon={<IconSpeedDial />}

      >
        {actions.map((action) => (
          <SpeedDialAction
            onClick={() => onCreate(action)}
            key={action.name}
            icon={action.icon}
            sx={{ background: action.color, color: 'white', '&:hover': { background: '#bbd2f1', color: action.color } }}

            tooltipTitle={action.name}
          />
        ))}
      </SpeedDial></div>)
    : null;
}
