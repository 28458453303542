import { IconChevronLeft } from '@/icons';
import { Box, IconButton, Link, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink, useMatches, useNavigate } from 'react-router-dom';
import { RouteHandle, crumbTypographyStyle } from './types';

// Main BreadCrumbs component
export default function BreadCrumbs() {
  const matches = useMatches();
  const navigate = useNavigate();
  const [crumbWidths, setCrumbWidths] = React.useState<number[]>([]);
  const crumbRefs = React.useRef<(HTMLDivElement | null)[]>([]);
  const [updateCounter, setUpdateCounter] = React.useState(0);
  const [crumbTexts, setCrumbTexts] = React.useState<string[]>([]);
  const navigableMatches = matches.filter((match) => Boolean((match.handle as RouteHandle | undefined)?.crumb))

  const crumbs = navigableMatches.map((match) => ({
    crumb: (match.handle as RouteHandle).crumb(),
    path: match.pathname
  }));

  // Measure all crumbs when they change
  React.useLayoutEffect(() => {
    requestAnimationFrame(() => {
      const widths = crumbRefs.current
        .map(ref => ref?.scrollWidth || 0);
      
      // Only update if we have all measurements and they're different
      if (widths.length === crumbs.length && widths.some((w, i) => w !== crumbWidths[i])) {
        setCrumbWidths(widths);
      }
    });
  }, [crumbs.length, updateCounter]); // Re-measure when crumbs change or content updates

  const handleCrumbUpdate = React.useCallback((index: number, text: string) => {
    setCrumbTexts(prev => {
      const next = [...prev];
      next[index] = text;
      return next;
    });
    setUpdateCounter(c => c + 1);
  }, []);

  // Wrap each crumb with the update callback
  const wrappedCrumbs = React.useMemo(() => 
    crumbs.map((crumb, index) => ({
      ...crumb,
      crumb: React.cloneElement(crumb.crumb as React.ReactElement, {
        onUpdate: (text: string) => handleCrumbUpdate(index, text),
      })
    })), [crumbs, handleCrumbUpdate]);

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: 1, 
        color: 'grey.600',
        whiteSpace: 'nowrap',
        width: '100%',
        overflow: 'hidden',
        minWidth: 0,
        flexWrap: 'nowrap',
      }}
    >
      <IconButton 
        disabled={navigableMatches.length <= 1} 
        onClick={() => navigate(navigableMatches[navigableMatches.length - 2].pathname, {replace: true})}
      >
        <IconChevronLeft sx={{ color: 'primary.main' }} />
      </IconButton>
      {wrappedCrumbs.map((crumb, index) => (
        <Box 
          key={crumb.path} 
          ref={el => {
            crumbRefs.current[index] = el as HTMLDivElement;
          }}
          sx={{ 
            display: 'flex', 
            alignItems: 'center',
            flexShrink: crumbWidths.length === crumbs.length 
              ? crumbWidths[index] / crumbWidths.reduce((sum, w) => sum + w, 0)
              : 1,
            minWidth: index === 0 ? '30px' : '60px',
            overflow: 'hidden'
          }}
        >
          {index > 0 && (
            <Typography 
              sx={{ 
                mx: 1, 
                flexShrink: 0,
                flexGrow: 0,
                color: 'text.primary'
              }}
            >
              /
            </Typography>
          )}
          {index === wrappedCrumbs.length - 1 ? (
            <Tooltip title={crumbTexts[index] || ''} enterDelay={1000}>
              <Box sx={{ width: '100%', ...crumbTypographyStyle, color: 'primary.main' }}>
                {crumb.crumb}
              </Box>
            </Tooltip>
          ) : (
            <Tooltip title={crumbTexts[index] || ''} enterDelay={1000}>
              <Link
                component={RouterLink}
                to={crumb.path}
                color="inherit"
                underline="none"
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  width: '100%',
                  overflow: 'hidden',
                  '&:hover': {
                    color: 'primary.main'
                  }
                }}
              >
                {crumb.crumb}
              </Link>
            </Tooltip>
          )}
        </Box>
      ))}
    </Box>
  );
}
