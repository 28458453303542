import { Box, Button, IconButton, Stack } from "@mui/material";
import { RoleDescriptor } from "../RoleDescriptor";
import { RoleDescription } from "../../types";
import { IconDelete } from "@/icons";

interface GlobalRoleTabProps {
  roles: Readonly<RoleDescription[]>;
  deleteRole: () => void;
}

export function GlobalRoleTab({ roles, deleteRole }: GlobalRoleTabProps) {
  return (
    <Box display="flex" flexDirection="column" gap={2} sx={{ width: "100%", alignItems: "stretch" }}>
      {roles.map((role, index) => (
          <RoleDescriptor key={index} {...role} />
      ))}
      <Stack direction="row" spacing={2} sx={{ justifyContent: "flex-end" }}>
        <Button
          variant="outlined"
          color="error"
          size="small"
          startIcon={<IconDelete fontSize="small" />}
          onClick={() => deleteRole()}
        >
          Remove Role
        </Button>
      </Stack>
    </Box>
  );
}
