import { generateDebug } from "@/utils";
import { TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";

const debug = generateDebug("NameDialog");

interface NameDialogProps {
  entityType: string;
  content?: string | React.ReactNode;
  title?: string;
  name?: string;
  disabled: boolean;
  handler?: (name: string | null) => void;
  namesInUse?: string[];
  allowCurrentName?: boolean;
}

export function NameDialog({
  entityType,
  disabled,
  handler,
  name,
  namesInUse,
  title,
  allowCurrentName = true,
  content,
}: NameDialogProps) {
  const [newName, setNewName] = React.useState(name || "");
  React.useEffect(() => setNewName(name || ""), [name, handler]);
  const handleClose = React.useCallback(() => {
    handler?.(null);
  }, [handler]);
  const message = React.useMemo(() => {
    const n = newName.trim();
    if (!n) {
      return null;
    }
    if (
      namesInUse &&
      (namesInUse.includes(n) || (!allowCurrentName && n === name))
    ) {
      return "Name already taken";
    }
  }, [newName, name]);
  return handler ? (
    <Dialog
      open
      onClose={disabled ? () => {} : handleClose}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <DialogTitle>{title || "Name " + entityType}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ p: 1, border: "1px solid", borderRadius: 0, borderColor: "grey.200" }}>
          {content || (
            <Typography variant="body2">
              Enter name for {entityType}.{namesInUse ? " Must be unique" : ""}
            </Typography>
          )}
        </DialogContentText>
        <Box sx={{ mt: 2 }}>
          <TextField
            disabled={disabled}
            autoFocus
            fullWidth
            autoComplete="off"
            label={entityType + " name"}
            margin="dense"
            name="entityName"
            type="text"
            required
            value={newName}
            error={!!message}
            helperText={message}
            onChange={(evt) => setNewName(evt.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" sx={{ backgroundColor: "grey.500" }} disabled={disabled} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={disabled || !!message || !newName.trim().length}
          onClick={() => handler(newName)}
        >
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
}
