import { useModified } from "@/context/ModifiedProvider";
import { useLocalModificationBar } from "@/hooks/use-local-modification-bar";
import { Box, Button, Drawer, Paper } from "@mui/material";
import * as React from "react";
import { Outlet, useMatches, useNavigate } from "react-router-dom";
import { DebugView } from "./DebugView";
import { generateDebug } from "@/utils";
const DRAWER_WIDTH = 526;
const debug = generateDebug("DrawerLayout");
export interface DrawerLayoutProps {
  children: React.ReactNode;
  /**
   * The ID of the route that this layout handles.
   * When a route after this ID is matched, the drawer will be shown.
   */
  routeId: string;
}

export default function DrawerLayout({ children, routeId }: DrawerLayoutProps) {
  const matches = useMatches();
  const layoutIndex = matches.findIndex((m) => m.id === routeId);
  const hasDrawer = layoutIndex >= 0 && layoutIndex < matches.length - 1;
  const {isModified, handlers} = useModified();
  const navigate = useNavigate();
  const paperRef = React.useRef<HTMLDivElement>(null);
  const [paperHeight, setPaperHeight] = React.useState(0);
  debug(layoutIndex, hasDrawer, routeId, matches)
  React.useEffect(() => {
    if (paperRef.current) {
      setPaperHeight(paperRef.current.getBoundingClientRect().height);
    }
  }, []);
  // notify we're using local modification bar
  useLocalModificationBar();
  return (
    <Box sx={{ display: "flex" }}>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: "100%",
        }}
      >
        {children}
      </Box>
      <Drawer
        variant="temporary"
        anchor="right"
        open={hasDrawer}
        hideBackdrop
        onClose={(event, reason) => {
          if (reason === "escapeKeyDown") {
            navigate(".");
          }
        }}
        sx={{
          width: DRAWER_WIDTH,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: DRAWER_WIDTH,
            boxSizing: "border-box",
          },
        }}
      >
        <Box
          sx={{
            mt: 0,
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
            {/* <DebugView>{JSON.stringify({modified, paperHeight})}</DebugView> */}
          <Box
            sx={{
              flex: 1,
              overflow: "auto",
              position: "relative",
            }}
          >
            <Outlet />
          </Box>
          <Box
            sx={{
              height: isModified ? Math.max(paperHeight, 64) : 0,
              overflow: "hidden",
              transition: "height 225ms cubic-bezier(0.4, 0, 0.2, 1)",
            }}
          >
            <Paper
              id='drawer-layout-footer'
              ref={paperRef}
              sx={{
                p: 2,
                px: 3,
                display: "flex",
                justifyContent: "space-between",
                gap: 2,
                borderTop: 1,
                borderColor: "divider",
              
              }}
              elevation={0}
            >
              <Button
                disabled={!handlers.reset}
                onClick={handlers.reset}
                color="error"
                size="small"
                variant="outlined"
              >
                {handlers.resetCaption || "Reset"}
              </Button>
              <Button
                disabled={!handlers.save}
                onClick={handlers.save}
                color="success"
                size="small"
                variant="contained"
              >
                {handlers.saveCaption || "Save"}
              </Button>
            </Paper>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
