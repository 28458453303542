import { ShiroCan } from "@/auth/components/Can";
import { NameDialog } from "@/components/NameDialog";
import { useCurrentOrganization } from "@/context/OrganizationProvider";
import { useOrganizations } from "@/context/OrganizationsProvider";
import {
  useCreateOrganizationMutation
} from "@graphql/organizations-hook";
import {
  Container
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useWrapperHook } from "../structure/use-wrapper-hook";
import OrganizationCard from "./components/OrganizationCard";
import { ListOrganization } from "./types";

const NoOp = () => {};

interface OrganizationListViewProps {}
const NewOrganization: ListOrganization = {
  id: "new",
  name: "Create New Organization",
  courseCount: 0,
  __typename: "JrnyOrganization",
};
export default function OrganizationListView(props: OrganizationListViewProps) {
  const [{ fetching: isCreating }, doCreateOrganization] = useCreateOrganizationMutation();
  const { currentOrganization } = useCurrentOrganization();
  const { organizations } = useOrganizations();
  const navigate = useNavigate();
  const [nameHandler, setNameHandler] = useState<undefined | { handler: (name: string | null) => void }>();

  const createOrganization = useWrapperHook(
    (name: string) => doCreateOrganization({ input: { name } }),
    "Create organization",
    NoOp
  );

  const handleCreateOrganization = () => {
    setNameHandler({
      handler: async (name: string | null) => {
        if (name) {
          await createOrganization(name);
        }
        setNameHandler(undefined);
      },
    });
  };

  return (
    <Container>
      <Grid container spacing={3}>
        <ShiroCan permission="journey-admin:organization:create">
          <Grid size={{ md: 12, lg: 6 }}>
            <OrganizationCard
              isNew
              organization={NewOrganization}
              isSelected={false}
              onClick={handleCreateOrganization}
            />
          </Grid>
        </ShiroCan>

        {organizations.sort((a, b) => a.name.localeCompare(b.name)).map((organization, index) => (
          <Grid size={{ md: 12, lg: 6 }} key={index}>
            <OrganizationCard
              organization={organization}
              isSelected={currentOrganization?.id === organization.id}
              onClick={() => navigate(`/organization/${organization.id}`)}
            />
          </Grid>
        ))}
      </Grid>
      <NameDialog
        entityType="Organization"
        disabled={isCreating}
        handler={nameHandler?.handler}
        name="New Organization"
        title="Create new organization"
        content="Enter name of organization. Must be unique"
        namesInUse={organizations.map(org => org.name)}
      />
    </Container>
  );
}
