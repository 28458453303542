import { PromptInputNode } from "@/pages/prompt/extensions/prompt-input.extension";
import { PromptInput } from "@journey-lti-tool/common";
import {
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  styled,
} from "@mui/material";
import { EditorContent, useEditor } from "@tiptap/react";
import { StarterKit } from "@tiptap/starter-kit";
import * as React from "react";
import { MenuBar } from "@components/MenuBar";
import { Link } from "@tiptap/extension-link";

const EditorWrapper = styled(FormControl, {
  name: "MuiTextField",
  slot: "Root",
  overridesResolver: (props, styles) => styles.root,
})(
  (
    { theme } //language=scss prefix=.root{ suffix=}
  ) =>
    `
  
    & .ProseMirror span[data-prompt-ix] {
      min-width: 5em;
      background: ${theme.palette.secondary.light};
      border: #f00;
      height: 1.2em;
      line-height: 1.2em;
      border-radius: 1.2em;
      display: inline-block;
      margin: 0 .1em -0.1em;
      text-align: center;
      font-size: .9em;

      &:after {
        content: attr(data-prompt-ix);
      }
    }
  `
);

export interface PromptDescriptionEditorProps {
  onChange: (content: string) => void;
  content: string;
  inputs: PromptInput<any>[];
}

export default function PromptDescriptionEditor(
  props: PromptDescriptionEditorProps
) {
  const editor = useEditor(
    {
      extensions: [
        StarterKit,
        PromptInputNode,
        Link.configure({
          autolink: true,
          linkOnPaste: true,
          openOnClick: false,
          HTMLAttributes: {
            target: "_blank",
          },
        }),
      ],
      onUpdate({ editor }) {
        props.onChange(editor.getHTML());
      },
      content: props.content,
    },
    [props.onChange]
  );
  const content = React.useMemo(
    () =>
      React.forwardRef((pr, ref) => (
        <Box sx={{ p: 2 }}>
          <MenuBar editor={editor} inputs={props.inputs} />
          <EditorContent editor={editor} />
        </Box>
      )),
    [editor, props.inputs]
  );
  return editor ? (
    <>
      <EditorWrapper variant="outlined" fullWidth sx={{}}>
        <InputLabel
          htmlFor="text-editor"
          id="text-editor-label"
          shrink
        >
          Description
        </InputLabel>
        {/* TODO: Create a proper outlined version of this*/}
        <OutlinedInput
          notched={true}
          label="Description"
          id="text-editor"
          sx={{ '& .MuiOutlinedInput-notchedOutline': { border: "2px solid var(--mui-palette-secondary-500)"  }   }}
          inputComponent={content as any}
        />
      </EditorWrapper>
    </>
  ) : null;
}
