import { Typography } from '@mui/material';
import React from 'react';
import { CrumbProps, crumbTypographyStyle } from './types';

interface StandardCrumbProps extends CrumbProps {
  name: string;
}

export const StandardCrumb = ({ name, onUpdate }: StandardCrumbProps) => {
  React.useEffect(() => {
    onUpdate?.(name);
  }, [name]);

  return (
    <Typography component="span" color="inherit" sx={crumbTypographyStyle}>
      {name}
    </Typography>
  );
}; 