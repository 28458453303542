import FuturaPTBold from '@/assets/fonts/FuturaPTBold.woff2';
import FuturaPTBoldOblique from '@/assets/fonts/FuturaPTBoldOblique.woff2';
import FuturaPT from '@/assets/fonts/FuturaPTDemi.woff2';
import FuturaPTOblique from '@/assets/fonts/FuturaPTDemiOblique.woff2';
import BkgImage from '@/assets/journey-bg-bw.jpg';
import { ThemeOptions } from '@mui/material';

export const themeOptions: ThemeOptions = {

  typography: {
    fontFamily: 'FuturaPT',
    allVariants: {
      letterSpacing: 0.03
    },
    h1: {
      fontSize: 24,
    },
    h2: {
      fontSize: 18,
    },
    h3: {
      fontSize: 16,
    },
    body1: {
      fontSize: 14,
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#40B777',
    },
    secondary: {
      main: '#F8ACFF',
    },
    contrast: {
      main: '#42368E',
    },
    info: {
      main: '#40ADE2',
    },
    background: {
      paper: 'rgba(0,0,0,0.5)',
      menu: 'rgba(51,51,51,.9)', // Background used in menus
      menuGradient: 'linear-gradient(180deg, rgba(5, 4, 11, 0.75) 0%, rgba(66, 54, 142, 0.75) 41.67%, rgba(248, 172, 255, 0.75) 100%)', // Background used in menus
      default: '#42368E',
      activeElement: 'linear-gradient(0deg, rgba(64, 183, 119, 0.15) 0%, #F8ACFF 100%)'
    },
    divider: 'rgba(255,255,255,0.49)',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'FuturaPT';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Futura PT Demi'), url('${FuturaPT}') format('woff2');
        }
        @font-face {
          font-family: 'FuturaPTBold';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Futura PT Bold'), url('${FuturaPTBold}') format('woff2');
        }
        @font-face {
          font-family: 'FuturaPTOblique';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Futura PT Demi Oblique'), url('${FuturaPTOblique}') format('woff2');
        }
        @font-face {
          font-family: 'FuturaPTBoldOblique';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Futura PT Extra Bold Oblique'), url('${FuturaPTBoldOblique}') format('woff2');
        }
      `,
    },
    MuiLink: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: 'white'
          }
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: ({theme}) => ({
          '&:after': {
            content:'""',
            opacity:.7,
            ...(theme.journeyBackground.lesson),
          }
        }),
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: ({theme}) => ({

          background: 'rgba(0, 0, 0, 0.6)'

        })

      }
    },

    MuiButton: {
      styleOverrides: {
        root: {
          background:
            'linear-gradient(263.33deg, #362C78 0.57%, #328EBB 53.05%, #40B777 102.35%)',
          border: '1.5px #b3b3b3 solid',
          borderRadius: '15px',
          boxShadow: '0px 4px 10px 2px rgba(64, 173, 226, 0.35)',
          color: 'white',
          height: 36,
          padding: '8px 16px',
          '&:hover': {
            borderColor: 'inherit',
            boxShadow: '0px 4px 20px 3px #40ADE2'
          },
          '&.Mui-disabled': {
            background:
              'linear-gradient(263.33deg, #362C78/80% 0.57%, #328EBB/80% 53.05%, #40B777/80% 102.35%)',
          }

        },
      },
    },
  },
  shape: {
    borderRadius: 16,
  },
  journeyBackground: {
    default: {
      backgroundImage:
        'linear-gradient(134deg, rgba(255, 243, 0, .14) 0%, rgba(0, 195, 255, 0.2) 50%, rgba(79, 0, 255, 0.34) 100%),' +
        'linear-gradient(202deg, rgba(40, 254, 254, 1) 0%, rgba(0, 113, 184, 1) 23%, rgba(180, 49, 171, 0.5) 60%, rgba(176, 57, 146, 1) 100%),' +
        `url(${BkgImage})`,
      backgroundSize: 'cover, cover, cover',
      backgroundBlendMode: 'color, color, normal',
    },
    lesson: {
      backgroundImage:
        'linear-gradient(180deg, rgba(5, 4, 11, 0.6) 0%, rgba(66, 54, 142, 0.6) 41.67%, rgba(248, 172, 255, 0.6) 100%),' +
        'linear-gradient(202deg, rgba(40, 254, 254, 1) 0%, rgba(0, 113, 184, 1) 23%, rgba(180, 49, 171, 0.5) 60%, rgba(176, 57, 146, 1) 100%),' +
        `url(${BkgImage})`,
      backgroundSize: '100vw 200vh, cover, cover',
      backgroundBlendMode: 'normal, color, normal',
    },
  },
};

