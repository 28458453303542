import { Role } from '../types';

export const stripUnnecessaryFields = (roles: Role[]): Role[] => {
  return roles.map(role => {
    const stripped: Role = {
      scope: role.scope,
      roleIds: role.roleIds,
    };

    switch (role.scope) {
      case 'global':
        return stripped;
      case 'organization':
        return {
          ...stripped,
          organizationId: role.organizationId,
        };
      case 'course':
        return {
          ...stripped,
          organizationId: role.organizationId,
          courseId: role.courseId,
        };
    }
  });
}; 