import { useMemo, useState } from "react";
import { Box, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router-dom";
import { useCurrentOrganization } from "@/context/OrganizationProvider";
import CourseCard from "./components/CourseCard";
import { Course } from "./types";
import { useCurrentCourse } from "@/context/CourseProvider";
import {
  useCreateCourseMutation,
  useCloneCourseMutation,
  useDeleteCourseMutation,
  useUpdateCourseMutation,
} from "@/graphql/hooks/courses-hook";
import { useWrapperHook } from "../structure/use-wrapper-hook";
import { DeleteDialog } from "@/components/DeleteDialog";
import { NameDialog } from "@/components/NameDialog";

const NoOp = () => {};
export const CourseListView = () => {
  const navigate = useNavigate();
  const { currentOrganization } = useCurrentOrganization();
  const { currentCourse } = useCurrentCourse();
  const [entityName, setEntityName] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContent, setDialogContent] = useState<string | React.ReactNode>("");
  const [nameHandler, setNameHandler] = useState<
    undefined | { handler: (name: string | null) => void }
  >();
  const [confirmDeleteHandler, setConfirmDeleteHandler] = useState<
    undefined | { handler: (remove: boolean) => void }
  >();

  const [{ fetching: isCreating }, doCreateCourse] = useCreateCourseMutation();
  const [{ fetching: isCloning }, doCloneCourse] = useCloneCourseMutation();
  const [{ fetching: isDeleting }, doRemoveCourse] = useDeleteCourseMutation();
  const [_, updateCourse] = useUpdateCourseMutation();

  const isSaving = useMemo(() => {
    return isCreating || isCloning || isDeleting;
  }, [isCreating, isCloning, isDeleting]);
  const removeCourse = useWrapperHook(
    (id: string) => doRemoveCourse({ id }),
    "Remove course",
    NoOp
  );
  const cloneCourse = useWrapperHook(
    async (id: string, name: string) =>
      doCloneCourse({
        input: {
          id,
          name,
        },
      }),
    "Clone course",
    NoOp
  );
  const createCourse = useWrapperHook(
    (organizationId: string, name: string) =>
      doCreateCourse({
        input: {
          name,
          organizationId,
        },
      }),
    "Create course",
    NoOp
  );

  const handleCourseClick = (course: Course) => {
    navigate(`./course/${course.id}`);
  };

  const handleCreateCourse = () => {
    setDialogTitle("Confirm new course");
    setDialogContent("Enter name for course");
    setEntityName("New Course");
    setNameHandler({
      handler: (name: string | null) => {
        if (name) {
          createCourse(currentOrganization!.id, name);
        }
        setNameHandler(undefined);
      },
    });
  };

  const handleActivate = (course: Course) => {
    updateCourse({ input: { id: course.id, available: !course.available } });
  };

  const handleDuplicate = (course: Course) => {
    setDialogTitle("Confirm duplicate course");
    setDialogContent(
      <>
        <Typography variant="inherit" marginBottom={2}>Duplicating a course will create a copy of all course content, including activities, media, and course info. </Typography>
        <Typography variant="inherit" marginBottom={2}>Users and course admins will not be carried over, and must be assigned as new.</Typography>
        <Typography variant="inherit">Enter name for the new course</Typography>
      </>
    );

    setEntityName(course.name + " (Copy)");
    setNameHandler({
      handler: (name: string | null) => {
        if (name) {
          cloneCourse(course.id, name);
        }
        setNameHandler(undefined);
      },
    });
  };

  const handleDelete = (course: Course) => {
    setEntityName(course.name);
    setConfirmDeleteHandler({
      handler: (remove: boolean) => {
        if (remove) {
          removeCourse(course.id);
        }
        setConfirmDeleteHandler(undefined);
      },
    });
  };

  if (!currentOrganization) {
    return null;
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ mb: 4 }}>
        <Grid container spacing={3} justifyContent="stretch">
          <Grid size={{ xs: 12, sm: 6, md: 4 }}>
            <CourseCard isNew onClick={handleCreateCourse} />
          </Grid>

          {currentOrganization.courses?.map((course) => (
            <Grid size={{ xs: 12, sm: 6, md: 4 }} key={course.id}>
              <CourseCard
                course={course}
                isSelected={currentCourse?.id === course.id}
                onClick={() => handleCourseClick(course)}
                onActivate={() => handleActivate(course)}
                onDuplicate={() => handleDuplicate(course)}
                onDelete={() => handleDelete(course)}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <NameDialog
        entityType={"Course"}
        disabled={isSaving}
        handler={nameHandler?.handler}
        name={entityName}
        title={dialogTitle}
        content={dialogContent}
      />
      <DeleteDialog
        entityType={"Course"}
        disabled={isSaving}
        handler={confirmDeleteHandler?.handler}
      />
    </Container>
  );
};

export default CourseListView;
