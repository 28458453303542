import { useAssets } from "@/context/AssetsProvider";
import { IGQLJrnyMediaType, IGQLJrnyUpdateMediaInput } from "@/graphql-types";
import { useLocalChange } from "@/hooks/use-local-change";
import { IconPhoto, IconVideo } from "@/icons";
import AspectRatio from "@components/file-object/AspectRatio";
import { MinimalFileObject } from "@components/file-object/types";
import {
  IGQLMediaQuery,
  useMediaQuery,
  useUpdateMediaMutation,
} from "@graphql/videos-hook";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import * as React from "react";
import { useParams } from "react-router-dom";
import MediaDescriptionEditor from "./components/MediaDescriptionEditor";

interface MediaDetailsViewProps {}
type Local = IGQLMediaQuery["jrnyMedia"] & { iframe: string };
type Original = IGQLMediaQuery["jrnyMedia"];
function htmlToJson(htmlString: string) {
  if (!htmlString) {
    return null;
  }
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  const iframe = doc.querySelector("iframe");
  const json: Record<string, string> = {};

  if (iframe) {
    for (let i = 0; i < iframe.attributes.length; i++) {
      const attr = iframe.attributes[i];
      json[attr.name] = attr.value;
    }
  }

  return json;
}
function jsonToHtml(json: Record<string, string>) {
  if (!json) {
    return "";
  }
  const iframe = document.createElement("iframe");

  for (const key in json) {
    if (json.hasOwnProperty(key)) {
      iframe.setAttribute(key, json[key]);
    }
  }

  return iframe.outerHTML;
}
export default function MediaDetailsView(props: MediaDetailsViewProps) {
  const { mediaId } = useParams();
  const { showFileChooser } = useAssets();
  const [showControls, setShowControls] = React.useState(false);
  const [video] = useMediaQuery({
    variables: { id: mediaId || "" },
    pause: !mediaId,
  });
  const [_, updateMedia] = useUpdateMediaMutation();
  const [localMedia, setLocalMedia] = useLocalChange<Original, Local>(
    video.data?.jrnyMedia,
    (original, local) => {
      const input: IGQLJrnyUpdateMediaInput = {
        id: original.id,
        mediaType: local.mediaType,
        imageFileId: local.image?.id || null,
        videoFileId: local.video?.id || null,
        iframe: htmlToJson(local.iframe),
        name: local.name,
        duration: local.duration,
        description: local.description,
      };
      return updateMedia({ input });
    },
    (original) => {
      const json = jsonToHtml(original.iframe);
      return {
        ...original,
        iframe: json,
      };
    },
    (local) => {
      return {
        ...local,
        iframe: htmlToJson(local.iframe),
      };
    }
  );
  const setDescription = React.useCallback(
    (description: string) =>
      setLocalMedia((draft) => {
        draft!.description = description;
      }),
    []
  );

  const onSetImage = (fileObject: MinimalFileObject | null) => {
    setLocalMedia((draft) => {
      if (draft) {
        if (fileObject) {
          // @ts-ignore
          draft.image = fileObject;
        } else {
          draft.image = null;
        }
      }
    });
  };
  const onSetMedia = (fileObject: MinimalFileObject | null) => {
    setLocalMedia((draft) => {
      if (draft) {
        if (fileObject) {
          // @ts-ignore
          draft.video = fileObject;
          draft.duration = !!fileObject.metadata?.duration
            ? Math.max(1, Math.round(fileObject.metadata.duration / 60))
            : 0;
        } else {
          draft.video = null;
          draft.duration = 0;
        }
      }
    });
  };

  return localMedia ? (
    <>
      <Paper sx={{ p: 2 }}>
        <Grid container columnSpacing={1} rowSpacing={4}>
          <Grid size={8}>
            <TextField
              label="Name/Title"
              fullWidth
              size="small"
              value={localMedia.name}
              onChange={(evt) =>
                setLocalMedia((draft) => {
                  draft!.name = evt.target.value;
                })
              }
            />
          </Grid>
          <Grid size={2}>
            <TextField
              size="small"
              type={"number"}
              label="Estimated duration"
              value={localMedia.duration}
              onChange={(evt) =>
                setLocalMedia((draft) => {
                  draft!.duration = Math.max(
                    0,
                    Number.parseFloat(evt.target.value)
                  );
                })
              }
            />
          </Grid>
          <Grid
            size={{
              sm: 2
            }}>
            <FormControl fullWidth>
              <InputLabel id="type-label">Type</InputLabel>
              <Select
                labelId="type-label"
                label="Type"
                size="small"
                displayEmpty
                value={localMedia.mediaType}
                onChange={(evt) =>
                  setLocalMedia((draft) => {
                    draft!.mediaType = evt.target.value as IGQLJrnyMediaType;
                  })
                }
              >
                <MenuItem value={IGQLJrnyMediaType.Video}>Video</MenuItem>
                <MenuItem value={IGQLJrnyMediaType.Image}>Image</MenuItem>
                <MenuItem value={IGQLJrnyMediaType.Iframe}>iFrame</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid size={12}>
            <MediaDescriptionEditor
              onChange={setDescription}
              content={localMedia.description}
            />
          </Grid>
          {localMedia.mediaType == IGQLJrnyMediaType.Video && (
            <Grid size={6}>
              <Box
                onMouseEnter={() => setShowControls(true)}
                onMouseLeave={() => setShowControls(false)}
              >
                <AspectRatio ratio="1.7" objectFit="contain">
                  {localMedia.video ? (
                    <video
                      style={{ maxWidth: "100%" }}
                      src={localMedia.video.url}
                      controls={showControls}
                    />
                  ) : (
                    <Box sx={{ maxWidth: "100%", bgcolor: "#aaa" }}>
                      <IconVideo sx={{ fontSize: "10rem", fill: "#444" }} />
                    </Box>
                  )}
                </AspectRatio>
              </Box>
              <Button
                onClick={() =>
                  showFileChooser({ type: "video", onSelect: onSetMedia })
                }
              >
                Choose video
              </Button>
            </Grid>
          )}
          {(localMedia.mediaType == IGQLJrnyMediaType.Video ||
            localMedia.mediaType == IGQLJrnyMediaType.Image) && (
            <Grid size={6}>
              <Box>
                <AspectRatio ratio="1.7" objectFit="contain">
                  {localMedia.image ? (
                    <img src={localMedia.image.url} />
                  ) : (
                    <Box sx={{ maxWidth: "100%", bgcolor: "#aaa" }}>
                      <IconPhoto sx={{ fontSize: "10rem", fill: "#444" }} />
                    </Box>
                  )}
                </AspectRatio>
              </Box>
              <Button
                onClick={() =>
                  showFileChooser({ type: "image", onSelect: onSetImage })
                }
              >
                Choose{" "}
                {localMedia.mediaType == IGQLJrnyMediaType.Video
                  ? "poster image"
                  : "image"}
              </Button>
            </Grid>
          )}
          {localMedia.mediaType == IGQLJrnyMediaType.Iframe && (
            <Grid size={12}>
              <TextField
                label="IFRAME"
                fullWidth
                size="small"
                value={localMedia.iframe}
                multiline
                onChange={(evt) =>
                  setLocalMedia((draft) => {
                    draft!.iframe = evt.target.value;
                  })
                }
              />
            </Grid>
          )}
        </Grid>
      </Paper>
      {/*<DebugView>{JSON.stringify(localMedia, null, 2)}</DebugView>*/}
    </>
  ) : null;
}
