import { Typography } from '@mui/material';
import React from 'react';
import { useCurrentCourse } from '@/context/CourseProvider';
import { CrumbProps, crumbTypographyStyle } from './types';

export const CourseCrumb = ({ onUpdate, sx }: CrumbProps) => {
  const { currentCourse, fetching } = useCurrentCourse();
  const text = fetching ? 'Loading...' : (currentCourse?.name || 'Course');
  
  React.useEffect(() => {
    onUpdate?.(currentCourse?.name || '');
  }, [currentCourse?.name, fetching]);

  return (
    <Typography component="span" color="inherit" sx={crumbTypographyStyle}>
      {text}
    </Typography>
  );
}; 