import {useRequestResetPasswordMutation} from '@graphql/password-hook';
import * as React from 'react';
import {Field, FieldRenderProps, Form, FormSpy} from 'react-final-form';
import Box from '@mui/material/Box';
import Typography from '@components/Typography';
import AppAppBar from '@components/AppAppBar';
import AppForm from '@components/AppForm';
import {email, required} from '@components/form/validation';
import RFTextField from '@components/form/RFTextField';
import FormButton from '@components/form/FormButton';
import FormFeedback from '@components/form/FormFeedback';

function ForgotPassword() {
  const [, requestResetPassword] = useRequestResetPasswordMutation()
  const [requested, setRequested] = React.useState(false);


  const validate = (values: { [index: string]: string }) => {
    const errors = required(['email'], values);

    if (!errors.email) {
      const emailError = email(values.email);
      if (emailError) {
        errors.email = emailError;
      }
    }

    return errors;
  };

  const handleSubmit = async (values: { email: string }) => {
    await requestResetPassword({input: {email: values.email, source: 'webadmin'}});
    setRequested(true)
  };

  return (
    <React.Fragment>
      <AppAppBar/>{!requested &&
      (<AppForm>
          <React.Fragment>
            <Typography variant="h3" gutterBottom marked="center" align="center">
              Forgot your password?
            </Typography>
            <Typography variant="body2" align="center">
              {"Enter your email address below and we'll " +
                'send you a link to reset your password.'}
            </Typography>
          </React.Fragment>
          <Form
            onSubmit={handleSubmit}
            subscription={{submitting: true}}
            validate={validate}
          >
            {({handleSubmit: handleSubmit2, submitting}) => (
              <Box component="form" onSubmit={handleSubmit2} noValidate sx={{mt: 6}}>
                <Field
                  autoFocus
                  autoComplete="email"
                  component={RFTextField}
                  disabled={submitting}
                  fullWidth
                  label="Email"
                  margin="normal"
                  name="email"
                  required
                  size="large"
                />
                <FormSpy subscription={{submitError: true}}>
                  {({submitError}) =>
                    submitError ? (
                      <FormFeedback error sx={{mt: 2}}>
                        {submitError}
                      </FormFeedback>
                    ) : null
                  }
                </FormSpy>
                <FormButton
                  sx={{mt: 3, mb: 2}}
                  disabled={submitting}
                  size="large"
                  color="secondary"
                  fullWidth
                >
                  {submitting ? 'In progress…' : 'Send reset link'}
                </FormButton>
              </Box>
            )}
          </Form>
        </AppForm>
      ) || (
        <AppForm>
          <React.Fragment>
            <Typography variant="h3" gutterBottom marked="center" align="center">
              Password reset requested
            </Typography>
            <Typography variant="body2" align="center">
              {"Check your email for instruction " }
            </Typography>
          </React.Fragment>
        </AppForm>

      )}
    </React.Fragment>
  );
}

export default ForgotPassword;
