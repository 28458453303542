import * as Types from '../../graphql-types';

import { gql } from 'urql';
import { PerkDetailsFragmentDoc } from './fragment-Perk-hook';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IGQLPerksQueryVariables = Types.Exact<{
  courseId: Types.Scalars['ID']['input'];
}>;


export type IGQLPerksQuery = { __typename: 'Query', jrnyPerks: Array<{ __typename: 'JrnyPerk', id: string, title: string, price: number, created: any, organizationId: string, courseId: string, providerEmail: string, managerEmail: string, description: string, visible: boolean, isOutOfStock: boolean, inStockCount: number, image?: { __typename: 'FileObject', id: string, type?: string | null, name?: string | null, originalName: string, contentType: string, size: number, url: string, folderId: string, metadata?: any | null, created: any } | null }> };

export type IGQLPerkQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type IGQLPerkQuery = { __typename: 'Query', jrnyPerk: { __typename: 'JrnyPerk', id: string, title: string, price: number, created: any, organizationId: string, courseId: string, providerEmail: string, managerEmail: string, description: string, visible: boolean, isOutOfStock: boolean, inStockCount: number, purchases: Array<{ __typename: 'JrnyPerkPurchase', id: string, price: number, courseId: string, buyer: { __typename: 'JrnyPerkBuyer', id: string, isTeam: boolean, name: string, email?: string | null } }>, image?: { __typename: 'FileObject', id: string, type?: string | null, name?: string | null, originalName: string, contentType: string, size: number, url: string, folderId: string, metadata?: any | null, created: any } | null } };

export type IGQLClonePerkMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyClonePerkInput;
}>;


export type IGQLClonePerkMutation = { __typename: 'Mutation', jrnyClonePerk: { __typename: 'JrnyPerk', id: string, title: string, price: number, created: any, organizationId: string, courseId: string, providerEmail: string, managerEmail: string, description: string, visible: boolean, isOutOfStock: boolean, inStockCount: number, image?: { __typename: 'FileObject', id: string, type?: string | null, name?: string | null, originalName: string, contentType: string, size: number, url: string, folderId: string, metadata?: any | null, created: any } | null } };

export type IGQLCreatePerkMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyCreatePerkInput;
}>;


export type IGQLCreatePerkMutation = { __typename: 'Mutation', jrnyCreatePerk: { __typename: 'JrnyPerk', id: string, title: string, price: number, created: any, organizationId: string, courseId: string, providerEmail: string, managerEmail: string, description: string, visible: boolean, isOutOfStock: boolean, inStockCount: number, image?: { __typename: 'FileObject', id: string, type?: string | null, name?: string | null, originalName: string, contentType: string, size: number, url: string, folderId: string, metadata?: any | null, created: any } | null } };

export type IGQLUpdatePerkMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyUpdatePerkInput;
}>;


export type IGQLUpdatePerkMutation = { __typename: 'Mutation', jrnyUpdatePerk: { __typename: 'JrnyPerk', id: string, title: string, price: number, created: any, organizationId: string, courseId: string, providerEmail: string, managerEmail: string, description: string, visible: boolean, isOutOfStock: boolean, inStockCount: number, image?: { __typename: 'FileObject', id: string, type?: string | null, name?: string | null, originalName: string, contentType: string, size: number, url: string, folderId: string, metadata?: any | null, created: any } | null } };

export type IGQLRefundPerkPurchaseMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyRefundPerkPurchaseInput;
}>;


export type IGQLRefundPerkPurchaseMutation = { __typename: 'Mutation', jrnyRefundPerkPurchase: { __typename: 'JrnyPerk', purchases: Array<{ __typename: 'JrnyPerkPurchase', id: string, price: number, courseId: string, buyer: { __typename: 'JrnyPerkBuyer', id: string, isTeam: boolean, name: string, email?: string | null } }> } };


export const PerksDocument = gql`
    query perks($courseId: ID!) {
  jrnyPerks(courseId: $courseId) {
    ...PerkDetails
  }
}
    ${PerkDetailsFragmentDoc}`;

export function usePerksQuery(options: Omit<Urql.UseQueryArgs<IGQLPerksQueryVariables>, 'query'>) {
  return Urql.useQuery<IGQLPerksQuery, IGQLPerksQueryVariables>({ query: PerksDocument, ...options });
};
export const PerkDocument = gql`
    query perk($id: ID!) {
  jrnyPerk(id: $id) {
    ...PerkDetails
    purchases {
      id
      price
      courseId
      buyer {
        id
        isTeam
        name
        email
      }
    }
  }
}
    ${PerkDetailsFragmentDoc}`;

export function usePerkQuery(options: Omit<Urql.UseQueryArgs<IGQLPerkQueryVariables>, 'query'>) {
  return Urql.useQuery<IGQLPerkQuery, IGQLPerkQueryVariables>({ query: PerkDocument, ...options });
};
export const ClonePerkDocument = gql`
    mutation clonePerk($input: JrnyClonePerkInput!) {
  jrnyClonePerk(input: $input) {
    ...PerkDetails
  }
}
    ${PerkDetailsFragmentDoc}`;

export function useClonePerkMutation() {
  return Urql.useMutation<IGQLClonePerkMutation, IGQLClonePerkMutationVariables>(ClonePerkDocument);
};
export const CreatePerkDocument = gql`
    mutation createPerk($input: JrnyCreatePerkInput!) {
  jrnyCreatePerk(input: $input) {
    ...PerkDetails
  }
}
    ${PerkDetailsFragmentDoc}`;

export function useCreatePerkMutation() {
  return Urql.useMutation<IGQLCreatePerkMutation, IGQLCreatePerkMutationVariables>(CreatePerkDocument);
};
export const UpdatePerkDocument = gql`
    mutation updatePerk($input: JrnyUpdatePerkInput!) {
  jrnyUpdatePerk(input: $input) {
    ...PerkDetails
  }
}
    ${PerkDetailsFragmentDoc}`;

export function useUpdatePerkMutation() {
  return Urql.useMutation<IGQLUpdatePerkMutation, IGQLUpdatePerkMutationVariables>(UpdatePerkDocument);
};
export const RefundPerkPurchaseDocument = gql`
    mutation refundPerkPurchase($input: JrnyRefundPerkPurchaseInput!) {
  jrnyRefundPerkPurchase(input: $input) {
    purchases {
      id
      price
      courseId
      buyer {
        id
        isTeam
        name
        email
      }
    }
  }
}
    `;

export function useRefundPerkPurchaseMutation() {
  return Urql.useMutation<IGQLRefundPerkPurchaseMutation, IGQLRefundPerkPurchaseMutationVariables>(RefundPerkPurchaseDocument);
};