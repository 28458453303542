import { OptionInputCard } from "@/pages/prompt/components/input-cards/OptionInputCard";
import { TextInputCard } from "@/pages/prompt/components/input-cards/TextInputCard";
import { UploadInputCard } from "@/pages/prompt/components/input-cards/UploadInputCard";
import { PromptInputCardProps } from "@/pages/prompt/components/types";
import { generateDebug } from "@/utils";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import { IconChoice, IconDelete, IconText, IconUpload } from "@/icons";

const debug = generateDebug("PromptInputListItem");

export function GradingInputCard(props: PromptInputCardProps<any, any>) {
  return <div />;
}

export default function PromptInputListItem(
  {index, ...props}: PromptInputCardProps<any, any> & {index: number}
) {
  const [element, Icon, title] = React.useMemo(() => {
    debug("Generating input card", props.promptInput);
    switch (props.promptInput.type) {
      case "option":
        return [<OptionInputCard {...props} />, IconChoice, "Option input"];
      case "text":
        return [<TextInputCard {...props} />, IconText, "Text input"];
      // case 'grading':
      //   return [<GradingInputCard {...props} />, GradingIcon, 'Grading input']
      case "upload":
        return [<UploadInputCard {...props} />, IconUpload, "Upload input"];
      default:
        throw new Error("Unknown type: " + props.promptInput.type);
    }
  }, [props.promptInput, props.onChange, props.onRemove]);
  return (
    <Card
      elevation={0}
      sx={{
        p: 0,
        my: 1,
        border: "2px solid var(--mui-palette-secondary-500)",
        bgcolor: "var(--mui-palette-secondary-0)",
        '& .MuiFormControl-root': {
          bgcolor: "white",
        }
      }}
      variant={"outlined"}
    >
      <CardHeader
        avatar={
          <Avatar>
            <Icon />
          </Avatar>
        }
        title={<>
        <Typography variant="h6" fontSize={'1.3em'}>{`INPUT ${index + 1}`}</Typography>
        <Typography variant="caption">{`${title}`}</Typography>
        </>}
        action={
          <IconButton onClick={() => props.onRemove(props.promptInput)}>
            <IconDelete sx={{ color: "#f00" }} />
          </IconButton>
        }
      />
      <CardContent>{element}</CardContent>
    </Card>
  );
}
