import { useCurrentCourse } from "@/context/CourseProvider";
import { generateDebug } from "@/utils";
import { LinearProgress, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";

const debug = generateDebug("CourseMain");

export function CourseMain() {
  const { courseId } = useParams();
  const { fetching, error, currentCourse, loadCourse } = useCurrentCourse();
  debug("fetching", fetching);
  debug("error", error);
  debug("currentCourse", currentCourse);
  useEffect(() => {
    if (courseId) {
      loadCourse(courseId);
    }
  }, [courseId]);
  return currentCourse ? (
    <Outlet />
  ) : fetching ? (
    <Stack direction="row" spacing={2}>
      <Stack>
        <Typography>Loading course</Typography>
      </Stack>
      <LinearProgress />
    </Stack>
  ) : error ? (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Stack>
        <Typography>Error loading course</Typography>
      </Stack>
      <Typography>{error}</Typography>
    </Stack>
  ) : (
    <Typography>Course not found</Typography>
  );
} 