import { generateDebug } from "@/utils";
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { IGQLCourseQuery, useCourseQuery } from "@/graphql/hooks/courses-hook";
import { Unpacked } from "@/types";

const debug = generateDebug("CourseProvider");
export type Course = Unpacked<IGQLCourseQuery['jrnyCourse']>
interface CourseContextType {
  currentCourse: Course | null;
  fetching: boolean;
  error: string | null;
  loadCourse: (courseId: string|null) => void;
}

export const CourseContext = createContext<CourseContextType>({
  currentCourse: null,
  fetching: false,
  error: null,
  loadCourse: () => {},
});

export function CourseProvider({ children }: PropsWithChildren) {
  const [courseId, setCourseId] = useState<string|null>(null);
  const [{ data, fetching, error }] = useCourseQuery({
    variables: { id: courseId || "" },
    pause: !courseId,
  });

  debug("courseId", courseId);
  debug("data", data);
  debug("fetching", fetching);
  debug("error", error);
  const value = useMemo(() => ({
    currentCourse: courseId && data?.jrnyCourse?.id === courseId ? data?.jrnyCourse : null,
    fetching,
    error: error?.message || null,
    loadCourse: setCourseId,
  }), [courseId, data, fetching, error]);
  return (
    <CourseContext.Provider
      value={value}
    >
      {children}
    </CourseContext.Provider>
  );
}

export function useCurrentCourse() {
  return useContext(CourseContext);
} 