import AppAppBar from '@components/AppAppBar';
import AppForm from '@components/AppForm';
import FormButton from '@components/form/FormButton';
import FormFeedback from '@components/form/FormFeedback';
import RFTextField from '@components/form/RFTextField';
import { password, required, verifyPassword } from '@components/form/validation';
import Typography from '@components/Typography';
import { useAcceptSiteInvitationMutation } from '@graphql/invitations-hook';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import debugFn from 'debug';
import { FORM_ERROR } from 'final-form';
import * as React from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { useNavigate, useParams } from 'react-router-dom';
import {AbilityContext, useCurrentUser} from "@/context/CurrentUserProvider";
import {useSnackbar} from "notistack";
import { useCallback } from 'react';
import { CaslVerb } from '@/CaslVerb';

const debug = debugFn('SignUp')


function AcceptSiteInvitation() {
  const [_, acceptInvitation] = useAcceptSiteInvitationMutation()
  const {currentUser, setCurrentUser, signOut} = useCurrentUser()
  const ability = React.useContext(AbilityContext);
  const {enqueueSnackbar} = useSnackbar()
  const {tokenId} = useParams()
  const navigate = useNavigate()
  React.useEffect(() => {
    if (currentUser) {
      signOut()
    }
  }, [])


  const validate = (values: { [index: string]: string }) => {
    const errors = required(['firstName', 'lastName', 'password', 'verifyPassword'], values);

    if (!errors.password) {
      const passwordError = password(values.password);
      if (passwordError) {
        errors.password = passwordError;
      }
    }
    if (!errors.verifyPassword) {
      const passwordError = verifyPassword(values.verifyPassword, values.password);
      if (passwordError) {
        errors.verifyPassword = passwordError;
        errors.password = passwordError;
      }
    }

    return errors;
  };

  const redirect = useCallback(() => {
    const hasWebadminPermissions = ability.can(CaslVerb.LoginWebadmin);
    const hasWebappPermissions = ability.can(CaslVerb.LoginWebapp);
    debug('redirect', ability)
    debug('redirect', {hasWebadminPermissions, hasWebappPermissions})
    
    if(hasWebadminPermissions && !hasWebappPermissions) {
      navigate('/admin')
    } else {
      window.location.href = import.meta.env.VITE_WEBAPP_URL + '/logout'
    }
  }, [ability, navigate])
  
  const handleSubmit = async (values: { email: string, password: string, firstName: string, lastName: string }) => {
    const result = await acceptInvitation({
      input: {
        token: tokenId!,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName
      }
    })
    if (result.error) {
      return {[FORM_ERROR]: result.error.graphQLErrors[0].message||'Confirmation failed'}
    }
    setCurrentUser(result.data!.jrnyAcceptSiteInvitation)
    setTimeout(redirect, 5)
  };

  return (
    (<React.Fragment>
      <AppAppBar/>
      <AppForm>
        <React.Fragment>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Create your account
          </Typography>
        </React.Fragment>
        <Form
          onSubmit={handleSubmit}
          subscription={{submitting: true}}
          validate={validate}
        >
          {({handleSubmit: handleSubmit2, submitting}) => (
            <Box component="form" onSubmit={handleSubmit2} noValidate sx={{mt: 6}}>
              <Grid container spacing={2}>
                <Grid
                  size={{
                    xs: 12,
                    sm: 6
                  }}>
                  <Field
                    autoFocus
                    component={RFTextField}
                    disabled={submitting}
                    autoComplete="given-name"
                    fullWidth
                    label="First name"
                    name="firstName"
                    required
                  />
                </Grid>
                <Grid
                  size={{
                    xs: 12,
                    sm: 6
                  }}>
                  <Field
                    component={RFTextField}
                    disabled={submitting}
                    autoComplete="family-name"
                    fullWidth
                    label="Last name"
                    name="lastName"
                    required
                  />
                </Grid>
              </Grid>
              <Field
                fullWidth
                component={RFTextField}
                disabled={submitting}
                required
                name="password"
                autoComplete="new-password"
                label="Password"
                type="password"
                margin="normal"
              />
              <Field
                fullWidth
                component={RFTextField}
                disabled={submitting}
                required
                name="verifyPassword"
                autoComplete="new-password"
                label="Verify password"
                type="password"
                margin="normal"
              />
              <FormSpy subscription={{submitError: true}}>
                {({submitError}) =>
                  submitError ? (
                    <FormFeedback error sx={{mt: 2}}>
                      {submitError}
                    </FormFeedback>
                  ) : null
                }
              </FormSpy>
              <FormButton
                sx={{mt: 3, mb: 2}}
                disabled={submitting}
                color="secondary"
                fullWidth
              >
                {submitting ? 'In progress…' : 'Accept invitation'}
              </FormButton>
            </Box>
          )}
        </Form>
      </AppForm>
    </React.Fragment>)
  );
}

export default AcceptSiteInvitation;
