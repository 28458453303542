import { IconCreate, IconImage, IconPerson } from "@/icons";
import {
    MoreHoriz
} from "@mui/icons-material";
import {
    Box,
    Card,
    CardContent,
    Chip,
    IconButton,
    ListItemText,
    Menu,
    MenuItem,
    Typography
} from "@mui/material";
import React, { useState } from "react";
import { Course } from "../types";
import styles from "./CourseCard.module.css";

interface CourseCardProps {
  course?: Course;
  isNew?: boolean;
  isSelected?: boolean;
  onClick?: () => void;
  onActivate?: () => void;
  onDuplicate?: () => void;
  onDelete?: () => void;
}

export const CourseCard: React.FC<CourseCardProps> = ({
  course,
  isNew = false,
  isSelected = false,
  onClick,
  onActivate,
  onDuplicate,
  onDelete,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleActivate = (e: React.MouseEvent) => {
    e.stopPropagation();
    onActivate?.();
    setAnchorEl(null);
  };

  const handleDuplicate = (e: React.MouseEvent) => {
    e.stopPropagation();
    onDuplicate?.();
    setAnchorEl(null);
  };

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    onDelete?.();
    setAnchorEl(null);
  };

  if (isNew) {
    return (
      <Card className={`${styles.card}`} onClick={onClick} elevation={0}>
        <CardContent className={styles.cardContent}>
          <div className={styles.thumbnailContainer}>
            <div className={styles.placeholder}>
              <IconCreate fontSize="large" />
            </div>
          </div>

          <Box className={styles.contentContainer}>
            <Typography variant="h6" className={styles.title}>
              Create New Course
            </Typography>
            <Typography variant="body2" className={styles.term}>
              Add a new course to your organization
            </Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }

  if (!course) return null;

  const checkpointsCount = course.checkpoints?.length || 0;
  const studentsCount = course.studentCount || 0;
  const perkCount = course.perkCount || 0;
  return (
    <Card
      className={`${styles.card} ${isSelected ? styles.selected : ""}`}
      onClick={onClick}
      elevation={0}
    >
      <CardContent className={styles.cardContent}>
        <div className={styles.thumbnailContainer}>
          {course.mapImage?.url ? (
            <img
              src={course.mapImage?.url}
              alt={course.name}
              className={styles.thumbnail}
            />
          ) : (
            <div className={styles.placeholder}>
              <IconImage fontSize="large" />
            </div>
          )}
        </div>

        <Box className={styles.contentContainer}>
          <Box className={styles.headerContainer}>
            <Typography variant="h6" className={styles.title}>
              {course.name}
            </Typography>
            <IconButton
              size="small"
              onClick={handleMenuClick}
              className={styles.menuButton}
            >
              <MoreHoriz fontSize="large" sx={{color: "grey.600"}} />
            </IconButton>
          </Box>

          {/* <Typography variant="body2" className={styles.term}>
            {course.available ? "Available" : "Not Available"}
          </Typography> */}

          <Box className={styles.statsContainer}>
            <Chip
              label={`${checkpointsCount} Checkpoints ${perkCount} Perks`}
              size="small"
              sx={{
                backgroundColor: "transparent",
              }}
            />
            <Box className={styles.spacer} />
            {course.available ? (
              <Chip
                label={`${studentsCount} Students`}
                icon={<IconPerson fontSize="small" color="success" />}
                size="small"
                sx={{
                  backgroundColor: "transparent",
                  color: "success.main",
                }}
              />
            ) : (
              <Chip
                label={`Draft`}
                size="small"
                sx={{
                  backgroundColor: "secondary.50",
                  color: "secondary.600",
                }}
              />
            )}
          </Box>
        </Box>
      </CardContent>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleActivate}>
          <ListItemText>
            {course.available ? "Deactivate" : "Activate"}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDuplicate}>
          <ListItemText>Duplicate as new</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDelete} className={styles.deleteMenuItem}>
          <ListItemText className={styles.deleteText}>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </Card>
  );
};

export default CourseCard;
