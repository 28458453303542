import Typography from "@/components/Typography";
import {
  useReorderAllLessonsMutation,
  useReorderInvalidOrderLessonsMutation,
  useListCheckpointsWithInvalidOrderQuery,
  IGQLReorderAllLessonsMutation,
} from "@/graphql/hooks/maintenance-hook";
import { Unpacked } from "@/types";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
type ReorderResult = Unpacked<
  IGQLReorderAllLessonsMutation["jrnyReorderAllLessons"]
>;
export function MaintenanceView() {
  const [
    { fetching: fetchingAll, data: dataAll, error: errorAll },
    reorderAllLessonsMutation,
  ] = useReorderAllLessonsMutation();
  const [
    {
      fetching: fetchingInvalidOrder,
      data: dataInvalidOrder,
      error: errorInvalidOrder,
    },
    reorderInvalidOrderLessonsMutation,
  ] = useReorderInvalidOrderLessonsMutation();
  const [
    {
      fetching: fetchingCheckpoints,
      data: dataCheckpoints,
      error: errorCheckpoints,
    },
    listCheckpointsQuery,
  ] = useListCheckpointsWithInvalidOrderQuery({
    pause: true,
  });

  const [result, setResult] = useState<ReorderResult[]>([]);
  const fetching = fetchingAll || fetchingInvalidOrder || fetchingCheckpoints;
  const data =
    dataAll?.jrnyReorderAllLessons ||
    dataInvalidOrder?.jrnyReorderInvalidOrderLessons ||
    dataCheckpoints?.jrnyListCheckpointsWithInvalidOrder;
  const error = errorAll || errorInvalidOrder || errorCheckpoints;

  const reorderAllLessons = async () => {
    try {
      const result = await reorderAllLessonsMutation({});
      setResult(result.data?.jrnyReorderAllLessons || []);
    } catch (err: any) {
      console.error(err);
    }
  };

  const reorderInvalidOrderLessons = async () => {
    try {
      const result = await reorderInvalidOrderLessonsMutation({});
      setResult(result.data?.jrnyReorderInvalidOrderLessons || []);
    } catch (err: any) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (dataCheckpoints) {
      setResult(dataCheckpoints.jrnyListCheckpointsWithInvalidOrder);
    }
  }, [dataCheckpoints]);

  return (
    <>
      <Paper sx={{ p: 2 }}>
        <Grid container spacing={1}>
          <Grid size={{ sm: 12 }}>
            <Stack spacing={1}>
              <Typography variant="caption" sx={{ pb: 1 }}>
                Trigger a reorder of the lessons that have invalid order. This
                will fix any non-consecutive sequences or gaps in lesson
                ordering.
              </Typography>
              <Stack direction="row" spacing={1}>
                <Button
                  disabled={fetching}
                  variant="contained"
                  color="success"
                  onClick={() => listCheckpointsQuery()}
                >
                  List Checkpoints with Invalid Order
                </Button>
                <Button
                  disabled={fetching}
                  variant="contained"
                  color="warning"
                  onClick={() => reorderInvalidOrderLessons()}
                >
                  Reorder Invalid Order Lessons
                </Button>
                <Button
                  disabled={fetching}
                  variant="contained"
                  color="error"
                  onClick={() => reorderAllLessons()}
                >
                  Reorder All Lessons
                </Button>
              </Stack>

              {data && (
                <>
                  <Typography variant="caption" sx={{ pb: 1 }}>
                    Reordered lessons in {data.length} checkpoints
                  </Typography>
                  <TableContainer component={Paper} variant="outlined">
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Course</TableCell>
                          <TableCell>Checkpoint</TableCell>
                          <TableCell align="right">Lessons Fixed</TableCell>
                          <TableCell align="right">
                            <Tooltip title="Previous minimum order value">
                              <span>Min Order</span>
                            </Tooltip>
                          </TableCell>
                          <TableCell align="right">
                            <Tooltip title="Previous maximum order value">
                              <span>Max Order</span>
                            </Tooltip>
                          </TableCell>
                          <TableCell align="right">
                            <Tooltip title="Number of unique order values before fixing">
                              <span>Unique Orders</span>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {result.map((result) => (
                          <TableRow
                            key={`${result.courseId}-${result.checkpointId}`}
                          >
                            <TableCell>{result.courseName}</TableCell>
                            <TableCell>{result.checkpointName}</TableCell>
                            <TableCell align="right">
                              {result.lessonCount}
                            </TableCell>
                            <TableCell align="right">
                              {result.previousMinOrder}
                            </TableCell>
                            <TableCell align="right">
                              {result.previousMaxOrder}
                            </TableCell>
                            <TableCell align="right">
                              {result.previousUniqueOrders}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Paper>
      {/* <DebugView>{JSON.stringify(localSettings, null, 2)}</DebugView> */}
    </>
  );
}
