import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { themeOptions } from "../app-theme";
import { AppBackground } from "./AppBackground";
import { useRef } from "react";

interface AppViewProps {
  gradient1: string;
  gradient2: string;
  isLesson?: boolean;
  sx?: any;
  children?: React.ReactNode;
  primaryColor?: string;
  secondaryColor?: string;
}

function AppViewContent({
  gradient1,
  gradient2,
  isLesson,
  sx = {},
  children,
}: AppViewProps) {
  return (
    <AppBackground
      gradient1={gradient1}
      gradient2={gradient2}
      isLesson={isLesson}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: 10,
        ...sx,
      }}
    >
      {children}
    </AppBackground>
  );
}

export function AppView({
  primaryColor,
  secondaryColor,
  ...props
}: AppViewProps) {
  const ref = useRef<HTMLDivElement>(null);
  const themeOverrides = {
    palette: {
      primary: primaryColor
        ? {
            main: primaryColor,
          }
        : undefined,
      secondary: secondaryColor
        ? {
            main: secondaryColor,
          }
        : undefined,
    },
  };

  const theme = createTheme({
    cssVariables: {
      rootSelector: ".app-view",
    },
    ...deepmerge(themeOptions, themeOverrides),
  });
  
  return (
    <div ref={ref} className="app-view">
      <ThemeProvider theme={theme} 
      disableNestedContext
      colorSchemeNode={ref.current}>
        <CssBaseline />
        <AppViewContent {...props} />
      </ThemeProvider>
    </div>
  
  );
}
