import { Role, Roles } from "../types";

const isSameRole = (a: Role, b: Role): boolean => {
  if (a.scope !== b.scope) return false;
  if (a.organizationId !== b.organizationId) return false;
  if (a.courseId !== b.courseId) return false;
  
  const aRoleIds = a.roleIds || [];
  const bRoleIds = b.roleIds || [];
  if (aRoleIds.length !== bRoleIds.length) return false;
  
  return aRoleIds.every(id => bRoleIds.includes(id));
};

const findMatchingRole = (role: Role, roles: Roles): Role | undefined => {
  return roles.find(r => 
    r.scope === role.scope && 
    r.organizationId === role.organizationId && 
    r.courseId === role.courseId
  );
};

export const roleDiff = (next: Roles, prev: Roles): Roles => {
  const result: Roles = [];

  // Handle changed and new roles
  next.forEach(nextRole => {
    const prevRole = findMatchingRole(nextRole, prev);
    if (!prevRole || !isSameRole(nextRole, prevRole)) {
      result.push(nextRole);
    }
  });

  // Handle removed roles (keep structure but with empty roleIds)
  prev.forEach(prevRole => {
    const nextRole = findMatchingRole(prevRole, next);
    if (!nextRole) {
      result.push({
        ...prevRole,
        roleIds: []
      });
    }
  });

  return result;
}; 