export function stringToColor(name: string) {
    let hash = 0;
    let i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < name.length; i += 1) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
  export function stringAvatar(name?: string|null, avatar?: string|null, size?: number ) {
    if( avatar) {
      return {src: avatar}
    }
    if( !name) {
      return {}
    }
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: size,
        height: size,
      },
      children: getInitials(name),
    };
  }
  export const getInitials = (name: string) => {
    // can contain any number of words, take first and last or first or undefined
    const words = name.split(/\s+/)
    return (words.length > 1 ? words[0][0] + words[words.length - 1][0] : words[0][0]).toUpperCase()
  
  }
  