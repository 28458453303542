import * as Types from '../../graphql-types';

import { gql } from 'urql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type IGQLParticipationsQueryVariables = Types.Exact<{
  courseId: Types.Scalars['ID']['input'];
}>;


export type IGQLParticipationsQuery = { __typename: 'Query', jrnyParticipationsForCourse: Array<{ __typename: 'JrnyParticipation', courseId: string, userId: string, roleIds: Array<string>, user: { __typename: 'JrnyAppInternalUser', id: string, name?: string | null, email?: string | null } }> };

export type IGQLAddCourseRoleToUserMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyAssignCourseRoleInput;
}>;


export type IGQLAddCourseRoleToUserMutation = { __typename: 'Mutation', jrnyAddCourseRoleToUser: { __typename: 'JrnyParticipation', courseId: string, userId: string, roleIds: Array<string>, user: { __typename: 'JrnyAppInternalUser', id: string, name?: string | null, email?: string | null } } };

export type IGQLAddCourseRolesToUsersMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyAssignCourseRolesInput;
}>;


export type IGQLAddCourseRolesToUsersMutation = { __typename: 'Mutation', jrnyAddCourseRolesToUsers: Array<{ __typename: 'JrnyParticipation', courseId: string, userId: string, roleIds: Array<string>, user: { __typename: 'JrnyAppInternalUser', id: string, name?: string | null, email?: string | null } }> };

export type IGQLRemoveCourseRoleFromUserMutationVariables = Types.Exact<{
  input: Types.IGQLJrnyRemoveCourseRoleInput;
}>;


export type IGQLRemoveCourseRoleFromUserMutation = { __typename: 'Mutation', jrnyRemoveCourseRoleFromUser: { __typename: 'JrnyParticipation', courseId: string, userId: string, roleIds: Array<string>, user: { __typename: 'JrnyAppInternalUser', id: string, name?: string | null, email?: string | null } } };

export type IGQLSendWelcomeEmailMutationVariables = Types.Exact<{
  input: Types.IGQLJrnySendEmailToParticipantsInput;
}>;


export type IGQLSendWelcomeEmailMutation = { __typename: 'Mutation', jrnySendEmailToParticipants: { __typename: 'JrnySendEmailToParticipantsOutput', courseId: string, userIds: Array<string> } };


export const ParticipationsDocument = gql`
    query participations($courseId: ID!) {
  jrnyParticipationsForCourse(courseId: $courseId) {
    courseId
    userId
    roleIds
    user {
      id
      name
      email
    }
  }
}
    `;

export function useParticipationsQuery(options: Omit<Urql.UseQueryArgs<IGQLParticipationsQueryVariables>, 'query'>) {
  return Urql.useQuery<IGQLParticipationsQuery, IGQLParticipationsQueryVariables>({ query: ParticipationsDocument, ...options });
};
export const AddCourseRoleToUserDocument = gql`
    mutation addCourseRoleToUser($input: JrnyAssignCourseRoleInput!) {
  jrnyAddCourseRoleToUser(input: $input) {
    courseId
    userId
    roleIds
    user {
      id
      name
      email
    }
  }
}
    `;

export function useAddCourseRoleToUserMutation() {
  return Urql.useMutation<IGQLAddCourseRoleToUserMutation, IGQLAddCourseRoleToUserMutationVariables>(AddCourseRoleToUserDocument);
};
export const AddCourseRolesToUsersDocument = gql`
    mutation addCourseRolesToUsers($input: JrnyAssignCourseRolesInput!) {
  jrnyAddCourseRolesToUsers(input: $input) {
    courseId
    userId
    roleIds
    user {
      id
      name
      email
    }
  }
}
    `;

export function useAddCourseRolesToUsersMutation() {
  return Urql.useMutation<IGQLAddCourseRolesToUsersMutation, IGQLAddCourseRolesToUsersMutationVariables>(AddCourseRolesToUsersDocument);
};
export const RemoveCourseRoleFromUserDocument = gql`
    mutation removeCourseRoleFromUser($input: JrnyRemoveCourseRoleInput!) {
  jrnyRemoveCourseRoleFromUser(input: $input) {
    courseId
    userId
    roleIds
    user {
      id
      name
      email
    }
  }
}
    `;

export function useRemoveCourseRoleFromUserMutation() {
  return Urql.useMutation<IGQLRemoveCourseRoleFromUserMutation, IGQLRemoveCourseRoleFromUserMutationVariables>(RemoveCourseRoleFromUserDocument);
};
export const SendWelcomeEmailDocument = gql`
    mutation sendWelcomeEmail($input: JrnySendEmailToParticipantsInput!) {
  jrnySendEmailToParticipants(input: $input) {
    courseId
    userIds
  }
}
    `;

export function useSendWelcomeEmailMutation() {
  return Urql.useMutation<IGQLSendWelcomeEmailMutation, IGQLSendWelcomeEmailMutationVariables>(SendWelcomeEmailDocument);
};