import * as Sentry from "@sentry/react";
import { SnackbarProvider } from "notistack";
import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import "./App.css";
import { useCurrentUser } from "./context/CurrentUserProvider";
import { useJrnyReloginMutation } from "./graphql/hooks/login-hook";
import { router } from "./router";
import { getFaviconUrl, getTitle } from "./utils";

// @ts-ignore - Ignoring type issues with Sentry integration
const SentryRouterProvider = Sentry.withSentryReactRouterV6Routing(RouterProvider);

function App() {
  const { currentUser, setCurrentUser } = useCurrentUser();
  const [, doJrnyRelogin] = useJrnyReloginMutation();

  useEffect(() => {
    const faviconUpdate = async () => {
      const favicon = document.getElementById("favicon");
      if (favicon) {
        (favicon as HTMLLinkElement).href = getFaviconUrl();
      }
      document.title = getTitle();
    };
    faviconUpdate();
  }, []);

  useEffect(() => {
    const relogin = async () => {
      if (currentUser?.saved) {
        const result = await doJrnyRelogin({});
        if (result.data?.jrnyRelogin) {
          console.log("Setting current user", result.data.jrnyRelogin);
          setCurrentUser(result.data.jrnyRelogin);
        }
      }
    };
    relogin();
  }, [currentUser]);

  return (
    <div className="App">
      <SnackbarProvider
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <RouterProvider router={router} />
      </SnackbarProvider>
    </div>
  );
}

export default App;
