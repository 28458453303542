import { IconDelete, IconGridFolder } from "@/icons";
import { generateDebug } from "@/utils";
import { useDraggable, useDroppable } from '@dnd-kit/core';
import { Box, Card, IconButton, Stack, styled, useTheme } from "@mui/material";
import useMergedRef from "@react-hook/merged-ref";
import * as React from 'react';
import AspectRatio from "./AspectRatio";
import { FileInfo } from "./FileInfo";
import { MimeTypeIcon } from "./MimeTypeIcon";
import { NonRenamableName, RenamableName } from "./RenamableName";
import { FileFolderTree, FileOrFolder, MinimalFileObject } from "./types";

const debug = generateDebug('file-grid-items')
const DiscreteBox = styled(Box)({
  opacity: 0,
  transition: 'opacity .2s ease-in-out',
  position: 'absolute', top: 0, right: 0, zIndex: 1,
  '.hoverable:hover &': {
    opacity: 1,
  }
})

interface BaseGridItemProps {
  item: FileOrFolder
  disabled?: boolean
  onSelect?: ((val: FileOrFolder) => void)
  onRename?: (val: FileOrFolder) => void
  onDelete?: (val: FileOrFolder) => void
  setNodeRef?: (node: HTMLElement | null) => void
  cardStyles?: React.CSSProperties
  image: React.ReactNode
  name?: string
  isDroppable?: boolean
}

interface FileGridItemsProps {
  onSelect?: (val: FileOrFolder) => void
  onRename?: (val: FileOrFolder) => void
  onDelete?: (val: FileOrFolder) => void
  file: MinimalFileObject
  disabled?: boolean
  ref?: React.Ref<HTMLDivElement>
}

interface VideoGridItemProps extends FileGridItemsProps {
  onStartPlaying?: (val: MinimalFileObject) => void
  isPlaying: boolean

}

interface FolderGridItemsProps {
  onRename?: (val: FileOrFolder) => void
  onGoToFolder?: (folder: FileFolderTree) => void
  onDelete?: (val: FileOrFolder) => void
  isParent: boolean
  folder: FileFolderTree
  isDroppable?: boolean
  ref?: React.Ref<HTMLDivElement>


}

const gridSize = {xs: 6, sm: 4, md: 3, lg: 2}
type ImageCardProps = {
  children: React.ReactNode,
  onDelete?: (evt: React.SyntheticEvent) => void
  onSelect?: ((val: FileOrFolder) => void)
  disabled?: boolean
  item: FileOrFolder
  style?: React.CSSProperties
}

const ImageCard = React.forwardRef((props: ImageCardProps, ref: React.Ref<HTMLDivElement>) => {
  return (<Card className={props.onDelete ? 'hoverable' : undefined}
                ref={ref}
                onClick={() => props.onSelect?.(props.item)}
                style={{...props.style, opacity: props.disabled ? 0.5 : 1}}
                sx={{flexGrow: 0, position: 'relative', mb: 1}}>
    {props.children}
    {props.onDelete && <DiscreteBox>
      <IconButton onClick={props.onDelete}>
        <IconDelete color={'warning'}/>
      </IconButton>
    </DiscreteBox>

    }
  </Card>)
})

function DroppableImageCard(props: ImageCardProps) {
  const theme = useTheme()
  const {isOver, setNodeRef, active} = useDroppable({
    id: props.item.id,
    data: props.item,
  });
  const style = React.useMemo(() => ({
    backgroundColor: isOver && active?.id != props.item.id ? theme.palette.primary.main : undefined,
    transition: 'background-color .2s ease-in-out'
  }), [isOver, active])
  return (
    <ImageCard ref={setNodeRef} style={style} {...props}/>
  )
}


const BaseGridItem = React.forwardRef((props: BaseGridItemProps, ref: React.Ref<HTMLDivElement>) => {
  const {item, image, isDroppable} = props
  const onDelete = props.onDelete ? (evt: { stopPropagation: () => void; }) => {
    evt.stopPropagation()
    props.onDelete?.(props.item)
  } : undefined
  return (<Box sx={{
      flexShrink: 1,
      display: 'flex',
      flexFlow: 'column'
    }}
               ref={ref}
    >
      <FileInfo item={item}/>
      {isDroppable
        ?
        <DroppableImageCard
          item={item}
          onDelete={onDelete}
          onSelect={props.onSelect}
          disabled={props.disabled}>{image}</DroppableImageCard>
        : <ImageCard item={item}
                     onSelect={props.onSelect}

                     onDelete={onDelete}
                     disabled={props.disabled}>{image}</ImageCard>
      }
      <Stack>
        {props.name
          ? <NonRenamableName name={props.name}/>
          : <RenamableName item={item} onRename={props.onRename}/>
        }
      </Stack>
    </Box>
  )
})


export function FolderGridItem(props: FolderGridItemsProps) {
  const {folder, isParent, onRename, onGoToFolder, ...rest} = props

  return (
    <BaseGridItem
      item={folder}
      disabled={false}
      onSelect={onGoToFolder as ((val: FileOrFolder) => void)}
      {...rest}
      onRename={folder.parent ? onRename : undefined}
      image={<Box sx={{p: 2}}><AspectRatio ratio={'1'} objectFit="contain">
        <IconGridFolder color={"primary"}/>
      </AspectRatio></Box>}
      name={isParent ? '..' : undefined}
    />
  )
}

export const ImageGridItem = React.forwardRef((props: FileGridItemsProps, ref: React.Ref<HTMLDivElement>) => {
  const {file, ...rest} = props


  return (
    <BaseGridItem item={file}
                  ref={ref}
                  {...rest}
                  image={<AspectRatio ratio={'1'} objectFit="contain">
                    <img src={file.url}/>
                  </AspectRatio>}/>
  )
})

export const DocumentGridItem = React.forwardRef((props: FileGridItemsProps, ref: React.Ref<HTMLDivElement>) => {
  const {file, ...rest} = props
  return (
    <BaseGridItem item={file}
                  ref={ref}
                  {...rest}
                  image={<Box sx={{p: 2}}>

                    <AspectRatio ratio={'1'} objectFit="contain">
                      <MimeTypeIcon width={'100%'} height={'100%'} color="#ff0" auto contentType={file.contentType}/>
                    </AspectRatio>
                  </Box>}/>
  )
})

export const VideoGridItem = React.forwardRef((props: VideoGridItemProps, ref: React.Ref<HTMLDivElement>) => {
  const {file, ...rest} = props
  return (
    <BaseGridItem item={file}
                  ref={ref}
                  {...rest}
                  image={<Box sx={{background: props.isPlaying ? '#670303' : '#fff'}}
                              onClick={() => props.onStartPlaying?.(file)}>
                    <AspectRatio ratio={'1'} objectFit="contain">
                      <video id={'video_' + file.id} src={file.url}>
                        <source src={file.url + '#t=0.1'} type={file.contentType}/>
                      </video>
                    </AspectRatio>
                  </Box>}/>
  )
})


type DraggableGridItemProps = {
  children: React.ReactNode
  item: FileFolderTree | MinimalFileObject,
}
export const DraggableGridItem = React.forwardRef((props: DraggableGridItemProps, ref) => {
  const {attributes, listeners, setNodeRef, active} = useDraggable({
    id: props.item.id,
    data: props.item,
  });
  const combineRef = useMergedRef(setNodeRef, ref);
  return (
    <div ref={combineRef} {...listeners} {...attributes}
         style={{
           opacity: active?.id==props.item.id ? 0.5 : 1,
           display: 'flex',
           flexFlow: 'column'
         }}>
      {props.children}
    </div>
  );
});
